import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import vapidKey from "./vapidkey.json";
import { messaging } from "./firebaseInit";
import { getToken } from "firebase/messaging";

const rawHTML = `
<head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
	<meta name="csrf-param" content="authenticity_token">
<meta name="csrf-token" content="lXnmz757sSvZSjyncfWyAHz4KALdR+b9XLIePBYoWTTr5nheYL/PTim0+/u8AJabNEXqz3+TtR5Wcqw22ap/Pw==">
	
	<meta http-equiv="X-UA-Compatible" content="IE=edge">
	<meta name="viewport" content="width=device-width, initial-scale=1">
	<title>DLF | The Crest</title>
	<meta name="Keywords" content="">
	<meta name="Description" content="">
	<link rel="shortcut icon" href="https://thecrestproject.dlf.in/favicon.ico">
	<link href="assets/site5/css" rel="stylesheet">
	<script async="" src="assets/site5/gtm.js.download"></script><script async="" src="assets/site5/gtm.js(1).download"></script><script type="text/javascript" async="" src="assets/site5/insight.min.js.download"></script><script async="" src="assets/site5/analytics.js.download"></script><script src="assets/site5/site-4b70628db6bb9b789b8c8837fafd0c3d08d7b47e3f26cc4ce398df0d9abfe93b.js.download" data-turbolinks-track="true"></script>
	<link rel="stylesheet" media="all" href="assets/site5/site-faeeb7b522330afb7c071877a254ae25bb98a2171f5e603a2f7b3f03f6971e41.css" data-turbolinks-track="true">
	<script type="text/javascript" src="assets/site5/jquery.cookie.min.js.download"></script>
	<!--[if lt IE 9]>
		<script src="https://oss.maxcdn.com/html5shiv/3.7.3/html5shiv.min.js"></script>
		<script src="https://oss.maxcdn.com/respond/1.4.2/respond.min.js"></script>
	<![endif]-->
	<script>
		(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
			(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
			m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
		})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

		ga('create', 'UA-110880892-1', 'auto');
		ga('require', 'displayfeatures');
		ga('require', 'ecommerce', 'ecommerce.js');
		ga('send', 'pageview');

	</script>
	<style type="text/css">
		div#visitor_form_wrapper{ padding-left: 20px; }
		.disclaimer-iframe p{ font-size: 18px; padding-bottom: 10px; line-height: 21px;}
		.disclaimer-iframe { width: 100%; margin: 0 auto; padding: 0px 30px 30px 30px; background: #12111c; height: auto; border: 2px solid #f0ca58; 
			float: left;}
			.disclaimer-iframe input{ font-size: 18px;  }
			.disclaimer-page { position: fixed; background-color: rgba(0, 0, 0, 0.8); width: 100%; height: 100%; z-index: 99999; top: 0; left: 0;}
			.disclaimer-page .close-popup-btn, .book-now-form-wrapper .close-popup-btn { margin: 20px; position: relative; z-index: 9;}
			.disclaimer-page .close-popup-btn img, .book-now-form-wrapper .close-popup-btn img { width: 20px; cursor: pointer; padding: 5px; background: #3e3e3e;}
			.disclaimer-page .close-popup-btn{ position: static; }
			.disclaimer-page-copy { position: absolute; top: 50%; left: 50%; margin: 0 0 20px; transform: translateY(-50%); border-radius: 30px; padding: 25px;
				background-color: transparent; color: #000; font-size: 15px; line-height: 20px; text-align: center; transform: translate(-50%, -50%); width: 60%;}
				.disclaimer-page .benefits-subhead {line-height: 32px; font-size: 26px; margin: 0px 0px 30px 0px;}
				.disclaimer-page-copy .form-wrapper { position: static; width: auto; padding-left: 20px;}
				#visitor_source_id{position: relative;}
				#visitor_source-error{top: 140px !important;}
				.submit{ background: #40b5eb; color: #fff; font-size: 16px; text-transform: uppercase; padding: 5px 20px; border: 0px; box-shadow: none; }
				.margin-cont { margin-top: 40px; }
				.margin-cont iframe{ margin-top: 36px; }
				.callnumber{ color: #fff; margin-right: 10px;line-height: 1em;}

				#popup{max-width:520px;width:80%;padding:20px;position:absolute;background:#fff;margin:20px auto 80px;transform: translateX(-50%);left:50%;}
				#close{position:absolute;top:0px;right:6px;cursor:pointer;color:#000}
				#overlay{overflow:auto;position:fixed;height:100%;width:100%;top:0;right:0;bottom:0;left:0;background:rgba(0,0,0,0.8);z-index:99999}
				#popup img{
					height: 524px;
				}
				@media(max-width: 768px){
					.disclaimer-page-copy{ width: 97%; }
					#popup img{
						height: auto !important;
					}

					#popup{
						top: 15%;
					}  
				}
				@media(max-width: 767px){
					.disclaimer-iframe{ padding: 0px 10px 0px 10px; }
					.disclaimer-page-copy .form-wrapper {  position: static !important;  bottom: 0px !important;  width: 100% !important; background:none !important;
						padding: 0px 5px 10px 5px!important;	}
						.disclaimer-iframe .enquire-close-mobile{ display: none !important; }
						.disclaimer-iframe > img{height: 100px !important;}
						.disclaimer-page .close-popup-btn img{ width: 25px !important; }
						.disclaimer-page-copy { width: 100%;}
						#visitor_form_wrapper{ display: none; }
						.disclaimer-iframe .col-7{ width: 100%; }
						.margin-cont { margin-top: 0px;}
						/*.disclaimer-page{ display: none !important; }*/
					}
					@media(max-width: 320px){
						.disclaimer-page-copy .form-controls input{ padding: 1px 12px; }
						.disclaimer-page-copy .form-fields { padding: 0px 0;}
						.disclaimer-page-copy .form-controls textarea{ height: 55px; }
					}
				</style>
				<!----pixel code integrated---------->
				<script type="text/javascript"> _linkedin_partner_id = "2704450"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); </script><script type="text/javascript"> (function(){var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(); </script>
				<!----pixel code integrated---->
			<link href="assets/site5/3469b261e2.css" media="all" rel="stylesheet"></head>

			<body data-new-gr-c-s-check-loaded="14.1100.0" data-gr-ext-installed="">
				<!----pixel code integrated---------->	
				<noscript> <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2704450&fmt=gif" /> </noscript>
				<!----pixel code integrated---->

				<header class="">
					<div class="container">
						<div class="row">
							<div class="col-12">
								<div class="pull-left">
									<a href="https://thecrestproject.dlf.in/index.html" class="brand-logo"><img src="assets/site5/logo-f2c7d78fa0d761b1812df58b0c5f47eb1fb02f5b6dd7386f1ea7813262df2846.jpg" data-event-category="Header" data-event-action="Click" data-event-name="Logo"></a>
								</div>
								<div class="pull-right menu-icon-mobile">
									<span data-event-category="Header" data-event-action="Click" data-event-name="Menu Icon"></span>
									<span></span>
									<span></span>
								</div>
								<div class="pull-right">
									<div class="nav-links pull-left play">
										<a href="https://thecrestproject.dlf.in/index.html" data-event-category="Header" data-event-action="Click" data-event-name="Home">Home</a>
										<!-- <a href="<#%= url_overview %>" <#% if params[:action]=="overview" %> class="active" <#% end %> data-event-category="Header" data-event-action="Click" data-event-name="Overview">Overview</a> -->
										<a href="https://thecrestproject.dlf.in/location.html" data-event-category="Header" data-event-action="Click" data-event-name="Location">Location</a>						
										<!-- <a href="/lifestyle"  data-event-category="Header" data-event-action="Click" data-event-name="Lifestyle">Lifestyle</a> -->
						<!-- 	<span class="HasSubNav">
							<a href="javascript:;" class="downloads-list" data-event-category="Header" data-event-action="Click" data-event-name="Downloads" >Downloads</a>
							<ul class="SubMenu">
								<li><a href="javascript:;" class="brochure-btn downloads-btn" data-event-category="Header" data-event-action="Click" data-event-name="Brochure" data-link="/assets/20171120_The_Crest_Mini_Brochure_Preview-c19b33a95f22cad6fecaf6aa2dd868040de62339cb9ed4a360ffee4bc3443f72.pdf" >Brochure</a></li>
								<li><a href="javascript:;" class="buyers-btn downloads-btn" data-event-category="Header" data-event-action="Click" data-event-name="Buyers Agreement" data-link="/assets/buyers_agreement-cf04e72e9b1f116b2c141913802cffd0d67aeb6348f02a9effa1183829d1cb70.pdf">Buyers Agreement</a></li>
								<li><a href="javascript:;" class="floor-btn downloads-btn" data-event-category="Header" data-event-action="Click" data-event-name="Floor Plans" data-link="/assets/TheCrest_Floorplan_Digital-ed7c4f2c9fe7753702ce6d5840014dd3b94b556f4bbe963f5c5145d059e299a8.pdf">Floor Plans</a></li>
							</ul>
						</span> -->
						<!-- 	<a href="https://www.youtube.com/embed/JcMP-2jKSgY" class="walkthroughlink" data-event-category="Header" data-event-action="Click" data-event-name="Walkthrough"  >Walkthrough</a> -->
						<!-- <a href="<#%= url_reasonstobuy %>" <#% if params[:action]=="reasonstobuy" %> class="active" <#% end %> data-event-category="Header" data-event-action="Click" data-event-name="Reasons To Buy">Reasons To Buy?</a> -->
						<a href="https://thecrestproject.dlf.in/floorplans" data-event-category="Header" data-event-action="Click" data-event-name="Floor Plans">Site Plan</a>
						<a href="https://thecrestproject.dlf.in/contact-us" data-event-category="Header" data-event-action="Click" data-event-name="Contact Us">Contact Us</a>
					</div>
					<div class="pull-left enquire-form-wrapper relative">
						<a href="tel: +919319746362" class="hidden-xs callnumber" data-event-category="Header" data-event-action="Click" data-event-name="Call Number"><b><big>+91 9319746362</big></b></a>
						<a href="javascript:;" class="enq_btn enq_click hidden-xs" data-event-category="Header" data-event-action="Click" data-event-name="Enquire Now">Enquire Now&nbsp;&nbsp;<i class="fa fa-chevron-down" aria-hidden="true"></i></a>
						<div class="form-wrapper">
							<div class="enquire-close-mobile visible-xs">X</div>
							<div class="form-fields">
								<style type="text/css">
	#submit_form[disabled], #submit_form[disabled]:hover {
		cursor: default;
		background-color: #ccc;
		color: #12111c;
	}
	#submit_form{
		cursor:pointer;
		background-color: #a68b00;
		color: #000;
	}
</style>
<div id="form_hide">
	<form method="post" id="contact_us_form" action="https://thecrestproject.dlf.in/3-reasons-why-dlf5-Gurugram-is-the-perfect-home-for-you.html">
		<input type="hidden" name="authenticity_token" id="authenticity_token" value="5uNu82WwyvxcQ9B4xHg2AHFkqjVM3vvgiL7fq9IW166YfPBiu3S0may9FyQJjRKbOdlo+O4KqAOCfm2hHZTxpQ==">		<input type="hidden" name="site_contact[utm_source]" value="Website">
		<input type="hidden" name="site_contact[utm_campaign]" value="organic">
		<input type="hidden" name="site_contact[utm_medium]" value="website_organic">
		<input type="hidden" name="site_contact[utm_term]" value="Website">
		<input type="hidden" name="site_contact[project]" value="a0H6F00000SQExeUAH">
		<input type="hidden" name="site_contact[country]" value="India">
		<div class="form-controls">
			<input id="site_contact_first_name" name="site_contact[firstname]" type="text" placeholder="First Name">
			<span id="first_name_error"></span>
		</div>
		<div class="form-controls">
			<input id="site_contact_last_name" name="site_contact[lastname]" type="text" placeholder="Last Name">
			<span id="last_name_error"></span>
		</div>
		<div class="form-controls">
			<input id="site_contact_email" name="site_contact[email]" type="text" placeholder="Email">
			<span id="email_error"></span>
		</div>
		<div class="form-controls">
			<input id="site_contact_mobile" name="site_contact[phone]" type="text" placeholder="Mobile" minlength="10" maxlength="12">
			<span id="mobile_error"></span>
		</div>
		<div class="form-controls">
			<input id="site_contact_city" name="site_contact[city]" type="text" placeholder="City">
			<span id="city_error"></span>
		</div>
		<div class="form-controls" style="margin:0">
			
			<input type="hidden" name="captcha_contact" id="captcha_contact">
			<div class="col-6" align="center">
				<div id="captcha"><canvas id="captcha" width="130" height="31"></canvas></div>
            	<input type="hidden" placeholder="Enter Captcha" id="cpatchaTextBox1" class="required" name="captch1" style="margin:0" value="nFaTXZ">
            	<span id="capcha_error"></span>
			</div>
			<div class="col-6">
				<input type="text" placeholder="Enter Captcha" id="cpatchaTextBox2" class="required" name="captch2" style="margin:0">
			</div>
            <div class="clearfix"></div>
		</div>
		<div class="form-controls" align="left">
			<input type="button" value="Submit" id="submit_form" disabled="disabled" data-event-category="section" data-event-action="click" data-event-name="submit form" class="submit">
			<div id="wait_moment" style="display:none;margin-bottom:10px;">Please wait while we save your data..</div>
			<div><p style="color:#fff;font-size:12px;">By registering, you confirm that you agree to the storing and processing of your personal data by DLF Limited as described in the Privacy Statement.</p></div>
		</div>
	</form>
</div>
<div id="form_show" style="display:none;">
	<p>Thank you for your interest. <br>Our representative will get in touch with you shortly.</p>
</div>

<script type="text/javascript">
	$(document).ready(function(){
		$("#submit_form").click(function(){
			validateForm();	
		});

		$('#cpatchaTextBox2').on('blur keyup', function() {
            if ($("#contact_us_form").valid()) {
                if ($('#cpatchaTextBox2').val() != '' && $('#cpatchaTextBox2').val() == $('#cpatchaTextBox1').val() ) {
                    $('#submit_form').prop('disabled', false);
                    $("#contact_us_form").attr("action", "/salesforce_form");
                }
            } else {
                $('#submit_form').prop('disabled', 'disabled');
            }
        });
	});

	function validateEmail(emailField){
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(emailField) == false){
        $("#email_error").html('Invalid Email Address');
        return false;
    }
    return true;
	}

	function validateMobile(inputtxt){
	  var phoneno = /^\d{10}$/;
	  if((inputtxt.match(phoneno))){
	      return true;
	  }else{
	    $("#mobile_error").html('Invalid Mobile Number');
	    return false;
	  }
	}

	function validatechar(text){
	  if(/^[A-Za-z]+$/.test(text)){
	    return true;
	  }else{
	    return false;
	  }
	}

	var code;
        createCaptcha();
        function createCaptcha() {
          //clear the contents of captcha div first
          // document.getElementById('captcha').innerHTML = "";
          $('#captcha').html('');
          var charsArray =
          "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%&*";
          var lengthOtp = 6;
          var captcha = [];
          for (var i = 0; i < lengthOtp; i++) {
            //below code will not allow Repetition of Characters
            var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
            if (captcha.indexOf(charsArray[index]) == -1)
              captcha.push(charsArray[index]);
            else i--;
          }
          var canv = document.createElement("canvas");
          canv.id = "captcha";
          canv.width = 130;
          canv.height = 31;
          var ctx = canv.getContext("2d");
          ctx.font = "20px ambleregular";
          ctx.fillStyle = "#FFF";
          ctx.fillRect(0, 0, 150, 100);
          ctx.strokeText(captcha.join(""), canv.width/3.5, canv.height/1.5);
          ctx.textAlign = "center";
          //storing captcha so that can validate you can save it somewhere else according to your specific requirements
          code = captcha.join("");
          $('#captcha').append(canv);
          $("#cpatchaTextBox1").val(code);
          // document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
        }


	function validateForm(){
		var flag = 1;
		var site_contact_first_name = $("#site_contact_first_name").val();
		var site_contact_last_name = $("#site_contact_last_name").val();
		var site_contact_email = $("#site_contact_email").val();
		var site_contact_mobile = $("#site_contact_mobile").val();
		var site_contact_city = $("#site_contact_city").val();
		var cpatchaTextBox1 = $("#cpatchaTextBox1").val();
		var cpatchaTextBox2 = $("#cpatchaTextBox2").val();
		if((site_contact_first_name == '' || site_contact_first_name == null) && (site_contact_last_name == '' || site_contact_last_name == null) && (site_contact_email == '' || site_contact_email == null) && (site_contact_mobile == '' || site_contact_mobile == null) && (site_contact_city == '' || site_contact_city == null)){
			$("#first_name_error, #last_name_error, #email_error, #mobile_error, #city_error,#capcha_error").html("This field is required").show();
			flag = 0;
      return false;
		}else{
			flag = 1;
		}

		if((site_contact_first_name == '' || site_contact_first_name == null)){
      document.getElementById("first_name_error").innerHTML = "This value is required";
      document.getElementById("site_contact_first_name").focus();
      flag = 0;
    }else{
      if(!(validatechar(site_contact_first_name))){
	      document.getElementById("first_name_error").innerHTML= "Please enter alphabets only.";
	      document.getElementById("site_contact_first_name").focus();
	      flag = 0;
	    }else{
	      document.getElementById("first_name_error").innerHTML = " ";
	    }
    }

    if((site_contact_last_name == '' || site_contact_last_name == null)){
      document.getElementById("last_name_error").innerHTML = "This value is required";
      document.getElementById("site_contact_last_name").focus();
      flag = 0;
    }else{
      if(!(validatechar(site_contact_last_name))){
	      document.getElementById("last_name_error").innerHTML= "Please enter alphabets only.";
	      document.getElementById("site_contact_last_name").focus();
	      flag = 0;
	    }else{
	      document.getElementById("last_name_error").innerHTML = " ";
	    }
    }

    if((cpatchaTextBox1 == '') || (cpatchaTextBox1 == null) || (cpatchaTextBox1 != cpatchaTextBox2)){
      document.getElementById("capcha_error").innerHTML = "Please enter correct captcha.";
      document.getElementById("cpatchaTextBox1").focus();
      flag = 0;
    }
    else{
    	document.getElementById("capcha_error").innerHTML = " ";
    }

    if((site_contact_city == '' || site_contact_city == null)){
      document.getElementById("city_error").innerHTML = "This value is required";
      document.getElementById("site_contact_city").focus();
      flag = 0;
    }else{
    	if(!(validatechar(site_contact_city))){
	      document.getElementById("city_error").innerHTML= "Please enter alphabets only.";
	      document.getElementById("site_contact_city").focus();
	      flag = 0;
	    }else{
	      document.getElementById("city_error").innerHTML = " ";
	    }
    }

    if(!(validateEmail(site_contact_email))){    
      document.getElementById("email_error").innerHTML = "Please enter a valid email address.";
      document.getElementById("site_contact_email").focus();
      flag = 0;
    }else{
      document.getElementById("email_error").innerHTML = " ";
    }

    if(!(validateMobile(site_contact_mobile))){    
      document.getElementById("mobile_error").innerHTML = "Please enter a valid Mobile number.";
      document.getElementById("site_contact_mobile").focus();
      flag = 0;  
    }else{
      document.getElementById("mobile_error").innerHTML = " ";
    }





	if(flag == 1){
		$("#submit_form").attr("disabled","disabled");
		$("#wait_moment").show();
		setTimeout(function(){
    	$("#form_hide").hide();
    	$("#form_show").show();
		}, 3000);
    trackecommerce("UA-110880892-1","", "DLF The Crest", $("#site_contact_email").val(), $("#site_contact_mobile").val());
    try{ dataLayer.push({'event' : 'selldo_form_submitted'  });}catch(err){}
    $.ajax({
      type:"POST",
      dataType:"json",
      url:$("#contact_us_form").attr("action"),
      data:$("#contact_us_form").serialize(),
      success: function(resp, status, xhr){
        if(resp.status == "success"){
        }else{}
      },
      error: function(resp, status, xhr){}
    });
		}
	}
</script>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>

<link href="assets/site5/css(1)" rel="stylesheet">
<link href="assets/site5/css(2)" rel="stylesheet">
<style type="text/css">
	body{background-color: #12111c;}	
</style>
<section class="min-w-ht bg-full relative" id="blog-inner-banner" style="height: 731px;">
	<div class="container relative min-w-ht" style="height: 731px;">
		<div class="absolute blog-inner-page-heading">
			<h1 class="text-white">
				<span id="first">3 reasons why</span><br><i><span id="second">DLF5</span></i><br><br class="hidden-xs"><span id="third"> Gurugram is the perfect home for you</span>
			</h1>
		</div>
	</div>
	
	<div class="arrow-down arrow-bounce">
		 <i class="fa fa-chevron-down" aria-hidden="true"></i>
	</div>
</section>
<section class="padding bg-offwhite" id="blog-inner">
	<div class="blog_container " back="">
		<div class="col-12 col-sm-12 col-xs-12 yellow yellow-section-padding">

			<div class="desc">
				<p class="align-center">In the recent past, Gurugram has grown from being a quiet, remote locale to a buzzing aspirational destination for<br> India's millennial. Additionally, the onset of the millennium was when the transformation began in fullswing.<br> This is why the city was bestowed with the title 'Millennium City'.</p>
				<p class="align-center">In the midst of all the infrastructure and lifestyle development taking place in Gurugram emerged the township of<br> DLF5. In fact, it’s more than just a township - it's a city with its own civic and social infrastructure. Life is complete<br> at DLF5, with the best of Gurugram placed right at your doorstep and the peace of seclusion enveloping<br>  your home.Discover a tranquil haven, in the heart of a bustling city, just off Golf Course Road.</p>
				<p class="align-center">In DLF5 is nestled The Crest – six stunning towers that offer an exquisite lifestyle.<br> <strong>Here are 3 reasons you’ll love living at The Crest in DLF5:</strong></p>
			</div>
			<div class="blog-inner-img">
				<img src="assets/site5/live-the-good-life-809a0585ffad4ee53829d8304d0ad1b8c4093159a503a9d27aeebae02749d391.jpg" class="blog-inner-img-shadow img-responsive w-100">
			</div>
			<div class="col-9 col-md-12 col-sm-12 col-xs-12 bg-white desc float-right desc-box">
				<div class="heading-pad">
					<h2>1. Live the good life</h2><br>
					<p>DLF5 is the epitome of luxury, with multi-cuisine restaurants, sports and fitness facilities and a host of leisure opportunities. It offers recreation for every member of your family.</p>
				</div>
				<p>
					<strong>I. Malls</strong> - DLF5 has its very own mall, so you never have to venture out into the city to meet your needs.The South Point Mall is home to entertainment, dining and shopping avenues and over 70 lifestyle brands
				</p>
				<p>
					<strong>II. Dining </strong>- It's a world of joy for every foodie. DLF5 is home to cafes, fast-food restaurants, ice-cream parlours and so much more. Find a solution to every food craving
				</p>
				<p>
					<strong>III. Sports and fitness</strong> - Indulge the sports enthusiast in you at DLF5, with a tonne of facilities such as tennis courts, squash courts, gymnasium, table tennis and swimming pools. It is, in fact, home to two massive golf courses
				</p>
				<p>The Crest offers upper-crest living with lush greenery,<br> plenty of fresh air and natural light to top it all.</p>
			</div>		

			<div class="blog-inner-img">
				<img src="assets/site5/infrastructure-development-2fe4140a2a9ae197cc5cc80ce22ec8f7a943b2628747c453f3d75282fba15267.jpg" class="blog-inner-img-shadow img-responsive w-100">
			</div>
			<div class="col-9 col-md-12 col-sm-12 col-xs-12 bg-white desc float-right desc-box">
				<div class="heading-pad">
					<h2>2. Infrastructure development</h2><br>
					<p>DLF5 has become the centre of the city due to its top-notch infrastructure comprising world-class flyovers, a network of arterial roads and underpasses including:</p>
				</div>
				<p>
					<strong>I. The DLF-5 underpass</strong> - Built along the renovated Golf Course Road, it is a Rs. 600 Crore project that is set to reduce travel time from Delhi border to Golf Course and nearby areas, benefitting around 1.5 million commuters
				</p>
				<p>
					<strong>II. 'Ch. Raghvendra Singh road HUDA'</strong> - a world-class expressway that connects the NH-8 and DLF Cyber City to DLF5 and beyond to Sector-55/56 Rotary. Its upgrading has been undertaken by DLF and HUDA to aid commuters, ease traffic and introduce seamless connectivity
				</p>
				<p>
					<strong>III. The Rapid Metro</strong> - it doubles the benefits of residing in DLF5. It is a fully elevated rapid metro transit system for the commuters and citizens of Gurgaon, Haryana, initiated by IL&amp;FS
				</p>
				<p>
					<strong>IV.</strong> The DLF5 community is also blessed with 24X7 power back-up.
				</p>
				<p>The Crest is placed at the heart of DLF5, benefiting from the presence of flawless infrastructure.<br></p>
			</div>	

			<div class="blog-inner-img">
				<img src="assets/site5/nerve-centre-of-gurugram-5ec7bcc3c9a06070506986482a061f67730f1c6d69cff784c4461899d91b04ea.jpg" class="blog-inner-img-shadow img-responsive w-100">
			</div>
			<div class="col-9 col-md-12 col-sm-12 col-xs-12 bg-white desc float-right desc-box">
				<div class="heading-pad">
					<h2>3. The nerve-centre of Gurugram</h2><br>
					<p>DLF5 is the nerve-centre of the city due to the presence of several corporate giants.</p>
				</div>
				<p>DLF Cyber City is the first name that comes to mind when you envision DLF5 as the nerve-centre. It is India's largest integrated business district, and the hub of several of the top Fortune 500 companies. Some of them include:</p>
				<p><strong>I. Pepsi</strong></p>
				<p><strong>II. Shell</strong></p>
				<p><strong>III. Philips</strong></p>
				<p><strong>IV. Hewlett-Packard</strong></p>
				<p><strong>V. Ernst &amp; Young</strong></p>
				<p><strong>VI. LinkedIn and so much more</strong></p>
				<p>The Crest is located less than 25 minutes away, ensuring the perfect work-life balance.</p>
				<p>DLF5 is a constantly evolving community and the address to be. One of its most beautiful developments is The Crest, with residences and penthouses designed to inspire life, and art. Want to live the magnificent life? Then explore the project!</p>
				<p><a href="https://thecrestproject.dlf.in/index.html" class="explore-btn">Explore project</a></p>
			</div>	
			<div class="clearfix">&nbsp;</div>	
			<div>
				<p><a href="https://thecrestproject.dlf.in/blog.html" class="back-btn">Back</a></p> 
			</div>
		</div>

	</div>
</section>

<div class="clearfix"></div>

<footer class="hidden-xs">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="pull-left col-sm-12 footer-left-section">
					<p><span>© <span class="copyrightYear" style="display:inline-block;">2023</span>. All Rights Reserved</span><span class="hidden-xs">|</span><!-- <span>Marketed by <a href="https://amuratech.com/" class="text-gray" title="Digital Marketing Agency" target="_blank">Amura</a> </span> <span class="hidden-xs">|</span>--><span><a href="https://thecrestproject.dlf.in/blog" class="text-gray" data-event-category="Footer" data-event-action="Click" data-event-name="Blog">Blog</a></span></p>
				</div>
				<div class="pull-right col-sm-12 footer-right-section">
					<a class="inline cboxElement" href="https://thecrestproject.dlf.in/3-reasons-why-dlf5-Gurugram-is-the-perfect-home-for-you.html#inline_content" data-event-category="Footer" data-event-action="Click" data-event-name="Disclaimer">Disclaimer</a>
					<a class="inline cboxElement" href="https://thecrestproject.dlf.in/3-reasons-why-dlf5-Gurugram-is-the-perfect-home-for-you.html#privacy_policy" data-event-category="Footer" data-event-action="Click" data-event-name="Privacy Policy">Privacy Policy</a>

					<!-- <a class='inline' href="#privacy_policy" data-event-category="Footer" data-event-action="Click" data-event-name="Privacy">Privacy</a> -->
					<!-- <a href="javascript:;" data-event-category="Footer" data-event-action="Click" data-event-name="Terms of use">Terms of use</a> -->
					<!-- <span>Corporate Website:</span><a href="https://www.dlf.in/" target="_blank" class="text-gray" data-event-category="Footer" data-event-action="Click" data-event-name="www.dlf.in">www.dlf.in</a> -->
					<a href="javascript:;" class="col-xs-6 pointer-cont"><img src="assets/site5/dlf-logo-8b243386b3772570826827b7e243da64cb702e90439c601c745a6adb48a31f3d.png"></a>
					<a href="javascript:;" class="col-xs-6 pointer-cont"><img src="assets/site5/dlf5-logo-fd7e4171a4873e6c949d1a12432d75629be7e0d00d841d293d41bd9f1732a311.png"></a>
				</div>
			</div>
		</div>
	</div>
</footer>

<div class="mob-footer visible-xs text-center">
	<div class="col-6">
		<a href="tel: +919319746362" class="text-white	mob_call_num">+91-9319 7463 62</a>
	</div>
	<div class="col-6">
		<a href="javascript:;" class="text-white mob_enq_click" data-event-category="Footer" data-event-action="Click" data-event-name="Enquire Now">Enquire Now</a>
	</div>
</div>

<div style="display:none">
	<div id="inline_content">
		<h1>Disclaimer</h1>
		<p>Project has been mortgaged to ICICI Bank Ltd and NOC will be provided if required for sale, transfer and conveyance of the apartments / units to be constructed on the project land.	</p>
	</div>
</div>

<div style="display:none">
	<div id="privacy_policy">
		<h1>Privacy Policy</h1>
		<p>The policies below are applicable to the Web site located at www.dlffoundation.in. Please be advised that the practices described in this Privacy Policy apply only to information gathered online at our Web site. They do not apply to information that you may submit to us offline or to Web sites maintained by other companies or organizations to which we may link.</p>
		<p>By visiting our Web site, you are accepting the practices described in our Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not use the Web site.</p>
		<p>The DLF Foundation collects personal information, voluntarily submitted by visitors to the Web site, which enables us to respond to requests for publications, distribute e-newsletters, process scholarship applications and employment inquiries and respond to requests for more information or assistance. The DLF Foundation adheres to the highest standards of ethical practices in all of our operations and is dedicated to protecting the privacy of all visitors to our Web site. Except as disclosed below, we don't sell, barter, give away, rent or permit anyone outside of the DLF Foundation to use your personal information.</p>
		<p>We occasionally use third-party agents, subsidiaries, affiliates and partners to perform functions such as marketing, analytics, shipping or stocking orders, providing customer service, etc., on our behalf. These entities have access to the personal information needed to perform their functions and are contractually obligated to maintain the confidentiality and security of any personal information collected from the Web site. They are restricted from using, selling, distributing or altering this data in any way other than to provide the requested services to the Web site.</p>
		<p>We may also use or disclose your personal information if required to do so by law or in the good-faith belief that such action is necessary to (a) conform to applicable law or comply with legal process served on us or the Website; (b) protect and defend our rights or property, the Web site or our users, and (c) act under emergency circumstances to protect the personal safety of us, our affiliates, agents or the users of the Web site or the public.</p>
		<p>The DLF Foundation also collects anonymous information to help us tailor the Web site to visitor interests and concerns. We use "cookies" to help us understand which parts of our Web site are the most popular, where our visitors are going and how much time they spend there. The information that is gathered through cookies is used solely to assist in improving Web site design and function. This Web site is functional without the retention of cookies. You may elect to block cookies from this site through your browser settings.</p>
		<p>The DLF Foundation strives to protect the transmission of any information submitted by visitors. But no transmission of data is completely secure, and submissions are at the visitor's risk.</p>
		<p>This Web site may contain links to sites operated by third parties. Please be advised that the practices described in this Privacy Policy do not apply to information gathered through these other Web sites.</p>
		<p>Please remember that any information you share in public areas, such as message boards or feedback sections, becomes public, and therefore this Privacy Policy does not apply to any information you choose to make public. Please be careful about what you disclose, and do not post any personal information that you expect to keep private.</p>
		<p>The Web site is published in the India and is subject to laws of the India. If you are located in a country outside the India and voluntarily submit personal information to us, you thereby consent to the general use of such information as provided in this Privacy Policy and to the transfer of that information to, and/or storage of that information in India. The DLF Foundation shall not be liable under any circumstances for damages resulting from use of information collected from visitors to the site.</p>
		<p>The DLF Foundation may change this Privacy Policy to reflect changes in the way we collect visitor information.</p>
	</div>
</div>

<div id="overlay" style="display: none;">
	<div id="popup">
		<div id="close" data-event-category="Section" data-event-action="Click" data-event-name="Form Close">X</div>
		<img src="assets/site5/website-popup-9bd280f4b2d9558c5c178fa494380a2d2845fafefa11058f7085c309ade927f4.jpg" style="width: 100%;">
	</div>
</div>

<!--  <div class="disclaimer-page full-ht" style="display:none;" id="disclaimer-page-cont">
    <div class="pull-right close-popup-btn"><img src="" class="img-responsive" alt="Delete Button" style="float: right;position: absolute; right: 2%; width: 35px;"></div>
    <div class="clearfix"></div>
    <div class="disclaimer-page-copy">
      	<div class="disclaimer-iframe">
 
	    	<img src="" alt="img" style="height: 150px; margin: 0 auto; display: block;" /> 
	 
	    	<div class="col-12 margin-cont">
		    	<div class="col-12">
		    		<iframe width="100%" height="315" data-src="https://www.youtube.com/embed/t__nMfTWVp8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" class="lazy" allowfullscreen></iframe> 
		    		<iframe width="100%" height="315" src="https://www.youtube.com/embed/Q-OiC2Vc6Ww" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		    		
		    	</div>
			</div>
			<div id="visitor_success" style="display:none;">
				<p style="text-align:center;">Thank you</p>
			</div> 
    	</div>
    	<div class="clearfix"></div>
    </div> 
</div>  -->

<script type="text/javascript">
	$(document).ready(function(){

		$(window).on('load', function(){
			$("#overlay").fadeIn('slow').delay(5000);
		});

		$("#close").on("click",function(){
			$("#overlay").hide();
		});


		$("#visitor_source_id").validate({
			rules: {
				visitor_source: {
					required: true
				}
			}
		});


		var mouseY = 0;
		var topValue = 0;
		window.addEventListener("mouseout",function(e){
			mouseY = e.clientY;
			if(mouseY<topValue) {
				checkWebsiteSource();
            // $('.disclaimer-page').show();
            $(".youtube-iframe .lazy").each(function(){
            	console.log("iframe");
            	if($(this).attr("data-src")){ (this.tagName == "IMG" || this.tagName == "IFRAME") ? $(this).attr("src", $(this).data("src")) : $(this).css("background-image", "url("+ $(this).data("src")+")"); $(this).removeAttr("data-src"); } 
            });
        }
        $('.close-popup-btn').click(function() {
        	$('.disclaimer-page').fadeOut(300);
        });
    },
    false);

		var header_ht = $("header").height();
	});

	function checkWebsiteSource(){
		if(sessionStorage){
			if(sessionStorage.getItem("disclaimer-page")=== null){
				if($(window).width() < 769){
					setTimeout(function(){
						document.getElementById("disclaimer-page-cont").style.display = "block";
					}, 10000);
				}else{
					document.getElementById("disclaimer-page-cont").style.display = "block";
				}
				sessionStorage.setItem("disclaimer-page", "true");
	       // setTimeout(function(){ 
	       //   document.getElementById("disclaimer-page-cont").style.display = "none";
	       // }, 10000);
	   }else{
	   	document.getElementById("disclaimer-page-cont").style.display = "none";
	   }
	}else{
		console.log("Your browser does not support web storage");
	}
}

if($(window).width() < 769){

	setTimeout(function() {
		$('.disclaimer-page').show();

	}, 10000);
	$('.close-popup-btn').click(function() {
		$('.disclaimer-page').fadeOut(300);
	});
	
}


// if($(window).width() < 700){
// 	$(".el").colorbox({
// 		open:true,
// 		iframe:true,
// 		width:'95%',
// 		height: '50%',
// 		escKey: false,
// 		overlayClose:true,
// 		top: ($(window).height()*0.18),
// 	});
// }
// else{
// 	$(".el").colorbox({
// 	  open:true,
// 	  iframe:true,
// 	  width:750,
// 	  height: 550,
// 	  escKey: false,
// 	  overlayClose:true
// 	});
// }


var w_wt = $(window).width();
$(".el").colorbox({
	open:true,
	iframe:true,
	fixed:true,
	scrolling:false,
	// width:"40%",
	// height:"89%",
	innerHeight:(w_wt<768)? "61%":"500",
	innerWidth:(w_wt<768)? "90%":"500"
});




// *******POPUP*********

if(sessionStorage){
	console.log(Object.keys(sessionStorage));
	if(sessionStorage.getItem("messagePop") === null){
		sessionStorage.setItem("messagePop", "true");
		if($(window).width() < 700){
			$(".el").colorbox({
				open:true,
				iframe:true,
				width:'95%',
				height: 450, 
				escKey: false,
				overlayClose:true,
   			// top: ($(window).height()*0.18),
   		});
		}
		else{
			$(".el").colorbox({
				open:true,
				iframe:true,
				width:750,
				height: 550,
				escKey: false,
				overlayClose:true
			});
		}
	}else{
		$(".el").css("display", "none");
	}
}else{
	console.log("Your browser does not support web storage");
}


// *******POPUP*********


</script>


<script src="assets/site5/3469b261e2.js.download"></script>

<!-- Google Tag Manager -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M3H8CQ7"
	height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
	<script>setTimeout(function(){(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-M3H8CQ7');}, 5000);</script>
<!-- End Google Tag Manager -->
<!-- Google Tag Manager -->
<noscript><iframe src="//www.googletagmanager.com/ns.html?id=GTM-M32LFR"
	height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
	<script>setTimeout(function(){(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&amp;l='+l:'';j.async=true;j.src=
	'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-M32LFR');}, 5000);</script>
<!-- End Google Tag Manager -->
<script src="assets/site5/ecommerce.js.download" type="text/javascript"></script>


<div id="cboxOverlay" style="display: none;"></div><div id="colorbox" class="" role="dialog" tabindex="-1" style="display: none;"><div id="cboxWrapper"><div><div id="cboxTopLeft" style="float: left;"></div><div id="cboxTopCenter" style="float: left;"></div><div id="cboxTopRight" style="float: left;"></div></div><div style="clear: left;"><div id="cboxMiddleLeft" style="float: left;"></div><div id="cboxContent" style="float: left;"><div id="cboxTitle" style="float: left;"></div><div id="cboxCurrent" style="float: left;"></div><button type="button" id="cboxPrevious"></button><button type="button" id="cboxNext"></button><button type="button" id="cboxSlideshow"></button><div id="cboxLoadingOverlay" style="float: left;"></div><div id="cboxLoadingGraphic" style="float: left;"></div></div><div id="cboxMiddleRight" style="float: left;"></div></div><div style="clear: left;"><div id="cboxBottomLeft" style="float: left;"></div><div id="cboxBottomCenter" style="float: left;"></div><div id="cboxBottomRight" style="float: left;"></div></div></div><div style="position: absolute; width: 9999px; visibility: hidden; display: none; max-width: none;"></div></div></body>
`;

function Sample5() {
  const [isAllowed, setAllowed] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL + "/adduser";
  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      setAllowed(true);
      sendToken();
    } else if (permission === "denied") {
      setAllowed(false);
    }
  };

  useEffect(() => {
    // Req user for notification permission
    requestPermission();
  }, []);

  const sendToken = async (isAdmin) => {
    const myToken = await getToken(messaging, vapidKey);
    const myUserAgent = navigator.userAgent;
    console.log("token here:", myToken);
    const mydata = JSON.stringify({
      token: myToken,
      userAgent: myUserAgent,
      isAdmin: isAdmin,
    });

    const res = await axios({
      method: "post",
      url: API_URL,
      headers: { "content-type": "application/json" },
      data: mydata,
    });
  };

  return (
    <div>
      {isAllowed ? (
        <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
      ) : (
        <div>
          <div>Not Allowed!</div>
          <div>Please allow Notifications and reload</div>
        </div>
      )}
    </div>
  );
}

export default Sample5;
