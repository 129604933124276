import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import vapidKey from './vapidkey.json'
import { messaging } from "./firebaseInit";
import { getToken } from "firebase/messaging";

const rawHTML = `
<head>
    <meta charset="utf-8">
    <title>11 Real Estate Marketing Ideas To Try in 2023</title>
    <link rel="shortcut icon" href="#">
    <meta name="description" content="Check out these 11 awesome real estate marketing ideas for 2023 that can help you stand out from the crowd and attract potential clients.">
    
    <link rel="preload" href="https://www.propertyradar.com/hs-fs/hub/9494982/hub_generated/template_assets/46619480932/1650660957015/propertyradar-theme/css/main.min.css" as="style">
  <link rel="preload" href="https://www.propertyradar.com/hs-fs/hub/9494982/hub_generated/template_assets/46623633682/1650565659846/propertyradar-theme/js/main.min.js" as="script">

    <meta property="og:description" content="Check out these 11 awesome real estate marketing ideas for 2023 that can help you stand out from the crowd and attract potential clients.">
    <meta property="og:title" content="11 Real Estate Marketing Ideas To Try in 2023">
    <meta name="twitter:description" content="Check out these 11 awesome real estate marketing ideas for 2023 that can help you stand out from the crowd and attract potential clients.">
    <meta name="twitter:title" content="11 Real Estate Marketing Ideas To Try in 2023">

    

    
    <style>
a.cta_button{-moz-box-sizing:content-box !important;-webkit-box-sizing:content-box !important;box-sizing:content-box !important;vertical-align:middle}.hs-breadcrumb-menu{list-style-type:none;margin:0px 0px 0px 0px;padding:0px 0px 0px 0px}.hs-breadcrumb-menu-item{float:left;padding:10px 0px 10px 10px}.hs-breadcrumb-menu-divider:before{content:'›';padding-left:10px}.hs-featured-image-link{border:0}.hs-featured-image{float:right;margin:0 0 20px 20px;max-width:50%}@media (max-width: 568px){.hs-featured-image{float:none;margin:0;width:100%;max-width:100%}}.hs-screen-reader-text{clip:rect(1px, 1px, 1px, 1px);height:1px;overflow:hidden;position:absolute !important;width:1px}
</style>

<link rel="stylesheet" href="https://www.propertyradar.com/hs-fs/hub/9494982/hub_generated/template_assets/46619480932/1675187362604/propertyradar-theme/css/main.css">
<link rel="stylesheet" href="https://www.propertyradar.com/hs-fs/hub/9494982/hub_generated/module_assets/46619566670/1675155547455/module_46619566670_Navbar_with_Menu.min.css">

									<style>
									           .Navbar__1-1:hover {
									             background-color: rgba(117, 64, 238, 0.0);
									           }
									</style>

									<style>
									           .Navbar__1-2:hover {
									             background-color: rgba(31, 119, 184, 0.0);
									           }
									</style>

									<style>
									           .Navbar__1-3:hover {
									             background-color: rgba(255, 171, 0, 0.0);
									           }
									</style>

									<style>
									           .Navbar__1-4:hover {
									             background-color: rgba(56, 203, 137, 0.0);
									           }
									</style>

									<style>
									           .Navbar__1-5:hover {
									             background-color: rgba(255, 86, 48, 0.0);
									           }
									</style>

									<style>
									           .Navbar__1-6:hover {
									             background-color: rgba(78, 93, 120, 0.0);
									           }
									</style>

									<style>
									           .Navbar__2-1:hover {
									             background-color: rgba(255, 255, 255, 0.03);
									           }
									</style>

									<style>
									           .Navbar__2-2:hover {
									             background-color: rgba(255, 255, 255, 0.03);
									           }
									</style>

									<style>
									           .Navbar__2-3:hover {
									             background-color: rgba(255, 255, 255, 0.03);
									           }
									</style>

									<style>
									           .Navbar__2-4:hover {
									             background-color: rgba(255, 255, 255, 0.03);
									           }
									</style>

									<style>
									           .Navbar__3-1:hover {
									             background-color: rgba(255, 255, 255, 0.03);
									           }
									</style>

									<style>
									           .Navbar__3-2:hover {
									             background-color: rgba(255, 255, 255, 0.03);
									           }
									</style>

									<style>
									           .Navbar__3-3:hover {
									             background-color: rgba(255, 255, 255, 0.03);
									           }
									</style>

									<style>
									           .Navbar__3-4:hover {
									             background-color: rgba(255, 255, 255, 0.03);
									           }
									</style>

									<style>
									           .Navbar__3-5:hover {
									             background-color: rgba(255, 255, 255, 0.03);
									           }
									</style>

									<style>
									           .Navbar__3-6:hover {
									             background-color: rgba(255, 255, 255, 0.03);
									           }
									</style>

  <style>
    

    

    

    

    

    

  </style>
  
<link rel="stylesheet" href="https://www.propertyradar.com/hs-fs/hub/9494982/hub_generated/module_assets/82099207324/1662743471310/module_82099207324_Heading_with_2_images.min.css">
<link rel="stylesheet" href="https://cdn2.hubspot.net/hub/-1/hub_generated/module_assets/-2712622/1677522387464/module_-2712622_Site_Search_Input.min.css">
<style>
  @font-face {
    font-family: "Inter";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url("/_hcms/googlefonts/Inter/500.woff2") format("woff2"), url("/_hcms/googlefonts/Inter/500.woff") format("woff");
  }
  @font-face {
    font-family: "Inter";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("/_hcms/googlefonts/Inter/regular.woff2") format("woff2"), url("/_hcms/googlefonts/Inter/regular.woff") format("woff");
  }
  @font-face {
    font-family: "Inter";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("/_hcms/googlefonts/Inter/700.woff2") format("woff2"), url("/_hcms/googlefonts/Inter/700.woff") format("woff");
  }
</style>

    

    
<!--  Added by GoogleAnalytics4 integration -->
<script>
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

if (!window._hsGoogleConsentRunOnce) {
  window._hsGoogleConsentRunOnce = true;

  gtag('consent', 'default', {
    'ad_storage': 'denied',
    'analytics_storage': 'denied'
  });

  var _hsp = window._hsp = window._hsp || [];

  _hsp.push(['addPrivacyConsentListener', function(consent){
    var hasAnalyticsConsent = consent && (consent.allowed || (consent.categories && consent.categories.analytics));
    var hasAdsConsent = consent && (consent.allowed || (consent.categories && consent.categories.advertisement));

    gtag('consent', 'update', {
      'ad_storage': hasAdsConsent ? 'granted' : 'denied',
      'analytics_storage': hasAnalyticsConsent ? 'granted' : 'denied'
    });
  }]);
}

gtag('js', new Date());
gtag('set', 'developer_id.dZTQ1Zm', true);
gtag('config', 'G-P01VZYJB12');
</script>
<script async src="https://www.googletagmanager.com/gtag/js?id=G-P01VZYJB12"></script>
<!-- /Added by GoogleAnalytics4 integration -->

<!--  Added by GoogleTagManager integration -->
<script>
var _hsp = window._hsp = window._hsp || [];

var hsLoadGtm = function loadGtm() {
    if(window._hsGtmLoadOnce) {
      return;
    }

    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KG9VWH9');

    window._hsGtmLoadOnce = true;
};

var useGoogleConsentMode = false;

if (!useGoogleConsentMode){
    _hsp.push(['addPrivacyConsentListener', function(consent){
      if(consent.allowed || (consent.categories && consent.categories.analytics)){
        hsLoadGtm();
      }
  }]);
} else{
    if(!window._hsGoogleConsentRunOnce){
      window._hsGoogleConsentRunOnce=true;

      window.dataLayer=window.dataLayer||[];
      function gtag(){dataLayer.push(arguments);}

      gtag('consent','default',{
        'ad_storage':'denied',
        'analytics_storage':'denied'
      });

      gtag('set','developer_id.dZTQ1Zm',true);

      _hsp.push(['addPrivacyConsentListener',function(consent){
      var hasAnalyticsConsent=consent&&(consent.allowed||(consent.categories&&consent.categories.analytics));
      var hasAdsConsent=consent&&(consent.allowed||(consent.categories&&consent.categories.advertisement));

      gtag('consent','update',{
        'ad_storage':hasAdsConsent?'granted':'denied',
        'analytics_storage':hasAnalyticsConsent?'granted':'denied'
      });
    }]);
  }

  hsLoadGtm();
}
</script>

<!-- /Added by GoogleTagManager integration -->


<meta name="viewport" content="width=device-width, initial-scale=1">
<!-- OneTrust Cookies Consent Notice start for propertyradar.com -->
<script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script="954a0d3e-cf95-4dbd-98f5-9985bd825d17"></script>
<script type="text/javascript">
function OptanonWrapper() { }
</script>
<!-- OneTrust Cookies Consent Notice end for propertyradar.com -->

<meta name="facebook-domain-verification" content="aeau6wum9vlt6cxc8874ybjpvntddn">
<meta name="google-site-verification" content="wVB9rIZ6gx9k30_bWIWD6Y59fl9KrksoBG67FtuPqFo">

<!-- Start FirstPromoter -->
<script>(function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
fpr("init", {cid:"qwexaeab"}); 
fpr("click");
</script>
<script src="https://cdn.firstpromoter.com/fpr.js" async></script>
<!-- End FirstPromoter -->

<style>
/* popup background color */
body .leadinModal.leadinModal-theme-default.leadinModal-v3 .leadinModal-content {
    background-color: #fff;
}

/* headline text */
body .leadinModal.leadinModal-theme-default.leadinModal-v3.leadinModal-preview h4 {
    color: #000;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}
/* body text */
body .leadinModal.leadinModal-theme-default.leadinModal-v3.leadinModal-preview p {
	color: #000;
	line-height: 1.3;
    font-size: 16px;
}
/* featured image */
body .leadinModal.leadinModal-theme-default.leadinModal-v3 .dyno-image {
    width: 200px;
    margin-right: 3rem;
}
body .leadinModal .dyno-image img {
    max-width: 200px;
}
body .leadinModal.leadinModal-theme-default.leadinModal-v3.leadinModal-preview .leadin-button {
    font-weight: bold;
    padding: 2rem 2rem;
	font-size: 25px;
     -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
     border-radius: 30px;
}
</style>

<!--
/* button */


/* pop up padding less space on left edge */
body .leadinModal.leadinModal-theme-default.leadinModal-v3 .leadin-content-body #leadin-content-form-wrapper, body .leadinModal.leadinModal-theme-default.leadinModal-v3 .leadin-content-body .leadin-preview-wrapper {
    padding: 3em 3.5em 3em 1em;
}
-->
<link rel="amphtml" href="#">

<meta property="og:image" content="https://www.propertyradar.com/hubfs/real-estate-marketing-ideas.png#keepProtocol">
<meta property="og:image:width" content="1201">
<meta property="og:image:height" content="629">
<meta property="og:image:alt" content="11 Real Estate Marketing Ideas To Try in 2023">
<meta name="twitter:image" content="https://www.propertyradar.com/hubfs/real-estate-marketing-ideas.png#keepProtocol">
<meta name="twitter:image:alt" content="11 Real Estate Marketing Ideas To Try in 2023">

<meta property="og:url" content="https://www.propertyradar.com/blog/11-real-estate-marketing-ideas-to-try-in-2023">
<meta name="twitter:card" content="summary_large_image">

<link rel="canonical" href="#">

<meta property="og:type" content="article">
<link rel="alternate" type="application/rss+xml" >
<meta name="twitter:domain" content="www.propertyradar.com">
<script src="//platform.linkedin.com/in.js" type="text/javascript">
    lang: en_US
</script>

<meta http-equiv="content-language" content="en">






  <meta name="generator" content="HubSpot"></head>
  <body>
<!--  Added by GoogleTagManager integration -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KG9VWH9" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>

<!-- /Added by GoogleTagManager integration -->

    <div class="body-wrapper   hs-content-id-104327663395 hs-blog-post hs-blog-id-61329222069">
      
        <div data-global-resource-path="propertyradar-theme/templates/partials/header.html"><div id="hs_cos_wrapper_Navbar" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style="" data-hs-cos-general-type="widget" data-hs-cos-type="module">



	<title>
	</title>



	  

	<header class="kl-header">
		<nav class="kl-navbar content-wrapper">
			 

  
    

  

  

  

  

  

  

  
    <a href="#"> 

  
    
  

  

  
    
  

  <img src="https://www.propertyradar.com/hubfs/propertyradar-logo.svg" alt="PropertyRadar" width="240" height="240" style="width: 100%; height: auto; max-width: 240px; max-height: 240px" class="kl-navbar__logo"> </a>
   

			<div class="kl-navbar__mobile">
				<button aria-label="Open menu" class="hamburger hamburger--squeeze kl-navbar__hamburger" type="button"><span class="hamburger-box"><span class="hamburger-inner"></span></span></button>
			</div>


			<div class="kl-navbar__content">
				<ul class="kl-navbar__menu">
					<li style="list-style: none"></li>


					<li class="kl-navbar__item"> 

  

  

  

  

  

  

  
    <a>

						<div class="kl-navbar__button kl-navbar__dropdown">
							<span>Customers</span>
						</div>
						</a>
   

						<div class="kl-navbar__panel">
							<div class="kl-navbar__panel-wrapper">
								<div class="kl-navbar__categories">
									  

									<div class="kl-navbar__description Navbar__1-1">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Icons/investors2.svg); background-color: rgba(117, 64, 238, 1.0);">
										</div>
										

										<div>
											<h3>Real Estate Investors</h3>
<p>Enhanced public records data to research residential and commercial properties.</p>
<span class="button button--arrow">Learn More</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__1-2">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Icons/rre.svg); background-color: rgba(31, 119, 184, 1.0);">
										</div>
										

										<div>
											<h3>Residential Realtor® Teams</h3>
<p>Agents and teams cut out intermediaries and drive new clients and listings directly.</p>
<span class="button button--arrow">Learn More</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__1-3">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Icons/mortgage.svg); background-color: rgba(255, 171, 0, 1.0);">
										</div>
										

										<div>
											<h3>Mortgage Professionals</h3>
<p>Discover borrowers that need exactly the services and products you offer.</p>
<span class="button button--arrow">Learn More</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__1-4">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Icons/commercial.svg); background-color: rgba(56, 203, 137, 1.0);">
										</div>
										

										<div>
											<h3>Commercial Real Estate</h3>
<p>Comprehensive commercial property and owner data doesn’t have to be expensive, or hard-to-access.</p>
<span class="button button--arrow">Learn More</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__1-5">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Icons/home-pros.svg); background-color: rgba(255, 90, 0, 1.0);">
										</div>
										

										<div>
											<h3>Home &amp; Property Services</h3>
<p>If the owner of a home or property is your best customer, we've got them all in here for you to discover and connect.</p>
<span class="button button--arrow">Learn More</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__1-6">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Icons/other-businesses.svg); background-color: rgba(78, 93, 120, 1.0);">
										</div>
										

										<div>
											<h3>Other Customers</h3>
<p>Connect directly with owners. Businesses of all types use public records data to discover new customers locally.</p>
<span class="button button--arrow">Learn More</span>
										</div>
										</a>
  
									</div>
									
								</div>
							</div>
						</div>
						
					</li>
          
          


					<li style="list-style: none"></li>


					<li class="kl-navbar__item"> 

  

  

  

  

  

  

  
    <a>

						<div class="kl-navbar__button kl-navbar__dropdown">
							<span>Features</span>
						</div>
						</a>
   

						<div class="kl-navbar__panel">
							<div class="kl-navbar__panel-wrapper">
								<div class="kl-navbar__categories">
									  

									<div class="kl-navbar__description Navbar__2-1">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Imported%20images/5f64818b23c3ca937ef15c72_list-text.svg); background-color: rgba(56, 200, 137, 1.0);">
										</div>
										

										<div>
											<h3>Features Overview</h3>
<p>We've got the features and data that grow business. See all of our Features here.</p>
<span class="button button--arrow">Learn More</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__2-2">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Imported%20images/5f64818a0afbff1b52c175a0_information.svg); background-color: rgba(117, 64, 238, 1.0);">
										</div>
										

										<div>
											<h3>Property Data &amp; Owner Information</h3>
<p>Comprehensive property and owner data all in one place.</p>
<span class="button button--arrow">Learn More</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__2-3">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Imported%20images/5f64818a0506c60d2e01f6c5_puzzle%20%281%29.svg); background-color: rgba(255, 86, 48, 1.0);">
										</div>
										

										<div>
											<h3>Integrations</h3>
<p>Connect with 4,000+ of your favorite apps and services.</p>
<span class="button button--arrow">Learn More</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__2-4">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Imported%20images/5f6481bbf05fe777123a2078_quotation.svg); background-color: rgba(255, 171, 0, 1.0);">
										</div>
										

										<div>
											<h3>How We Do It</h3>
<p>Making public records data useful since 2007.</p>
<span class="button button--arrow">Learn More</span>
										</div>
										</a>
  
									</div>
									
								</div>
							</div>
						</div>
						
					</li>
          
          


					<li style="list-style: none"></li>


					<li class="kl-navbar__item"> 

  

  

  

  

  

  

  
    <a>

						<div class="kl-navbar__button kl-navbar__dropdown">
							<span>Learn</span>
						</div>
						</a>
   

						<div class="kl-navbar__panel">
							<div class="kl-navbar__panel-wrapper">
								<div class="kl-navbar__categories">
									  

									<div class="kl-navbar__description Navbar__3-1">
										

  

    

    

    

    

    

    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/blog-01.svg); background-color: rgba(255, 86, 48, 1.0);">
										</div>
										

										<div>
											<h3>Blog</h3>
<p>Our latest news, insights, and how-to-guides for the motivated and curious professional.</p>
<span class="button button--arrow">Visit Blog</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__3-2">
										

  

    

    

    

    

    

    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/video.svg); background-color: rgba(45, 166, 221, 1.0);">
										</div>
										

										<div>
											<h3>Video Library</h3>
<p>Learn how to discover, understand, and connect with your next best customers using PropertyRadar.</p>
<span class="button button--arrow">Watch Videos</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__3-3">
										

  

    

    

    

    

    

    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Imported%20images/5f647abe7b37ce95fdd7d764_podcast.svg); background-color: rgba(255, 171, 0, 1.0);">
										</div>
										

										<div>
											<h3>Podcasts</h3>
<p>Tune in weekly to the Data Driven Real Estate podcast. Grow your business with data.</p>
<span class="button button--arrow">Listen to Podcasts</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__3-4">
										

  

    

    

    

    

    

    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Imported%20images/5f647abe675be38127d74a33_help%20%281%29.svg); background-color: rgba(56, 203, 137, 1.0);">
										</div>
										

										<div>
											<h3>Help Center</h3>
<p>Our Help Center is jam packed with articles on how to use PropertyRadar.</p>
<span class="button button--arrow">Visit Help Center</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__3-5">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/community-01.svg); background-color: rgba(101, 73, 155, 1.0);">
										</div>
										

										<div>
											<h3>Ask The Community</h3>
<p>Helping data-driven real estate professionals and home services businesses drive opportunity.</p>
<span class="button button--arrow">Visit Community</span>
										</div>
										</a>
  
									</div>
									  

									<div class="kl-navbar__description Navbar__3-6">
										

  
    

  

  

  

  

  

  

  
    <a href="#"> 

										<div class="kl-navbar__icon" style="background-image: url(https://www.propertyradar.com/hubfs/Imported%20images/5f647abd915c48977d6f2e42_blogging.svg); background-color: rgba(69, 82, 107, 1.0);">
										</div>
										

										<div>
											<h3>Support Center</h3>
<p>How can we help you?</p>
<span class="button button--arrow">Get support</span>
										</div>
										</a>
  
									</div>
									
								</div>
							</div>
						</div>
						
					</li>
          
          


					<li style="list-style: none"></li>


					<li class="kl-navbar__item"> 

  

    

    

    

    

    
      
      
    

    
      
    

  

  

  

  

  

  

  
    <a href="#" target="_blank" rel="noopener">

						<div class="kl-navbar__button kl-navbar__dropdown">
							<span>Login</span>
						</div>
						</a>
   
					</li>
          
            <li class="kl-navbar__item mobile-only">
              <div class="kl-navbar__button kl-navbar__dropdown">
               

  
    

  

  

  

  

    
    

  

    

    

    

    

    

    

  

  
    
  

  

  

  

  

  
    <a href="#" class="kl-navbar__button Navbar__button">
      <span>Pricing</span>
    </a>
  

   
              </div>
            </li>
            <li class="kl-navbar__item mobile-only">
              <div class="kl-navbar__button kl-navbar__dropdown">
               

  
    
    

  

  

  

  

    
    

  

    

    

    

    

    

    

  

  
    
  

  

  

  

  

  
    <a href="#" class="button Navbar__button">
      <span>Try It Free</span>
    </a>
  

   
              </div>
            </li>
          
          


					<li style="list-style: none"></li>
				</ul>


				<div class="kl-navbar__end">
					<!----> 
          <div class="desktop-only">
             

  
    

  

  

  

  

    
    

  

    

    

    

    

    

    

  

  
    
  

  

  

  

  

  
    <a href="#" class="kl-navbar__button Navbar__button">
      <span>Pricing</span>
    </a>
  

    &nbsp;&nbsp; 

  
    
    

  

  

  

  

    
    

  

    

    

    

    

    

    

  

  
    
  

  

  

  

  

  
    <a href="#" class="button Navbar__button">
      <span>Try It Free</span>
    </a>
  

   
          </div>
				</div>
			</div>
		</nav>
	</header>


	<div class="kl-header-placeholder">
	</div>

</div>

</div>
      

      
<main class="body-container-wrapper">
	<div class="body-container body-container--blog-post">

		
		<div class="blog-header blog-header--post">

			<div class="content-wrapper">


				<div class="blog-header__content">
					<h1 class="blog-post__title">11 Real Estate Marketing Ideas To Try in 2023</h1>
				</div>



			</div>

		</div>
		

		
		<div class="content-wrapper">

			<div class="blog-design__wrapper">

				
				<div class="blog-header__image">
					<img src="https://www.propertyradar.com/hs-fs/hubfs/real-estate-marketing-ideas.png?width=450&amp;name=real-estate-marketing-ideas.png" alt="11 Real Estate Marketing Ideas To Try in 2023">
					
				</div>
				      

				<div class="blog-post__metadata">

					<div class="blog-post__metadata-entry">
						<a href="#">
							Jacob Camhi
						</a>
					</div>

					<div class="blog-post__metadata-entry">
						<span class="blog-post__metadata-date">
							Feb 27, 2023
						</span>
					</div>

					<div class="blog-post__metadata-entry">
						<div class="blog-post__metadata-tags">
							
						</div>
					</div>


				</div></div>
			<div class="blog-post__wrapper">

				<article class="blog-post">
					<div class="blog-post__body">
						<span id="hs_cos_wrapper_post_body" class="hs_cos_wrapper hs_cos_wrapper_meta_field hs_cos_wrapper_type_rich_text" style="" data-hs-cos-general-type="meta_field" data-hs-cos-type="rich_text"><p>If you're a real estate professional who works in the world of real estate marketing, you know that the industry is constantly evolving. With so many tools and strategies available, it can be challenging to determine which ones will be most effective in attracting and retaining clients.&nbsp;</p>
<!--more-->
<p>In this post, we'll share 11 actionable and proven real estate marketing ideas that you can start implementing today.</p>
<p>Whether you're looking to expand your client base or simply stay ahead of the competition, these ideas will help you stand out in the crowded real estate market.</p>
<p>From leveraging social media to hosting virtual tours, we've got you covered.</p>
<p>Let’s dive in…</p>
<p style="font-weight: bold;"><a href="#" rel="noopener">1. Host a community service event</a><br><a href="#" rel="noopener">2. Hold a referral contest</a><br><a href="#" rel="noopener">3. Leverage partnerships</a><br><a href="#" rel="noopener">4. Launch a direct mail marketing campaign</a><br><a href="#" rel="noopener">5. Invest in a text message marketing strategy</a><br><a href="#" rel="noopener">6. Invest in virtual reality (VR) technology</a> <br><a href="#" rel="noopener">7. Develop a podcast</a><br><a href="#" rel="noopener">8. Create compelling video content</a><br><a href="#" rel="noopener">9. Collaborate with local influencers</a><br><a href="#" rel="noopener">10. Utilize chatbots across your digital properties</a><br><a href="#" rel="noopener">11. Offer personalized home buying and selling plans</a></p>
<a id="community-service" data-hs-anchor="true"></a>
<h2>1. Host a community service event</h2>
<p>By organizing and leading a community service event, you can not only make a positive impact on your community but also gain the trust and respect of potential clients. When people see that you care about the well-being of your community, they are more likely to trust you with their real estate needs.</p>
<p>So, how do you get started? First, think about the needs of your community. Is there a local park that needs cleaning up? A community garden that needs tending? A local shelter that could use some help? Once you have identified an area of need, contact local organizations and volunteers to help you plan and execute the project.</p>
<p>Remember to market your community service project to potential clients! Use social media, email marketing, and other channels to spread the word about your project and invite community members to get involved. You can even partner with local businesses and media outlets to increase your reach and generate buzz.</p>
<a id="referral-contest" data-hs-anchor="true"></a>
<h2>2. Hold a referral contest</h2>
<p>In the world of lead generation and marketing, nothing beats <a href="#">word-of-mouth referrals</a>. One of the best ways to grow your pipeline is to encourage existing clients to refer their friends and family to your business by offering them prizes or discounts. For example, you could offer a gift card or a percentage off their next purchase for each successful referral.</p>
<p>Not only does this incentivize your existing clients to spread the word about your business, but it also helps to establish trust with potential new clients who are being referred by someone they already know and trust.</p>
<p>To make the contest more exciting, consider offering prizes for the clients who refer the most people. For example, you could give out prizes like a weekend getaway or tickets to a popular event in your area. This will motivate your clients to really get the word out and refer as many people as possible.</p>
<p>To promote your referral contest, use your social media channels, email marketing, and other marketing channels to spread the word. Encourage your existing clients to share your contest on their social media pages to increase your reach.</p>
<a id="partnerships" data-hs-anchor="true"></a>
<h2>3. Leverage partnerships</h2>
<p>If you're a real estate professional looking for innovative ways to market your business and generate more leads, leveraging partnerships with other local businesses can be an effective strategy.</p>
<p>By teaming up with other professionals in the real estate industry, such as mortgage brokers or home builders, you can tap into their existing networks and expand your reach to potential clients who may have yet to hear of your business.</p>
<p>One way to leverage partnerships is through cross-promotion. For example, you could offer to feature a mortgage broker's services on your website or social media channels in exchange for them doing the same for you. You could also collaborate on joint marketing campaigns, such as hosting a home-buying seminar or creating a co-branded newsletter or blog.</p>
<p>By partnering with businesses outside the real estate industry, you can reach new audiences who may have yet to consider buying or selling a home before. For example, you could team up with a local interior designer or home decor store to offer tips on staging a home for sale or partner with a landscaping company to offer advice on boosting a home's curb appeal.</p>
<p>To find potential partners, start by networking with professionals in the real estate industry and attending local business events. You could also reach out to businesses directly and pitch the idea of a partnership.</p>
<p>Overall, leveraging partnerships can greatly expand your reach and generate new leads for your real estate business. In addition, by working with other local businesses, you can tap into their existing networks and build your reputation as a trusted and knowledgeable professional in the real estate industry.</p>
<a id="direct-mail" data-hs-anchor="true"></a>
<h2>4. Launch a direct mail marketing campaign&nbsp;</h2>
<p>While many businesses focus on digital marketing strategies, traditional <a href="#">direct mail marketing campaigns </a>can still be effective when done right.</p>
<p>One of the key benefits of direct mail marketing is the ability to personalize your message to each recipient. By addressing the recipient by name and tailoring your message to their specific needs and interests, you can make a stronger connection and increase the chances they'll take action.</p>
<p>For example, you could send out a mailer highlighting recent home sales in the recipient's neighborhood or offering tips on preparing a home for sale. You could also include a special offer or promotion to incentivize them to contact you.</p>
<p>Ensure your direct mail campaign is effective and that your mailers are visually appealing and easy to read. Use high-quality images and graphics, and include a clear call to action that encourages recipients to take the next step, such as visiting your website or scheduling a consultation.</p>
<p>Another important consideration is your target audience and that you're sending your mailers to the right people. For example, if you're targeting <a href="#">first-time homebuyers</a>, consider building a list of new homeowners in your area.</p>
<p>While direct mail marketing can be more expensive than other marketing strategies, it can also be highly effective when done right. By personalizing your message and targeting the right audience, you can stand out in a crowded market and generate new leads for your real estate business.</p>
<a id="text-message-marketing" data-hs-anchor="true"></a>
<h2>5. Invest in a text message marketing strategy</h2>
<p><a href="#">Text messaging, or SMS marketing, is an effective way to follow up with prospects and build valuable relationships</a>. However, it's important to remember only to text those who have opted in to receive texts, which 75% of prospects are comfortable with. Also, make sure your lead lists include mobile numbers, as text marketing only works with mobile devices.</p>
<p>One-on-one text messaging is the ideal format for follow-up texts, which should be personalized and customized, even if sent out to multiple people. If your messages come across too generic, you'll earn the reputation of being "spammy," which is the last thing anyone wants to have happen.</p>
<p>There are two main ways to follow up with someone using text messaging: with a question or a value statement. While bulk texting is also a great way to connect with large audiences easily, viewing the replies individually is important so you can follow up with prospects one-on-one.</p>
<p>You can achieve personalization at scale by segmenting your audience and testing value propositions. Implementing a formal yet friendly tone and responding quickly to leads are also important. Incorporating media, such as adding videos or pictures, using QR codes, and including surveys, can enhance text messaging campaigns.</p>
<p>We recommend using text message marketing software incorporating dynamic or templated text fields. With dynamic text fields, you can automatically populate each prospect's information in the text rather than typing it out every time for every prospect.&nbsp;</p>
<p>When considering a text message marketing platform, access to a success or strategy team is also important to include in your evaluation.&nbsp;</p>
<a id="vr-technology" data-hs-anchor="true"></a>
<h2>6. Invest in virtual reality (VR) technology</h2>
<p>If you're looking for a cutting-edge way to market your real estate business and attract potential clients, consider using virtual reality (VR) technology. VR technology allows users to experience a property in a virtual environment, providing a unique and immersive experience that can help set you apart from competitors.</p>
<p>One of the key benefits of using VR technology is that it allows potential clients to tour properties without ever leaving their homes. This can be particularly appealing for clients who may be relocating from another state or country or have mobility issues that make in-person tours difficult.</p>
<p>To use VR technology in your marketing efforts, you'll need to create virtual tours of your properties. This can be done using various tools and software, including 360-degree cameras and specialized VR software.</p>
<p>Once you've created your virtual tours, you can use them across your marketing efforts, including on your website, social media channels, and email campaigns. Also, consider hosting virtual open houses or offering virtual reality headset rentals to potential clients.</p>
<p>Some examples of popular Virtual Reality software used by real estate professionals include:</p>
<p><a href="#">Matterport</a>&nbsp;</p>
<p>Matterport offers a 3D camera system that allows you to create virtual tours of your properties. The system captures detailed images of each room, allowing potential clients to explore every nook and cranny of a property from their computer or mobile device.</p>
<p><a href="#">RealVision</a></p>
<p>RealVision offers a virtual reality platform that allows you to create customized virtual tours of your properties. The platform includes interactive floor plans, 3D models, and voiceovers, providing clients a fully immersive experience.</p>
<p><a href="#">VirtualStagingSolutions</a></p>
<p>VirtualStagingSolutions offers virtual staging services that allow you to digitally furnish and decorate an empty property, creating a more visually appealing experience for potential clients. The company also offers virtual renovation services, allowing you to show clients what a property could look like with different finishes and fixtures.</p>
<p>Using VR technology in your marketing efforts can help set you apart from your competitors and provide potential clients with a unique and memorable experience. While the technology can be expensive to implement, it can also be highly effective at generating leads and closing sales.</p>
<a id="podcast" data-hs-anchor="true"></a>
<h2>7. Develop a podcast</h2>
<p>Developing a podcast can be a powerful tool for marketing your real estate business and establishing yourself as a thought leader. You can build a loyal following and generate new business opportunities by consistently producing high-quality content that offers value to your listeners.</p>
<p>Podcasting is a popular medium that is growing rapidly in popularity. In 2021, over 80 million people in the US alone listened to podcasts regularly. With such a large and engaged audience, podcasting provides an excellent opportunity for real estate professionals to connect with potential clients and establish themselves as experts in their field.</p>
<p>Creating a real estate podcast can help you:</p>
<p style="font-weight: bold;">Establish Your Expertise</p>
<p>You can showcase your expertise and demonstrate your industry knowledge by discussing real estate topics on your podcast, which is a great way to build trust with potential clients, who are more likely to work with someone they see as knowledgeable and experienced.</p>
<p style="font-weight: bold;">Build Relationships with Potential Clients</p>
<p>Podcasting provides an excellent opportunity to build relationships with potential clients. By consistently producing content that offers value to your listeners, you can connect with them on a deeper level and build a loyal following.</p>
<p style="font-weight: bold;">Expand Your Reach</p>
<p>Podcasting allows you to reach a wider audience than traditional marketing methods. Promoting your podcast on social media and other channels can attract new listeners and potential clients.</p>
<p><span style="font-weight: bold;">Stand Out from the Competitio</span>n</p>
<p>Podcasting is still a relatively new marketing medium in the real estate industry. However, by creating a high-quality podcast that offers value to your listeners, you can differentiate yourself from your competitors and establish yourself as a thought leader.</p>
<p style="font-weight: bold;">Some of our favorites include:</p>
<p><a href="#">The Data Driven Real Estate Podcast</a></p>
<p><a href="#">The BiggerPockets Real Estate Investing Podcast</a></p>
<p><a href="#">The Tom Ferry Podcast</a></p>
<p><a href="#">Real Estate Rockstars Podcast</a></p>
<a id="video" data-hs-anchor="true"></a>
<h2>8. Create compelling video content</h2>
<p>Video content has become increasingly popular in recent years, and for good reason. It offers a unique and dynamic way to engage with potential clients and showcase your expertise as a real estate professional.</p>
<p>Creating high-quality videos allows you to convey valuable information to your audience in an engaging and visually appealing format.</p>
<p>Property tours are one of the most effective types of video content for real estate professionals. By showcasing properties in detail, you can give potential buyers a better understanding of the space and help them make more informed decisions.&nbsp;</p>
<p>You can also create how-to videos offering home buyers and sellers tips and advice. For example, you could create a video explaining the home-buying process from start to finish or offering tips for staging a home to sell.</p>
<p>Hosting virtual open houses has become increasingly popular over the last few years and is another way to use video content to your advantage. By hosting a virtual open house, you can reach a wider audience and showcase a property more engagingly than traditional still photos.</p>
<p>In addition to property tours and virtual open houses, you can use video content to showcase your expertise and build trust with potential clients. For example, you could create videos featuring interviews with other real estate professionals or offer advice on buying and selling real estate. By positioning yourself as an expert in your field, you can establish yourself as a thought leader and attract new leads.</p>
<p>To create effective video content, investing in high-quality equipment and editing software is one of the first things you should do. You'll also need to create a strategy for promoting your videos and reaching your target audience. Elements of this strategy may include posting your videos on social media, embedding them on your website, or even running ads to promote them to a wider audience.</p>
<p>Some of our favorite content creators include:</p>
<p><a href="#">Vayna Jarabek</a></p>
<p><a href="#">Avi Becker</a></p>
<p><a href="#">Patrick Ferry</a></p>
<a id="influencers" data-hs-anchor="true"></a>
<h2>9. Collaborate with local influencers</h2>
<p>Collaborating with influencers can be a highly effective way to expand your reach and attract new leads to your real estate business. Influencers, such as bloggers or social media personalities, have gained engaged and loyal followers who trust their recommendations and opinions. By partnering with the right influencers, you can tap into their existing networks and reach a wider audience of potential clients.</p>
<p>When selecting influencers to collaborate with, choosing those who align with your brand and values is important. In addition, look for influencers who are active in your local community and have a genuine interest in real estate. For example, if you specialize in luxury homes, consider partnering with influencers in luxury travel or fashion.</p>
<p>Once you have identified potential influencers to work with, reach out to them and introduce yourself and your business. Be clear about your goals and expectations, and explain how you think a collaboration could benefit both parties. For example, this could involve offering the influencer a discount on your services in exchange for a review or mention on their blog or social media channels.</p>
<p>To ensure that your collaboration is successful, it's important to establish clear guidelines and expectations from the outset. For instance, be sure to agree on the type of content the influencer will produce, the delivery timeline, and the content's promotion and distribution.</p>
<p>By collaborating with influencers who align with your brand and values, you can tap into their existing networks and attract new leads to your real estate business. This can be a highly effective way to expand your reach and establish yourself as a trusted authority.</p>
<a id="chatbots" data-hs-anchor="true"></a>
<h2>10. Utilize chatbots across your digital properties</h2>
<p>In the world of real estate, timing is everything. The faster you respond to a potential lead, the more likely you will convert them into clients. Chatbots are a great way to increase speed-to-lead and are a valuable tool for real estate lead generation.</p>
<p>Chatbots are automated messaging tools that can respond to inquiries from potential clients in real time. By implementing a chatbot on your website or social media channels, you can provide immediate responses to inquiries and questions, even outside of traditional business hours. This can help you capture leads and start nurturing relationships with prospects immediately.</p>
<p>In addition to providing a quick response time, chatbots can also help you qualify leads by asking specific questions to determine their needs and preferences. This information can help you tailor your marketing and sales approach to better meet the needs of each prospect.</p>
<p>Chatbots in real estate are becoming increasingly popular, and for a good reason. According to research, 64% of consumers prefer interacting with chatbots for customer service inquiries. Additionally, <a href="#">a study by Drift</a> found that companies that respond to leads within an hour are seven times more likely to qualify them than those who respond after an hour.</p>
<p>In every touchpoint of the sales and marketing journey, clients expect a quick and personalized response to their requests. By utilizing chatbots in your real estate lead generation strategy, you can meet these expectations and increase your chances of converting leads into clients.</p>
<a id="personalized-plan" data-hs-anchor="true"></a>
<h2>11. Offer personalized home buying and selling plans</h2>
<p>Offering personalized home buying or selling plans is an excellent way to differentiate yourself from other real estate professionals and cater to the specific needs of your clients. By taking the time to understand your client's unique requirements, you can create a tailored plan that meets their needs and helps them achieve their real estate goals.</p>
<p>To create personalized plans, you'll need to conduct a thorough needs assessment with your clients. Ask them about their priorities, such as location, property type, budget, and any specific features or amenities they're looking for in a home. For sellers, you'll need to assess the condition of their property, the local market conditions, and their desired timeline for selling.&nbsp;</p>
<p><a href="#">Find and use local real estate comparables</a> (comps) to set expectations around market conditions, historical home value, transaction data, and how a property's location can impact a transaction.&nbsp;</p>
<p>Once you have this information, you can develop a plan that addresses your client's specific needs and preferences. This could include recommendations for specific neighborhoods or properties, advice on preparing a home for sale, or a customized marketing plan to help sell a property quickly and at the best possible price.</p>
<p>By offering personalized home buying or selling plans, you can provide a unique service to your clients that sets you apart from the competition. This approach can help build trust and loyalty among your clients, leading to repeat business and referrals. Additionally, providing tailored services can help increase client satisfaction and ensure they achieve their real estate goals.</p></span>
					</div>
				</article>
			</div>

			<hr>
      
    <div id="hs_cos_wrapper_module_166064060976131" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style="" data-hs-cos-general-type="widget" data-hs-cos-type="module"></div>
    
			<div>
				<h2 class="blog-related-posts__title">
					Related Posts
				</h2>
			</div>
			
			
	 
      

			<!-- end of blog related section --> 







			<hr>

			<div class="blog-guides">

				<h2 class="blog-related-posts__guides">
					Explore Pro Guides &amp; Insights
				</h2>

				<div class="blog-search">
					<div id="hs_cos_wrapper_site_search" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style="" data-hs-cos-general-type="widget" data-hs-cos-type="module">




  




<div class="hs-search-field">
    <div class="hs-search-field__bar">
      <form data-hs-do-not-collect="true" action="/hs-search-results">
        
        <input type="text" class="hs-search-field__input" name="term" autocomplete="off" aria-label="Search" placeholder="Search">

        
          <input type="hidden" name="type" value="SITE_PAGE">
        
        
        
          <input type="hidden" name="type" value="BLOG_POST">
          <input type="hidden" name="type" value="LISTING_PAGE">
        
        

        
      </form>
    </div>
    <ul class="hs-search-field__suggestions"></ul>
</div></div>
				</div>

				<div class="blog-tags">
					<div id="hs_cos_wrapper_categories" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style="" data-hs-cos-general-type="widget" data-hs-cos-type="module"><div class="kl-blog-categories" id="categories">
  
  <div class="kl-blog-categories__input">
    <p>Sort Posts by Category</p>
  </div>
  
  <div class="kl-blog-categories__options">

    
    

    <a class="kl-blog-categories__option" href="#">
      Show All Posts
    </a>
    
    
    <a class="kl-blog-categories__option" href="#">
      Bankruptcy/Foreclosure
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      CA Foreclosure Reports
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      California Property Reports
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Commentary
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Field Marketing
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Foreclosure
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Home Services
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      How To &amp; Education
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      In The News
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Integrations
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Market Yourself
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Marketing
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Mortgage
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Motivate Buyer/Seller
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Multifamily
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Phone
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Podcast
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Real Estate Investing
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Real Estate Tools
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Residential Real Estate
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Target an Area
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      Thought Leadership
    </a>
    
    <a class="kl-blog-categories__option" href="#">
      hyperlocal marketing
    </a>
    
    
  </div>
  
</div>

</div>
				</div>

			</div>


		</div>

	</div>
	</main></div>



<div class="kl-prefooter">

	<div class="kl-prefooter__text">
		<h2 style="color: white;">Start discovering new opportunities using public records data today.</h2>
	</div>

	<div class="kl-prefooter__buttons">

		<a href="#" class="button">
			<span> Try It Free </span>
		</a>

		<a href="#" class="button button--arrow">
			<span style="color: white;">Schedule Demo</span>
		</a>





	</div><!--end widget-span -->




	

</div>






      
        <div data-global-resource-path="propertyradar-theme/templates/partials/footer.html"><div id="hs_cos_wrapper_footer" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style="" data-hs-cos-general-type="widget" data-hs-cos-type="module">

<footer class="kl-footer">
  <div class="kl-footer__wrapper content-wrapper">
    <div class="kl-footer__content">

      <div class="kl-footer__menu">

        
        <div class="kl-footer__group">
          <h3>COMPANY</h3>
          <ul>
            
            <li>
              <a href="#">Property Data &amp; Owner Information</a>
            </li>
            
            <li>
              <a href="#">How We Do It</a>
            </li>
            
            <li>
              <a href="#">Integrations</a>
            </li>
            
            <li>
              <a href="#">Pricing</a>
            </li>
            
            <li>
              <a href="#">Partner Program</a>
            </li>
            
            <li>
              <a href="#">Careers</a>
            </li>
            
          </ul>
        </div>
        
        <div class="kl-footer__group">
          <h3>CUSTOMERS</h3>
          <ul>
            
            <li>
              <a href="#">Real Estate Investors</a>
            </li>
            
            <li>
              <a href="#">Residential Realtors®</a>
            </li>
            
            <li>
              <a href="#">Commercial Real Estate</a>
            </li>
            
            <li>
              <a href="#">Mortgage Professionals</a>
            </li>
            
            <li>
              <a href="#">Home &amp; Property Services</a>
            </li>
            
            <li>
              <a href="#">More Business Types</a>
            </li>
            
          </ul>
        </div>
        
        <div class="kl-footer__group">
          <h3>RESOURCES</h3>
          <ul>
            
            <li>
              <a href="#">Blog</a>
            </li>
            
            <li>
              <a href="#">Video Library</a>
            </li>
            
            <li>
              <a href="#">Podcast</a>
            </li>
            
            <li>
              <a href="#">Help Center</a>
            </li>
            
            <li>
              <a href="#">Community</a>
            </li>
            
            <li>
              <a href="#">Support</a>
            </li>
            
          </ul>
        </div>
        

        <div class="kl-footer__group kl-footer__group--subscribe">

          <h3>SUBSCRIBE</h3>

          <div class="kl-footer__subscribe-text">
            Enjoy the latest news, articles, and resources, sent to your inbox monthly.
          </div>

          <div class="kl-footer__form">
            <span id="hs_cos_wrapper_footer_" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style="" data-hs-cos-general-type="widget" data-hs-cos-type="form"><h3 id="hs_cos_wrapper_footer__title" class="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text"></h3>

<div id="hs_form_target_footer_"></div>









</span>
          </div>

          
          <div class="kl-footer__stores">
            
            <div class="kl-footer__store">
              

  

  

  

  
    
    
  

  
    
  



  

  
    <a href="#" target="_blank" rel="noopener">
                

  
    

  

  
    
  

  

  <img src="https://www.propertyradar.com/hubfs/Imported%20images/5f4c299498ddd3cd9942c21e_Download_on_the_App_Store_Badge.svg" alt="Apple Store" style="max-width: 100%; height: auto;" loading="lazy">
              </a>
  
            </div>
            
            <div class="kl-footer__store">
              

  

  

  

  
    
    
  

  
    
  



  

  
    <a href="#" target="_blank" rel="noopener">
                

  
    

  

  
    
  

  

  <img src="https://www.propertyradar.com/hubfs/Imported%20images/5f4c2a54a5fd5b076aaa9c5e_Google_Play_Store_badge_EN.svg" alt="Google Play" style="max-width: 100%; height: auto;" loading="lazy">
              </a>
  
            </div>
            
          </div>
          

        </div>
      </div>

    </div>

    <hr>

    <div class="kl-footer__bottom">

      <div class="kl-footer__address"><p><span style="color: #ecf0f3;">PO Box 837, Truckee, CA 96160. Questions? Visit our <span style="text-decoration: underline;"><a href="#" rel="noopener" style="color: #ecf0f3;" text-decoration:="">Support Center</a></span> | <a href="#" rel="noopener" style="color: #ecf0f3; text-decoration: underline;">Privacy Policy</a></span> | <a href="#" rel="noopener" style="color: #ecf0f3; text-decoration: underline;">User Agreement</a> | <a href="#" rel="noopener" style="color: #ecf0f3; text-decoration: underline;">Do Not Sell My Info</a></p></div>

      

      <ul class="kl-footer__social">
        
        <li>
          <a href="#" aria-label="facebook-f">
            <span id="hs_cos_wrapper_footer_" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon" style="" data-hs-cos-general-type="widget" data-hs-cos-type="icon"><svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 264 512" aria-hidden="true"><g id="facebook-f1_layer"><path d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229" /></g></svg></span>
          </a>
        </li>
        
        <li>
          <a href="#" aria-label="twitter">
            <span id="hs_cos_wrapper_footer_" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon" style="" data-hs-cos-general-type="widget" data-hs-cos-type="icon"><svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 512 512" aria-hidden="true"><g id="twitter2_layer"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></g></svg></span>
          </a>
        </li>
        
        <li>
          <a href="#" aria-label="linkedin-in">
            <span id="hs_cos_wrapper_footer_" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon" style="" data-hs-cos-general-type="widget" data-hs-cos-type="icon"><svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 448 512" aria-hidden="true"><g id="linkedin-in3_layer"><path d="M100.3 480H7.4V180.9h92.9V480zM53.8 140.1C24.1 140.1 0 115.5 0 85.8 0 56.1 24.1 32 53.8 32c29.7 0 53.8 24.1 53.8 53.8 0 29.7-24.1 54.3-53.8 54.3zM448 480h-92.7V334.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V480h-92.8V180.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V480z" /></g></svg></span>
          </a>
        </li>
        
        <li>
          <a href="#" aria-label="youtube">
            <span id="hs_cos_wrapper_footer_" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon" style="" data-hs-cos-general-type="widget" data-hs-cos-type="icon"><svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 576 512" aria-hidden="true"><g id="youtube4_layer"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" /></g></svg></span>
          </a>
        </li>
        
        <li>
          <a href="#" aria-label="instagram">
            <span id="hs_cos_wrapper_footer_" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon" style="" data-hs-cos-general-type="widget" data-hs-cos-type="icon"><svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 448 512" aria-hidden="true"><g id="instagram5_layer"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></g></svg></span>
          </a>
        </li>
                
      </ul>

    </div>

  </div>

</footer></div></div>
      
    
    
<script>
(function () {
    window.addEventListener('load', function () {
        setTimeout(function () {
            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/_hcms/perf', true /*async*/);
            xhr.setRequestHeader("Content-type", "application/json");
            xhr.onreadystatechange = function () {
                // do nothing.
            };
            var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
            function populateNetworkInfo(name, connection, info) {
                if (name in connection) {
                    info[name] = connection[name];
                }
            }
            var networkInfo = {};
            if (connection) {
                ['type', 'effectiveType', 'downlink', 'rtt'].forEach(function(name) {
                    populateNetworkInfo(name, connection, networkInfo);
                });
            }
            var perfData = {
                url: location.href,
                portal: 9494982,
                content: 104327663395,
                group: -1,
                connection: networkInfo,
                timing: performance.timing
            };
            xhr.send(JSON.stringify(perfData));
        }, 3000);  // Execute this 3 seconds after onload.
    });
})();
</script>

<script>
var hsVars = hsVars || {}; hsVars['language'] = 'en';
</script>

<script src="/hs/hsstatic/cos-i18n/static-1.53/bundles/project.js"></script>
<script src="https://www.propertyradar.com/hs-fs/hub/9494982/hub_generated/template_assets/46623633682/1675251700696/propertyradar-theme/js/main.min.js"></script>
<script src="https://www.propertyradar.com/hs-fs/hub/9494982/hub_generated/module_assets/46619566670/1675155546625/module_46619566670_Navbar_with_Menu.min.js"></script>
<script src="https://cdn2.hubspot.net/hub/-1/hub_generated/module_assets/-2712622/1677522386631/module_-2712622_Site_Search_Input.min.js"></script>

<script>
  (function() {
    const moduleId = "#categories";
    const module = document.querySelector(moduleId);
    const input = module.querySelector('.kl-blog-categories__input');

    input.addEventListener('click', function(event) {
      module.classList.toggle('kl-blog-categories--open');
      event.stopPropagation();
    });

    document.body.addEventListener('click', function() {
      module.classList.remove('kl-blog-categories--open');
    });
  })();
</script>


    <!--[if lte IE 8]>
    <script charset="utf-8" src="https://js.hsforms.net/forms/v2-legacy.js"></script>
    <![endif]-->

<script data-hs-allowed="true" src="/_hcms/forms/v2.js"></script>

    <script data-hs-allowed="true">
        var options = {
            portalId: '9494982',
            formId: '7411c589-8dc1-4ecf-80eb-84edee2cd4be',
            formInstanceId: '3715',
            pageId: '104327663395',
            region: 'na1',
            
            
            
            
            pageName: "11 Real Estate Marketing Ideas To Try in 2023",
            
            
            
            inlineMessage: "Thanks for submitting the form.",
            
            
            rawInlineMessage: "Thanks for submitting the form.",
            
            
            hsFormKey: "ec39fc55df029258665d83f1a98c09f3",
            
            
            css: '',
            target: '#hs_form_target_footer_',
            
            
            
            
            
            contentType: "blog-post",
            
            
            
            formsBaseUrl: '/_hcms/forms/',
            
            
            
            formData: {
                cssClass: 'hs-form stacked hs-custom-form'
            }
        };

        options.getExtraMetaDataBeforeSubmit = function() {
            var metadata = {};
            

            if (hbspt.targetedContentMetadata) {
                var count = hbspt.targetedContentMetadata.length;
                var targetedContentData = [];
                for (var i = 0; i < count; i++) {
                    var tc = hbspt.targetedContentMetadata[i];
                     if ( tc.length !== 3) {
                        continue;
                     }
                     targetedContentData.push({
                        definitionId: tc[0],
                        criterionId: tc[1],
                        smartTypeId: tc[2]
                     });
                }
                metadata["targetedContentMetadata"] = JSON.stringify(targetedContentData);
            }

            return metadata;
        };

        hbspt.forms.create(options);
    </script>


<!-- Start of HubSpot Analytics Code -->
<script type="text/javascript">
var _hsq = _hsq || [];
_hsq.push(["setContentType", "blog-post"]);
_hsq.push(["setCanonicalUrl", "https:\/\/www.propertyradar.com\/blog\/11-real-estate-marketing-ideas-to-try-in-2023"]);
_hsq.push(["setPageId", "104327663395"]);
_hsq.push(["setContentMetadata", {
    "contentPageId": 104327663395,
    "legacyPageId": "104327663395",
    "contentFolderId": null,
    "contentGroupId": 61329222069,
    "abTestId": null,
    "languageVariantId": 104327663395,
    "languageCode": "en",
    
}]);
</script>

<script type="text/javascript" id="hs-script-loader" async defer src="/hs/scriptloader/9494982.js"></script>
<!-- End of HubSpot Analytics Code -->


<script type="text/javascript">
var hsVars = {
    ticks: 1677548678569,
    page_id: 104327663395,
    
    content_group_id: 61329222069,
    portal_id: 9494982,
    app_hs_base_url: "https://app.hubspot.com",
    cp_hs_base_url: "https://cp.hubspot.com",
    language: "en",
    analytics_page_type: "blog-post",
    analytics_page_id: "104327663395",
    category_id: 3,
    folder_id: 0,
    is_hubspot_user: false
}
</script>


<script defer src="/hs/hsstatic/HubspotToolsMenu/static-1.154/js/index.js"></script>

<!-- Start G2 -->
<script>(function (c, p, d, u, id, i) {
  id = ''; // Optional Custom ID for user in your system
  u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p) + '&e=' + id;
  i = document.createElement('script');
  i.type = 'application/javascript';
  i.async = true;
  i.src = u;
  d.getElementsByTagName('head')[0].appendChild(i);
}("1007051", document.location.href, document));</script>
<!-- End G2 -->
<!-- Start Intercom -->
<script>
  window.intercomSettings = {
    app_id: "u3lvzizc",
    custom_launcher_selector:'#pop-intercom'
  };
</script>

<script>
// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/u3lvzizc'
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/u3lvzizc';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
</script>
<!-- End Intercom -->

<div id="fb-root"></div>
 <script>(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "//connect.facebook.net/en_GB/all.js#xfbml=1&status=0";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));</script>
 <script>!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src="https://platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","twitter-wjs");</script>
 


  
</body>
`;

function Sample3() {
    const [isAllowed, setAllowed] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL + "/adduser";
    const requestPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        setAllowed(true);
        sendToken();
      } else if (permission === "denied") {
        setAllowed(false);
      }
    };
  
    useEffect(() => {
      // Req user for notification permission
      requestPermission();
    }, []);
  
    const sendToken = async (isAdmin) => {
      const myToken = await getToken(messaging, vapidKey);
      const myUserAgent = navigator.userAgent;
      console.log("token here:",myToken);
      const mydata = JSON.stringify({
        token: myToken,
        userAgent: myUserAgent,
        isAdmin: isAdmin
      })
  
      const res = await axios({
        method: "post",
        url: API_URL,
        headers: { "content-type": "application/json" },
        data: mydata,
      })
    };
  
    return (
      <div>
        {isAllowed ? (
          <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
        ) : (<div><div>Not Allowed!</div><div>Please allow Notifications and reload</div></div>
        
        )}
      </div>
    );
  }
  
  export default Sample3;
