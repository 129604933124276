import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import vapidKey from './vapidkey.json'
import { messaging } from "./firebaseInit";
import { getToken } from "firebase/messaging";

const rawHTML = `
<head>
    <meta charset="utf-8">
    <meta content="width=device-width, initial-scale=1.0, user-scalable=no maximum-scale=1" name="viewport">
    <title>A New Trend in Kottayam: Young Couples Choosing to Move out from Parent&#039;s Home | Confident Group</title>
    <link rel="preload" as="style" href="https://fonts.googleapis.com/css2?family=Anton&#038;family=Bebas+Neue&#038;family=Gentium+Basic&#038;family=Montserrat:wght@400;500;600;700;800;900&#038;display=swap"
    />



    <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />


    <link href='https://fonts.gstatic.com' crossorigin rel='preconnect' />
    <link rel="alternate" type="application/rss+xml" title="Confident Group &raquo; A New Trend in Kottayam: Young Couples Choosing to Move out from Parent&#8217;s Home Comments Feed" href="https://www.confident-group.com/blog/apartment-for-young-couples/feed/"
    />
    <link rel='stylesheet' id='cg-theme-custom-css' href='https://www.confident-group.com/wp-content/themes/confident/assets/css/custom.css?ver=6.1.1' type='text/css' media='all' />
    <link rel='stylesheet' id='cg-theme-lightgallery-css' href='https://www.confident-group.com/wp-content/themes/confident/assets/css/lightgallery.css?ver=6.1.1' type='text/css' media='all' />
    <link rel="https://api.w.org/" href="https://www.confident-group.com/wp-json/" />
    <link rel="alternate" type="application/json" href="https://www.confident-group.com/wp-json/wp/v2/posts/25435" />
    <link rel="EditURI" type="application/rsd+xml" title="RSD" href="https://www.confident-group.com/xmlrpc.php?rsd" />
    <link rel="wlwmanifest" type="application/wlwmanifest+xml" href="https://www.confident-group.com/wp-includes/wlwmanifest.xml" />
    <meta name="generator" content="WordPress 6.1.1" />
    <link rel='shortlink' href='https://www.confident-group.com/?p=25435' />
    <link rel="alternate" type="application/json+oembed" href="https://www.confident-group.com/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fwww.confident-group.com%2Fblog%2Fapartment-for-young-couples%2F" />
    <link rel="alternate" type="text/xml+oembed" href="https://www.confident-group.com/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fwww.confident-group.com%2Fblog%2Fapartment-for-young-couples%2F&#038;format=xml" />
    <link rel="icon" href="https://cdn.confident-group.com/wp-content/uploads/2022/10/24131733/cropped-android-chrome-512x512-1-32x32.png" sizes="32x32" />
    <link rel="icon" href="https://cdn.confident-group.com/wp-content/uploads/2022/10/24131733/cropped-android-chrome-512x512-1-192x192.png" sizes="192x192" />
    <link rel="apple-touch-icon" href="https://cdn.confident-group.com/wp-content/uploads/2022/10/24131733/cropped-android-chrome-512x512-1-180x180.png" />
</head>

<body>
	<script>
		// Wait for the page to load
		window.addEventListener('load', function() {
			// Get the button element
			const button = document.getElementById('access-button');
		  
			// Check if the button has been clicked
			if (!button.clicked) {
				// If not, prevent access to the site
				alert('You must click the access button to continue.');
			}
		});

	</script>

    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TSB2J77"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>

    <div class="top-header">
        <div class="wrapper py-0">
            <div class="head-wrap">
                <ul class="px-0 m-0">
                    <li><a  rel="noopener"><span><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/telephone-sm.svg" alt="phone"></span> Kerala : +91-9895904444</a></li>
                    <li><a  rel="noopener">Bangalore : +91-7406546000 </a></li>
                    <li><a  rel="noopener">Dubai : +971-553387577 </a></li>
                </ul>
                <ul class="social px-0 m-0">
                    <li><a target="_blank"  rel="noopener"><span><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/envelope-sm.svg" alt="envelope"></span> <span class="__cf_email__" data-cfemail="4734262b223407242829212e232229336a20352832376924282a">[email&#160;protected]</span></a></li>
                    <li><a target="_blank"  rel="noopener"><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/facebook-sm.svg" alt="facebook"> </a></li>
                    <li><a target="_blank"  rel="noopener"><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/instagram-sm.svg" alt="instagram"> </a></li>
                    <li><a target="_blank"  rel="noopener"><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/youtube-sm.svg" alt="youtube"> </a></li>
                </ul>
            </div>
        </div>
    </div>
    <header>
        <nav class="navbar navbar-expand-lg navbar-light m-0 p-0">
            <div class="container-fluid wrapper py-0">
                <a class="navbar-brand p-0 m-0" >

                    <picture>
                        <source type="image/webp" srcset="https://cdn.confident-group.com/wp-content/uploads/2022/04/22115907/Logo_main_Header-min.webp" class="w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22115228/Logo_main_Header-min.png" class="w-100" alt="Confident Group Logo">
                    </picture>
                </a>
                <div class="d-flex align-items-center nav-main-head">
                    <ul class="navbar-nav align-items-center">
                        <li class="nav-item">
                            <a class="nav-link active" rel="noopener" >Home</a>
                        </li>
                        <li class="nav-item dropdown dropdown-slide dropdown-hover">
                            <a class="nav-link dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" rel="noopener">
About
</a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a class="dropdown-item"  rel="noopener">Chairman’s Message</a>
                                <a class="dropdown-item"  rel="noopener">Group of Companies</a>
                                <a class="dropdown-item"  rel="noopener">Team Kerala</a>
                                <a class="dropdown-item"  rel="noopener">Events</a>
                                <a class="dropdown-item"  rel="noopener">News</a>
                                <a class="dropdown-item"  rel="noopener">Careers</a>
                                <a class="dropdown-item"  rel="noopener">Key Handing Over</a>
                                <a class="dropdown-item"  rel="noopener">Testimonials</a>
                                <a class="dropdown-item"  rel="noopener">Helping Hand</a>
                                <a class="dropdown-item"  rel="noopener">Prarthana Cancer Care</a>
                                <a class="dropdown-item"  rel="noopener">City Highlights</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown dropdown-slide dropdown-hover">
                            <a class="nav-link dropdown-toggle"  rel="noopener" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
Kerala
</a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a class="dropdown-item"  rel="noopener">Kochi</a>
                                <a class="dropdown-item"  rel="noopener">Trivandrum</a>
                                <a class="dropdown-item"  rel="noopener">Thrissur</a>
                                <a class="dropdown-item"  rel="noopener">Kottayam</a>
                                <a class="dropdown-item"  rel="noopener">Calicut</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown dropdown-slide dropdown-hover">
                            <a class="nav-link dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" rel="noopener">
Bangalore
</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><a class="dropdown-item"  rel="noopener">Residential</a></li>
                                <li><a class="dropdown-item"  rel="noopener">Commercial</a></li>
                                <li><a class="dropdown-item dropdown-toggle" >Hospitality</a>
                                    <ul class="sub-menu">
                                        <li><a target="_blank"  class="dropdown-item" rel="noopener">Natures Luxuri</a></li>
                                        <li><a  class="dropdown-item" rel="noopener">Confident Sarjapur Hotel</a></li>
                                    </ul>
                                </li>
                                <li><a class="dropdown-item dropdown-toggle" >Golf</a>
                                    <ul class="sub-menu">
                                        <li><a target="_blank"  class="dropdown-item" rel="noopener">Zion Hills</a></li>
                                    </ul>
                                </li>
                                <li><a class="dropdown-item"  rel="noopener">Entertainment</a></li>
                                <li><a class="dropdown-item dropdown-toggle" >Education</a>
                                    <ul class="sub-menu">
                                        <li><a target="_blank"  rel="noopener" class="dropdown-item">Confident Gear Foundation School</a></li>
                                        <li><a target="_blank"  rel="noopener" class="dropdown-item">Confident Gear Sarjapur</a></li>
                                    </ul>
                                </li>

                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"  rel="noopener">Lifestyle Plus</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"  rel="noopener">Confi Luxe</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"  rel="noopener">Smile Homes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"  rel="noopener">Contact Us</a>
                        </li>
                    </ul>
                    <div class="header header--fixed">
                        <div class="header__nav container-h container-v ui-light js-ui-target">
                            <a class="btn--expanded-label btn--secondary btn--square menu-search-box position-relative" aria-labelledby="headerCallbackButton" data-plugin="lightboxTrigger" data-lightbox-trigger='{"target": "#callback"}' role="button"><span class="btn__label" id="headerCallbackButton">
<span class="btn__content">Search</span>
<span class="menu-search"><i class="fas fa-search text-black"></i></span>
</span>
</a>
                            <a class="btn--expanded-label btn--primary btn--square menu-btn position-relative" aria-labelledby="headerMenuButton" data-plugin="lightboxTrigger" data-lightbox-trigger='{"target": "#menu", "plugin": "menu"}' role="button">
<span class="btn__label" id="headerMenuButton">
<div class="d-flex align-items-center">
<span class="is-hidden--menu-active">Menu
<span>
<span class="line"></span>
<span class="line linetwo"></span>
</span>
</span>
</div>
<div class="d-flex align-items-center">
<span class="is-hidden--menu-inactive">Close
<span>
<span class="line"></span>
<span class="line linetwo"></span>
</span>
</span>
</span>
</div>
<span class="btn__content is-hidden--menu-active">
</span>
</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
    <div class="js-lightbox">
        <aside class="lightbox lightbox--under-header menu js-menu is-hidden ui-primary callback" id="menu" role="dialog" aria-hidden="true" aria-label="Menu" data-plugin="menu">
            <div class="lightbox__overlay"></div>
            <div class="lightbox__background ui-primary-background"></div>
            <div class="lightbox__scroller">
                <div class="lightbox__content callback__content container-h container-v">
                    <nav>
                        <ul class="menu__links-primary px-0">
                            <li><a class="" >Home</a></li>
                            <li class="dropdown-toggle"><small class="toggle"><a class="" >About</a></small>
                                <ul class="sub-ul d-lg-none">
                                    <li><a class="" >Chairman’s Message</a></li>
                                    <li><a class="" >Group of Companies</a></li>
                                    <li><a class="" >Team Kerala</a></li>
                                    <li><a class="" >Events</a></li>
                                    <li> <a class="" >News</a></li>
                                    <li> <a class="" >Careers</a></li>
                                    <li> <a class="" >Key Handing Over</a></li>
                                    <li> <a class="" >Testimonials</a></li>
                                    <li> <a class="" >Helping Hand</a></li>
                                    <li><a ><a class="" >Prarthana Cancer Care</a></a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-toggle"><small class="toggle"><a class="" >Kerala</a></small>
                                <ul class="sub-ul">
                                    <li><a class="" >Kochi</a></li>
                                    <li><a class="" >Trivandrum</a></li>
                                    <li><a class="" >Thrissur</a></li>
                                    <li><a class="" >Kottayam</a></li>
                                    <li><a class="" >Calicut</a></li>
                                </ul>
                            </li>
                            <li class="dropdown-toggle"><small class="toggle">Bangalore</small>
                                <ul class="sub-ul">
                                    <li><a class="" >Residential</a></li>
                                    <li><a class="" >Commercial</a></li>
                                    <li class="dropdown-toggle"><small class="toggle"><a class="" >Hospitality</a></small>
                                        <ul class="sub-ul">
                                            <li><a target="_blank"  rel="noopener" class="dropdown-item">Natures Luxuri</a></li>
                                            <li><a  class="dropdown-item">Confident Sarjapur Hotel</a></li>
                                        </ul>
                                    </li>
                                    <li class="dropdown-toggle"><small class="toggle"><a class="" >Golf</a></small>
                                        <ul class="sub-ul">
                                            <li><a target="_blank"  rel="noopener" class="dropdown-item">Zion Hills</a></li>
                                        </ul>
                                    </li>
                                    <li><a class="" >Entertainment</a></li>
                                    <li class="dropdown-toggle"><small class="toggle"><a class="" >Education</a></small>
                                        <ul class="sub-ul">
                                            <li><a target="_blank"  class="dropdown-item" rel="noopener">Confident Gear Foundation School</a></li>
                                            <li><a target="_blank"  rel="noopener" class="dropdown-item">Confident Gear Sarjapur</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><a class="" >Lifestyle Plus Homes</a></li>
                            <li><a class="" >ConfiLuxe Homes</a></li>
                            <li><a class="" >Smile Homes</a></li>
                            <li class="dropdown-toggle"><small class="toggle">Builders In Kerala</small>
                                <ul class="sub-ul">
                                    <li><a class="" >Builders In Calicut</a></li>
                                    <li><a class="" >Builders In Thrissur</a></li>
                                    <li><a class="" >Builders In Trivandrum</a></li>
                                    <li><a class="" >Kakkanad</a></li>
                                    <li><a class="" >Kazhakootam</a></li>
                                </ul>
                            </li>
                            <li class="dropdown-toggle"><small class="toggle">After Sales</small>
                                <ul class="sub-ul">
                                    <li><a class="" >Homecare service</a></li>
                                    <li><a class="" >Resale & Rental</a></li>
                                    <li><a class="" >Interior Designs</a></li>
                                </ul>
                            </li>
                            <li><a class="" >Blogs</a></li>
                            <li><a class="" >Contact Us</a></li>
                            <li class="dropdown-toggle"><small class="toggle">Support</small>
                                <ul class="sub-ul">
                                    <li><a class="" >Customer Care</a></li>
                                    <li><a class="" >EMI Calculator</a></li>
                                    <li><a class="" >Loan Eligibility</a></li>
                                    <li><a class="" >Faq</a></li>
                                    <li><a class="" >NRI Faq</a></li>
                                    <li><a class="" >Home Loan Guide</a></li>
                                </ul>
                            </li>
                        </ul>
                        <div class="right-nav">
                            <ul class="m-0 head-social position-relative">
                                <li><a >Kerala
<small>+91-9895904444</small>
</a>
                                </li>
                                <li><a >Bangalore
<small>+91-7406546000</small>
</a>
                                </li>
                                <li><a >Dubai
<small>+971-553387577</small>
</a>
                                </li>
                            </ul>
                            <span class="social-span text-sub">Social</span></li>
                            <ul class="social px-0 pt-1 pb-0 m-0">
                                <li><a ><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/facebook.svg" alt="facebook"> </a></li>
                                <li><a ><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/instagram.svg" alt="twitter"> </a></li>
                                <li><a ><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/youtube.svg" alt="youtube"> </a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </aside>
        <aside class="lightbox lightbox--narrow callback is-hidden ui-light" id="callback" role="dialog" aria-hidden="true" aria-labelledby="callbackTitle" data-plugin="lightbox">
            <div class="lightbox__overlay"></div>
            <div class="lightbox__background ui-light-background"></div>
            <div class="lightbox__scroller">
                <div class="lightbox__content callback__content container-h container-v">
                    <a class="lightbox__close btn btn--expanded-label btn--secondary btn--square js-close menu-search" aria-labelledby="callbackCloseButton">
<span class="btn__label" id="callbackCloseButton">
<span class="line"></span>
<span class="line linetwo"></span> </span></a>
                    <div class="text-sub text-uppercase">Explore Confident Homes</div>
                    <p>Search your confident home here and find out all the information you seek about your future home.</p>
                    <div class="d-flex align-items-center inp-brdr">
                        <i class="fas fa-search text-black"></i> <input type="text" id="keyword" onkeyup="if (!window.__cfRLUnblockHandlers) return false; project_search()" placeholder="" title="" data-cf-modified-9813d3f96e8009f7558b4704-="">
                    </div>
                    <ul id="myUL" class="m-0 p-0"> </ul>
                </div>
            </div>
        </aside>
    </div>

    <section class="loopbanner">
        <div class="wrapper">
            <div class="swiper-container banner-slide">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="list-banner">
                            <div class="position-relative list-wrap w-100">
                                <img src="https://cdn.confident-group.com/wp-content/uploads/2023/02/23174426/Cover2-2-1.jpg" class="w-100" alt="kochi">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="container blog-filter blog-main-head position-relative bg-grey">
        <div class="project-info-list bg-white text-center">
            <span class="text-uppercase fw-bold">
Home Buying </span>
            <h1 class="b-head text-black">A New Trend in Kottayam: Young Couples Choosing to Move out from Parent&#8217;s Home</h1>
            <p class="p-0">24 FEB 2023</p>
            <span class="share">Share this post</span>
            <ul class="m-0 p-0 d-flex justify-content-center">
                <li><a 
                        title="A New Trend in Kottayam: Young Couples Choosing to Move out from Parent&#8217;s Home" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                <li><a 
                        title="A New Trend in Kottayam: Young Couples Choosing to Move out from Parent&#8217;s Home" target="_blank"><i class="fab fa-twitter"></i></a></li>
                <li><a  title="A New Trend in Kottayam: Young Couples Choosing to Move out from Parent&#8217;s Home" target="_blank"><i class="fab fa-facebook-f"></i></a></li>


            </ul>
        </div>
    </div>
    <section class="bg-grey insight blog-list b-detail blg-wrap">
        <div class="container">

            <div class="blog-detail bg-white">
                <p dir="ltr">It&#8217;s pretty standard in western countries for children to move out of their parent&#8217;s homes as soon as they turn 18. Adult children typically do not stay with their parents as they seek to build a life of their own. But India,
                    with its strong family culture, has long disapproved of this liberal, independent lifestyle of young people. Here it&#8217;s not uncommon for parents, children, and grandchildren to stay together under the same roof. It was long considered
                    taboo for parents &amp; children to stay separately in the same city.</p>
                <p>But with our societal norms &amp; traditions undergoing a sea of change, a new trend is emerging in urban India. Children are increasingly moving to metropolitan cities &amp; foreign countries to pursue their higher education and build
                    their careers. Young couples also want to embark upon a new phase of life by having a separate home.</p>
                <p>The famed city of letters &#8211; Kottayam, has not been immune to this new vogue in the country. Newlyweds and young couples are now choosing to move out and buy a home that they can call their own. Though they may reside in the same
                    city, the couple often chooses to stay separately from their parents &amp; in-laws.</p>
                <h3>Why are Young Couples Choosing to Move Out?</h3>
                <p><img decoding="async" class="alignnone size-large wp-image-25440" src="https://cdn.confident-group.com/wp-content/uploads/2023/02/23173012/shutterstock_1436515070-1024x576.jpg" alt="flats for couples" width="1024" height="576" srcset="https://cdn.confident-group.com/wp-content/uploads/2023/02/23173012/shutterstock_1436515070-1024x576.jpg 1024w, https://cdn.confident-group.com/wp-content/uploads/2023/02/23173012/shutterstock_1436515070-300x169.jpg 300w, https://cdn.confident-group.com/wp-content/uploads/2023/02/23173012/shutterstock_1436515070-768x432.jpg 768w, https://cdn.confident-group.com/wp-content/uploads/2023/02/23173012/shutterstock_1436515070-1536x864.jpg 1536w, https://cdn.confident-group.com/wp-content/uploads/2023/02/23173012/shutterstock_1436515070-2048x1152.jpg 2048w"
                        sizes="(max-width: 1024px) 100vw, 1024px" /></p>
                <p dir="ltr">Marriage marks a new beginning in the lives of people as their needs, outlooks, perspectives, and priorities in life can change completely. There are several reasons why youngsters may choose to move out of their family homes.</p>
                <h3>#1: Beginning of a New Journey</h3>
                <p dir="ltr">You have just got married. You are planning to craft a beautiful life along with your partner by seeking to understand him/her better. The first year of your marriage kind of sets the tone for all your life ahead. Spending quality time
                    together and privacy are the most critical ingredients to building a strong foundation for your marriage. It may not be possible at your parents&#8217; home if the house is not large enough and there are siblings around. It is tough
                    to chart out a life of your own in a shared space. With a separate place, you don&#8217;t have to grapple with heavy household chores and the adjustment issues of a large family.</p>
                <h3>#2: Desire for an Independent Life</h3>
                <p dir="ltr">No matter how cliched it sounds, the &#8216;generation gap&#8217; is a reality. Despite the right intentions, it may be difficult for the parents &amp; children to agree with each other on every matter. The changing value systems and lifestyle
                    patterns of the two generations give rise to severe differences &amp; misunderstandings between them. The husband is often forced to take sides in the event of a conflict between his mother and wife. Beginning the marriage on a negative
                    note can cause serious harm to this beautiful bond. The young couple may have other priorities like their careers, traveling, forging new relationships, etc., that do not always resonate with the parents&#8217; expectations.</p>
                <p>A separate home gives the young couple the freedom they crave for. Many parents also respect, support, and appreciate their adult children&#8217;s decision to &#8216;grow&#8217; independently and achieve bigger things in life.</p>
                <h3>#3: Convenience &amp; Comfort</h3>
                <p dir="ltr">Another important factor why young couples choose to move out is to stay closer to their workplaces. With the business districts, office campuses, and IT parks located in far-off suburbs, commuting from the parent&#8217;s homes is a herculean
                    task. Battling the daily traffic, pollution, and unexpected rains can sap all your time &amp; energy. The newlyweds do not get to spend time together and often come home tired and weary. The only solution to this grave problem is to
                    buy a home that is in close proximity to their workplaces.</p>
                <p>Moreover, the old parental home may not be in sync with the young couple&#8217;s desires to have a compact place that is easy to maintain and equipped with all the modern amenities for work &amp; leisure activities.</p>
                <h3>#4: Property is a Sound Financial Investment</h3>
                <p dir="ltr">It is something that parents would agree with their children. In fact, they often goad the children to invest in property as early as possible. The average age of homebuyers is rapidly declining in India. Most couples would like to buy
                    a home in their 20s and early 30s when they both have stable, high-paying jobs and few responsibilities. With the arrival of children, the priorities change toward their care &amp; upbringing.</p>
                <p>So, it&#8217;s a prudent &amp; sensible decision for young couples to invest in property as early as possible. Further, the old home of your parents may not be suitable for your current lifestyle needs. You can choose a compact and cozy
                    apartment that is tailor-made for young couples and small families with kids.</p>
                <h3>Buying a Property in Kottayam City</h3>
                <p><img decoding="async" loading="lazy" class="alignnone size-large wp-image-25441" src="https://cdn.confident-group.com/wp-content/uploads/2023/02/23173633/shutterstock_1892139802-1024x683.jpg" alt="kottayam" width="1024" height="683" srcset="https://cdn.confident-group.com/wp-content/uploads/2023/02/23173633/shutterstock_1892139802-1024x683.jpg 1024w, https://cdn.confident-group.com/wp-content/uploads/2023/02/23173633/shutterstock_1892139802-300x200.jpg 300w, https://cdn.confident-group.com/wp-content/uploads/2023/02/23173633/shutterstock_1892139802-768x512.jpg 768w, https://cdn.confident-group.com/wp-content/uploads/2023/02/23173633/shutterstock_1892139802-1536x1024.jpg 1536w, https://cdn.confident-group.com/wp-content/uploads/2023/02/23173633/shutterstock_1892139802-2048x1365.jpg 2048w"
                        sizes="(max-width: 1024px) 100vw, 1024px" /></p>
                <p dir="ltr">The scenic greenery, beautiful backwaters, and excellent quality of life that Kottayam offers make it a go-to investment destination. The city offers top-quality education and healthcare facilities. The civic infrastructure is excellent,
                    with good roads, connectivity, and commercial and entertainment zones. The city is clean and enjoys a salubrious climate throughout the year. Proximity to Kochi, the business capital of Kerala, is a huge plus.<br /> All these factors
                    have paved the way for Kottayam to emerge as a hot choice among locals and NRIs looking to invest in property.</p>
                <p>Builders in Kottayam are launching apartment &amp; villa projects to cater to different budget segments. Whether you want a simple 1-BHK flat or a luxurious villa, the city offers myriad choices to suit your needs, tastes, and budget.
                    A young couple can choose a ready-to-occupy two or 3-BHK apartment in a project with all facilities like a clubhouse, sports arena, manicured gardens, and a swimming pool. Your parents can visit you over the weekends or during festivals
                    to enjoy all these privileges.</p>
                <h3>Conclusion</h3>
                <p dir="ltr">Just like their western counterparts, young people in India are beginning to leave their parents&#8217; homes to chart a life of their own. It&#8217;s not just the metro cities but also the tier 2 and tier 3 cities like Kottayam witnessing
                    this new wave. But the decision should not be made impulsively. The separation should be on a positive note and preceded by a proper plan of action by the young couple.</p>
            </div>

            <div class="author_block">
                <div class="author_lft">
                    <div class="logo_side">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22130218/Blog.png" alt="">
                    </div>
                </div>
                <div class="author_ryt">
                    <span class="author">Author - Webdura</span>
                    <p></p>
                    <ul class="social_icon px-0 d-flex justify-content-md-start justify-content-center mb-0">

                        <li><a ><i class="fab fa-facebook-f"></i></a></li>
                        <li><a ><i class="fab fa-twitter"></i></a></li>
                        <li><a ><i class="fab fa-instagram"></i></a></li>

                    </ul>
                </div>
            </div>

            <div class="author_block comment_box d-block">
                <h5>Comments</h5>
            </div>
            <div class="post_bolk">
                <div class="post_comment">
                    <h6>POST YOUR COMMENT</h6>
                    <div class="post_form">
                        <div id="respond" class="comment-respond">
                            <h3 id="reply-title" class="comment-reply-title"> <small><a rel="nofollow" id="cancel-comment-reply-link"  style="display:none;">Cancel reply</a></small></h3>
                            <form action="https://www.confident-group.com/wp-comments-post.php"
                                method="post" id="commentform" class="comment-form">
                                <div class="post_input">
                                    <div class="post_one">
                                        <p class="comment-form-author"><label for="author">Name</label> <span class="required"></span><input id="author" name="author" type="text" value="" size="30" required /></p>
                                    </div>
                                    <div class="post_one">
                                        <p class="comment-form-email"><label for="email">Email</label> <span class="required"></span><input id="email" name="email" type="text" value="" size="30" required /></p>
                                    </div>
                                </div>
                                <p class="comment-form-comment"><label for="comment">Comment <span class="required">*</span></label> <textarea id="comment" name="comment" cols="45" rows="8" maxlength="65525" required="required"></textarea></p>
                                <p class="form-submit"><input name="submit" type="submit" id="submit" class="submit" value="SUBMIT NOW" /> <input type='hidden' name='comment_post_ID' value='25435' id='comment_post_ID' />
                                    <input type='hidden' name='comment_parent' id='comment_parent' value='0' />
                                </p>
                                <p style="display: none;"><input type="hidden" id="akismet_comment_nonce" name="akismet_comment_nonce" value="8fd32757bc" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="ak_js" value="0" />
                                    <script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div class="has-wrap-faq pb-0">
                <div class="text-center text-sub text-capitalize">Related Articles</div>
                <div class="has-highlight">
                    <div class="has-box-highlight m-0">
                        <div class="highlight-wrap p-0">
                            <div>
                                <div class="highlight-box d-block pt-0">
                                    <div class="h-img">
                                        <img src="https://cdn.confident-group.com/wp-content/uploads/2023/02/20101445/tumb-1-2.jpg" alt="" class="w-100">
                                    </div>
                                    <div class="h-text px-0">
                                        <h3 class="w-100">Young Married Women Choose Gated Community Living Rather than Individual Homes</h3>
                                        <p>So finally, you have decided to take the plunge and buy a home of your own. You are both excited and nervous at the same time. Buying a home often evokes mixed feelings in people. While it g...</p>
                                    </div>
                                </div>
                                <div class="read-more">
                                    <span> <a >Read More</a></span>
                                    <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.06 20.91">
<path d="M0 11.1h25.86L16.97 20c0 .04.91.92.91.91l10-10c0-.01.01-.01.02-.02l.04-.04c.01-.01.02-.02.02-.03.01-.01.02-.03.03-.05.01-.01.01-.03.02-.04 0-.01.01-.02.01-.03v-.02c.01-.01.01-.03.01-.04 0-.02.01-.03.01-.05 0-.01 0-.03.01-.04 0-.02 0-.03.01-.05v-.05-.05c0-.02 0-.03-.01-.05 0-.01 0-.03-.01-.04 0-.02-.01-.03-.01-.05 0-.01-.01-.03-.01-.04 0-.01 0-.01-.01-.02 0-.01-.01-.02-.02-.03-.01-.01-.01-.03-.02-.04-.01-.02-.02-.03-.03-.05-.01-.01-.02-.02-.02-.03-.01-.01-.02-.03-.04-.04.01 0 0-.01 0-.01l-10-10c0-.01-.91.91-.91.91l8.89 8.9H0v1.29z"></path>
</svg></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="has-box-highlight m-0">
                        <div class="highlight-wrap p-0">
                            <div>
                                <div class="highlight-box d-block pt-0">
                                    <div class="h-img">
                                        <img src="https://cdn.confident-group.com/wp-content/uploads/2023/02/09145816/tumb-2.jpg" alt="" class="w-100">
                                    </div>
                                    <div class="h-text px-0">
                                        <h3 class="w-100">Apartment Living is Better for Kids</h3>
                                        <p>With the properties and individual home prices skyrocketing, it has become incredibly tough to buy an individual house. Plus, individual dwellings come with a lot of additional costs like ma...</p>
                                    </div>
                                </div>
                                <div class="read-more">
                                    <span> <a >Read More</a></span>
                                    <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.06 20.91">
<path d="M0 11.1h25.86L16.97 20c0 .04.91.92.91.91l10-10c0-.01.01-.01.02-.02l.04-.04c.01-.01.02-.02.02-.03.01-.01.02-.03.03-.05.01-.01.01-.03.02-.04 0-.01.01-.02.01-.03v-.02c.01-.01.01-.03.01-.04 0-.02.01-.03.01-.05 0-.01 0-.03.01-.04 0-.02 0-.03.01-.05v-.05-.05c0-.02 0-.03-.01-.05 0-.01 0-.03-.01-.04 0-.02-.01-.03-.01-.05 0-.01-.01-.03-.01-.04 0-.01 0-.01-.01-.02 0-.01-.01-.02-.02-.03-.01-.01-.01-.03-.02-.04-.01-.02-.02-.03-.03-.05-.01-.01-.02-.02-.02-.03-.01-.01-.02-.03-.04-.04.01 0 0-.01 0-.01l-10-10c0-.01-.91.91-.91.91l8.89 8.9H0v1.29z"></path>
</svg></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <footer>
        <div class="footer--wrap">
            <div class="footer-card">
                <div class="container">
                    <div class="has-sub-block d-block d-md-block d-lg-none">
                        <span class="num-block">16</span><span>+</span>
                        <p>of Creating Signature Addresses
                        </p>
                    </div>
                    <div class="has-sub-block">
                        <span class="num-block">11923</span><span>+</span>
                        <p>Happy &amp; Confident Customers
                        </p>
                    </div>
                    <div class="has-sub-block">
                        <span class="num-block">5315</span><span>+</span>
                        <p>Homes handed over in kerala & bangalore
                        </p>
                    </div>
                    <div class="has-sub-block">
                        <span class="num-block">17</span><span>+</span>
                        <p>Upcoming Projects in kerala
                        </p>
                    </div>
                    <div class="has-sub-block">
                        <span class="num-blockk">Highest</span>
                        <p>Number of rera registered projects in kerala
                        </p>
                    </div>
                    <div class="has-sub-block">
                        <span class="num-block">3.3</span> <span>Million</span>
                        <p>SQ.FT under construction in kerala & Bangalore
                        </p>
                    </div>
                </div>
            </div>
            <div class="footer-nav">
                <div class="container">
                    <div class="footer-has-wrap">
                        <div class="footer-nav-wrap d-none d-sm-none d-md-block">
                            <div class="footer-nav-link">
                                <span>About</span>
                                <ul class="px-0 m-0">
                                    <li><a >About Us </a></li>
                                    <li><a >Chairman's Message </a></li>
                                    <li><a >Group of Companies </a></li>
                                    <li><a >Senior Management Team </a></li>
                                    <li><a >Events </a></li>
                                    <li><a >News </a></li>
                                    <li><a >Key Handing Over </a></li>
                                    <li><a >Careers </a></li>
                                    <li><a >Testimonials </a></li>
                                    <li><a >Helping Hand </a></li>
                                    <li><a >City Highlights</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="footer-nav-wrap d-none d-sm-none d-md-block">
                            <div class="footer-nav-link">
                                <span>hOME VERTICALS</span>
                                <ul class="px-0 m-0">
                                    <li><a >Lifestyle Plus Homes </a></li>
                                    <li><a >Confi Luxe Homes </a></li>
                                    <li><a >Smile Homes </a></li>
                                </ul>
                            </div>
                            <div class="footer-nav-link">
                                <span>Locations</span>
                                <ul class="px-0 m-0">
                                    <li><a >Kochi</a></li>
                                    <li><a >Kakkanad</a></li>
                                    <li><a >Trivandrum</a></li>
                                    <li><a >Technopark</a></li>
                                    <li><a >Thrissur</a></li>
                                    <li><a >Kottayam</a></li>
                                    <li><a >Calicut</a></li>
                                    <li><a >Bangalore</a></li>
                                    <li><a >Dubai</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="footer-nav-wrap d-none d-sm-none d-md-block">
                            <div class="footer-nav-link">
                                <span>Support</span>
                                <ul class="px-0 m-0">
                                    <li><a >Customer Care</a></li>
                                    <li><a >EMI Calculator</a></li>
                                    <li><a >Loan Eligibility</a></li>
                                    <li><a >Faq</a></li>
                                    <li><a >NRI Faq</a></li>
                                    <li><a >Contact us</a></li>
                                    <li><a >Blogs</a></li>
                                    <li><a >Home Loan Guide</a></li>
                                </ul>
                            </div>
                            <div class="footer-nav-link">
                                <span>After Sales</span>
                                <ul class="px-0 m-0">
                                    <li><a >Homecare service</a></li>
                                    <li><a >Resale & Rental</a></li>
                                    <li><a >Interior Designs</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="footer-nav-wrap text-lg-start text-sm-center text-md-start text-center">
                            <div class="footer-nav-link">
                                <span>Contact</span>
                                <ul class="m-0">
                                    <li><a >Kerala
<small>+91-9895904444</small>
</a>
                                    </li>
                                    <li><a >Bangalore
<small>+91-7406546000</small>
</a>
                                    </li>
                                    <li><a >Dubai
<small>+971-553387577</small>
</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-nav-link">
                                <span>Social</span>
                                <ul class="social px-0 pt-3 pb-0 m-0">
                                    <li><a target="_blank"  rel="noopener"><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/facebook-min.svg" alt="facebook"> </a></li>
                                    <li><a target="_blank"  rel="noopener"><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/instagram-min.svg" alt="instagram"> </a></li>
                                    <li><a target="_blank"  rel="noopener"><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/youtube-min.svg" alt="youtube"> </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="btm-footer">
                <div class="container">
                    <div class="btm-footer-wrap">
                        <div>
                            <p>© 2022 Confident Group. All Rights Reserved </p>
                            <ul class="px-0 m-0">
                                <li><a >Disclaimer</a></li>
                                <li><a >Terms of Use</a></li>
                                <li><a >Privacy Policy</a></li>
                            </ul>
                        </div>
                        <div class="has-design">
                            <span class="design">
Designed &<br>
Marketed by
</span>
                            <span class="pr-0">
<a  rel="noopener"><img src="https://www.confident-group.com/wp-content/themes/confident/assets/images/webdura.png" alt="Webdura Technologies"></a>
</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </footer>


    <div class="modal fade enquire-modal cmnbudget-homes" id="enquire-Calicut" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Please submit your details & our representative will contact you shortly</div>
                        <div role="form" class="wpcf7" id="wpcf7-f10004-o1" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f10004-o1" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="10004" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f10004-o1" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Phone<sub>*</sub></label><span class="wpcf7-form-control-wrap phone"><input type="tel" name="phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="phone" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Whats App<sub>*</sub></label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Project</label><span class="wpcf7-form-control-wrap choose_project"><select name="choose_project" class="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-select choose_project" id="choose_project" aria-required="true" aria-invalid="false"><option value="">Select Project</option><option value="Confident Exotica">Confident Exotica</option><option value="Confident Oakwood">Confident Oakwood</option><option value="Confident Greenwich">Confident Greenwich</option></select></span>
                                </div>
                                <input type="hidden" name="subj" value="Website Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Kozhikode" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_medium" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_source" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_campaign" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_term" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_content" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Enquire Now" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="74" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade enquire-modal cmnbudget-homes" id="enquire-Kochi" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Please submit your details & our representative will contact you shortly</div>
                        <div role="form" class="wpcf7" id="wpcf7-f10003-o2" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f10003-o2" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="10003" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f10003-o2" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Phone<sub>*</sub></label><span class="wpcf7-form-control-wrap phone"><input type="tel" name="phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="phone" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Whats App<sub>*</sub></label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Project</label><span class="wpcf7-form-control-wrap choose_project"><select name="choose_project" class="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-select choose_project" id="choose_project" aria-required="true" aria-invalid="false"><option value="">Select Project</option><option value="Confident White Berry">Confident White Berry</option><option value="Confident Iris">Confident Iris</option><option value="Confident Pinnacle">Confident Pinnacle</option><option value="Confident Joinville">Confident Joinville</option><option value="Confident White House">Confident White House</option><option value="Confident Gem">Confident Gem</option><option value="Confident Bellatrix III">Confident Bellatrix III</option><option value="Confident Symphony">Confident Symphony</option><option value="Confident Pride">Confident Pride</option><option value="Confident Oberon">Confident Oberon</option><option value="Confident Amber">Confident Amber</option><option value="Confident Pinewood">Confident Pinewood</option><option value="Confident Bougainville">Confident Bougainville</option></select></span>
                                </div>
                                <input type="hidden" name="subj" value="Website Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Kochi" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_medium" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_source" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_campaign" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_term" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_content" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Enquire Now" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="220" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade enquire-modal cmnbudget-homes" id="enquire-Kottayam" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Please submit your details & our representative will contact you shortly</div>
                        <div role="form" class="wpcf7" id="wpcf7-f7330-o3" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f7330-o3" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="7330" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f7330-o3" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Phone<sub>*</sub></label><span class="wpcf7-form-control-wrap phone"><input type="tel" name="phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="phone" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Whats App<sub>*</sub></label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Project</label><span class="wpcf7-form-control-wrap choose_project"><select name="choose_project" class="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-select choose_project" id="choose_project" aria-required="true" aria-invalid="false"><option value="">Select Project</option><option value="Confident Blueberry">Confident Blueberry</option><option value="Confident Cygnus">Confident Cygnus</option><option value="Confident Cygnus Block B">Confident Cygnus Block B</option></select></span>
                                </div>
                                <input type="hidden" name="subj" value="Website Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Kottayam" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_medium" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_source" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_campaign" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_term" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_content" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Enquire Now" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="202" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade enquire-modal cmnbudget-homes" id="enquire-Thrissur" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Please submit your details & our representative will contact you shortly</div>
                        <div role="form" class="wpcf7" id="wpcf7-f10005-o4" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f10005-o4" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="10005" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f10005-o4" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Phone<sub>*</sub></label><span class="wpcf7-form-control-wrap phone"><input type="tel" name="phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="phone" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Whats App<sub>*</sub></label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Project</label><span class="wpcf7-form-control-wrap choose_project"><select name="choose_project" class="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-select choose_project" id="choose_project" aria-required="true" aria-invalid="false"><option value="">Select Project</option><option value="Confident Lotus">Confident Lotus</option><option value="Confident Maple">Confident Maple</option><option value="Confident Ebony">Confident Ebony</option></select></span>
                                </div>
                                <input type="hidden" name="subj" value="Website Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Thrissur" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_medium" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_source" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_campaign" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_term" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_content" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Enquire Now" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="70" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade enquire-modal cmnbudget-homes" id="enquire-Trivandrum" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Please submit your details & our representative will contact you shortly</div>
                        <div role="form" class="wpcf7" id="wpcf7-f10006-o5" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f10006-o5" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="10006" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f10006-o5" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Phone<sub>*</sub></label><span class="wpcf7-form-control-wrap phone"><input type="tel" name="phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="phone" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Whats App<sub>*</sub></label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Project</label><span class="wpcf7-form-control-wrap choose_project"><select name="choose_project" class="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-select choose_project" id="choose_project" aria-required="true" aria-invalid="false"><option value="">Select Project</option><option value="Confident Aquila">Confident Aquila</option><option value="Confident Prime III">Confident Prime III</option><option value="Confident Greenfield">Confident Greenfield</option><option value="Confident Zenith">Confident Zenith</option><option value="Confident Crown">Confident Crown</option><option value="Confident Horizon">Confident Horizon</option><option value="Confident Gold Coast">Confident Gold Coast</option><option value="Confident Bilberry">Confident Bilberry</option><option value="Confident Oasis">Confident Oasis</option><option value="Confident Pioneer">Confident Pioneer</option></select></span>
                                </div>
                                <input type="hidden" name="subj" value="Website Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Trivandrum" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_medium" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_source" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_campaign" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_term" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_content" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Enquire Now" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="168" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade enquire-modal cmnbudget-homes" id="enquire-Bangalore" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Please submit your details & our representative will contact you shortly</div>
                        <div role="form" class="wpcf7" id="wpcf7-f14005-o6" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f14005-o6" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="14005" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f14005-o6" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Phone<sub>*</sub></label><span class="wpcf7-form-control-wrap phone"><input type="tel" name="phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="phone" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Whats App<sub>*</sub></label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Project</label><span class="wpcf7-form-control-wrap choose_project"><select name="choose_project" class="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-select choose_project" id="choose_project" aria-required="true" aria-invalid="false"><option value="">Select Project</option><option value="Confident Mayfair">Confident Mayfair</option><option value="Confident Meadows">Confident Meadows</option><option value="Confident Aquila">Confident Aquila</option><option value="Confident Pride">Confident Pride</option><option value="Confident Propus">Confident Propus</option><option value="Confident Central">Confident Central</option></select></span>
                                </div>
                                <input type="hidden" name="subj" value="Bangalore Website Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Bangalore" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_medium" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_source" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_campaign" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_term" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_content" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Enquire Now" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="232" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="modal fade enquire-modal" id="enquire-Kochi-brochure" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content cmn-fz">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Download Brochure
                            <p class="pt-3 text-capitalize">Access Your Brochure<span class="txt-b">
From E-mail & WhatsApp</span></p>
                        </div>
                        <div role="form" class="wpcf7" id="wpcf7-f7331-o7" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f7331-o7" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="7331" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f7331-o7" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Whats App</label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel wcphone form-control" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <input type="hidden" name="brochure_id" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="projectname" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="subj" value="Brochure Download Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Kochi" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="brochure_link" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Submit" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="5" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade enquire-modal" id="enquire-Calicut-brochure" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content cmn-fz">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Download Brochure
                            <p class="pt-3 text-capitalize">Access Your Brochure<span class="txt-b">
From E-mail & WhatsApp</span></p>
                        </div>
                        <div role="form" class="wpcf7" id="wpcf7-f15363-o8" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f15363-o8" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="15363" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f15363-o8" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Whats App</label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel wcphone form-control" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <input type="hidden" name="brochure_id" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="projectname" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="subj" value="Brochure Download Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Kozhikode" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="brochure_link" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Submit" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="34" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade enquire-modal" id="enquire-Kottayam-brochure" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content cmn-fz">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Download Brochure
                            <p class="pt-3 text-capitalize">Access Your Brochure<span class="txt-b">
From E-mail & WhatsApp</span></p>
                        </div>
                        <div role="form" class="wpcf7" id="wpcf7-f15364-o9" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f15364-o9" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="15364" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f15364-o9" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Whats App</label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel wcphone form-control" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <input type="hidden" name="brochure_id" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="projectname" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="subj" value="Brochure Download Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Kottayam" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="brochure_link" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Submit" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="63" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade enquire-modal" id="enquire-Thrissur-brochure" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content cmn-fz">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Download Brochure
                            <p class="pt-3 text-capitalize">Access Your Brochure<span class="txt-b">
From E-mail & WhatsApp</span></p>
                        </div>
                        <div role="form" class="wpcf7" id="wpcf7-f15365-o10" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f15365-o10" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="15365" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f15365-o10" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Whats App</label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel wcphone form-control" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <input type="hidden" name="brochure_id" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="projectname" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="subj" value="Brochure Download Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Thrissur" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="brochure_link" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Submit" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="187" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade enquire-modal" id="enquire-Trivandrum-brochure" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content cmn-fz">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Download Brochure
                            <p class="pt-3 text-capitalize">Access Your Brochure<span class="txt-b">
From E-mail & WhatsApp</span></p>
                        </div>
                        <div role="form" class="wpcf7" id="wpcf7-f15366-o11" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f15366-o11" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="15366" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f15366-o11" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Whats App</label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel wcphone form-control" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <input type="hidden" name="brochure_id" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="projectname" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="subj" value="Brochure Download Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Trivandrum" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="brochure_link" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Submit" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="86" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade enquire-modal" id="enquire-ban-brochure" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content cmn-fz">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Download Brochure
                        </div>
                        <div role="form" class="wpcf7" id="wpcf7-f14901-o12" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f14901-o12" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="14901" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f14901-o12" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">First Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Last Name<sub>*</sub></label><span class="wpcf7-form-control-wrap lastname"><input type="text" name="lastname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="lastname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Phone<sub>*</sub></label><span class="wpcf7-form-control-wrap phone"><input type="tel" name="phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cphone form-control" id="phone" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Email<sub>*</sub></label><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="email" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <input type="hidden" name="brochure_id" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="projectname" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="subj" value="Bangalore Brochure Download Lead" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="location" value="Bangalore" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="brochure_link" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_source" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_campaign" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_medium" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_term" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <input type="hidden" name="utm_content" value="" class="wpcf7-form-control wpcf7-hidden" />
                                <p><input type="submit" value="Submit" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="submit" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="146" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade enquire-modal" id="whatsapp-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body border-5 rounded border-top">
                    <div class="d-flex btn-wrap" data-bs-dismiss="modal" aria-label="Close">
                        <p class="close-btn text-white me-2">Close</p>
                        <button type="button" class="btn-close">
</button>
                    </div>
                    <div class="form-grp border-0 p-0 w-100">
                        <img src="https://cdn.confident-group.com/wp-content/uploads/2022/04/22132451/cg-logo-s_new.png" class="w-auto m-auto d-block" alt="Confident Group">
                        <div class="text-center m-0 text-sub text-uppercase text-black">Get Whatsapp Update
                        </div>
                        <div role="form" class="wpcf7" id="wpcf7-f14113-o13" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/blog/apartment-for-young-couples/#wpcf7-f14113-o13" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="14113" />
                                    <input type="hidden" name="_wpcf7_version" value="5.5.2" />
                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f14113-o13" />
                                    <input type="hidden" name="_wpcf7_container_post" value="0" />
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Enter Your Name<sub>*</sub></label><span class="wpcf7-form-control-wrap firstname"><input type="text" name="firstname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required w-100" id="firstname" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Enter Your Mobile Number<sub>*</sub></label><span class="wpcf7-form-control-wrap whatsapp"><input type="tel" name="whatsapp" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel w-100 cphone" id="whatsapp" aria-required="true" aria-invalid="false" /></span>
                                </div>
                                <input type="hidden" name="project_id" value="" class="wpcf7-form-control wpcf7-hidden" id="project_id" />
                                <p><input type="submit" value="Send" class="wpcf7-form-control has-spinner wpcf7-submit btn w-100" id="send" /></p>
                                <p style="display: none !important;"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js" name="_wpcf7_ak_js" value="247" />
                                    <script type="rocketlazyloadscript">document.getElementById( "ak_js" ).setAttribute( "value", ( new Date() ).getTime() );</script>
                                </p>
                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


















    <script type="rocketlazyloadscript">
        document.addEventListener( 'wpcf7mailsent', function( event ) { if ( '7360' == event.detail.contactFormId || '7929' == event.detail.contactFormId || '7933' == event.detail.contactFormId || '14005' == event.detail.contactFormId || '14901' == event.detail.contactFormId
        ) { location = 'https://www.confident-group.com/thank-you-bangalore/'; }else if('7331' == event.detail.contactFormId || '15363' == event.detail.contactFormId || '15364' == event.detail.contactFormId || '15365' == event.detail.contactFormId ||
        '15366' == event.detail.contactFormId){ location = 'https://www.confident-group.com/thank-you-brochure-download/'; }else{ location = 'https://www.confident-group.com/thank-you/'; } }, false ); /** * Disable WPCF7 button while it's submitting *
        Stops duplicate enquiries coming through */ document.addEventListener( 'wpcf7submit', function( event ) { // find only disbaled submit buttons var button = $('.wpcf7-submit[disabled]'); // grab the old value var old_value = button.attr('data-value');
        // enable the button button.prop('disabled', false); // put the old value back in button.val(old_value); }, false ); $('form.wpcf7-form').on('submit',function() { var form = $(this); var button = form.find('input[type=submit]'); var current_val
        = button.val(); // store the current value so we can reset it later button.attr('data-value', current_val); // disable the button button.prop("disabled", true); // tell the user what's happening button.val("Please wait..."); });


    </script>
    <script type="rocketlazyloadscript" data-rocket-type='text/javascript' src='https://www.confident-group.com/wp-includes/js/dist/vendor/regenerator-runtime.min.js?ver=0.13.9' id='regenerator-runtime-js'></script>
    <script type="rocketlazyloadscript" data-rocket-type='text/javascript' src='https://www.confident-group.com/wp-includes/js/dist/vendor/wp-polyfill.min.js?ver=3.15.0' id='wp-polyfill-js'></script>
    <script type="9813d3f96e8009f7558b4704-text/javascript" id='contact-form-7-js-extra'>
        /* <![CDATA[ */
        var wpcf7 = {
            "api": {
                "root": "https:\/\/www.confident-group.com\/wp-json\/",
                "namespace": "contact-form-7\/v1"
            },
            "cached": "1"
        };
        /* ]]> */
    </script>
    <script type="rocketlazyloadscript" data-minify="1" data-rocket-type='text/javascript' src='https://www.confident-group.com/wp-content/cache/min/1/wp-content/plugins/contact-form-7/includes/js/index.js?ver=1677489323' id='contact-form-7-js'></script>
    <script type="rocketlazyloadscript" data-rocket-type='text/javascript' id='rocket-browser-checker-js-after'>
        "use strict";
        var _createClass = function() {
            function defineProperties(target, props) {
                for (var i = 0; i < props.length; i++) {
                    var descriptor = props[i];
                    descriptor.enumerable = descriptor.enumerable || !1, descriptor.configurable = !0, "value" in descriptor && (descriptor.writable = !0), Object.defineProperty(target, descriptor.key, descriptor)
                }
            }
            return function(Constructor, protoProps, staticProps) {
                return protoProps && defineProperties(Constructor.prototype, protoProps), staticProps && defineProperties(Constructor, staticProps), Constructor
            }
        }();

        function _classCallCheck(instance, Constructor) {
            if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function")
        }
        var RocketBrowserCompatibilityChecker = function() {
            function RocketBrowserCompatibilityChecker(options) {
                _classCallCheck(this, RocketBrowserCompatibilityChecker), this.passiveSupported = !1, this._checkPassiveOption(this), this.options = !!this.passiveSupported && options
            }
            return _createClass(RocketBrowserCompatibilityChecker, [{
                key: "_checkPassiveOption",
                value: function(self) {
                    try {
                        var options = {
                            get passive() {
                                return !(self.passiveSupported = !0)
                            }
                        };
                        window.addEventListener("test", null, options), window.removeEventListener("test", null, options)
                    } catch (err) {
                        self.passiveSupported = !1
                    }
                }
            }, {
                key: "initRequestIdleCallback",
                value: function() {
                    !1 in window && (window.requestIdleCallback = function(cb) {
                        var start = Date.now();
                        return setTimeout(function() {
                            cb({
                                didTimeout: !1,
                                timeRemaining: function() {
                                    return Math.max(0, 50 - (Date.now() - start))
                                }
                            })
                        }, 1)
                    }), !1 in window && (window.cancelIdleCallback = function(id) {
                        return clearTimeout(id)
                    })
                }
            }, {
                key: "isDataSaverModeOn",
                value: function() {
                    return "connection" in navigator && !0 === navigator.connection.saveData
                }
            }, {
                key: "supportsLinkPrefetch",
                value: function() {
                    var elem = document.createElement("link");
                    return elem.relList && elem.relList.supports && elem.relList.supports("prefetch") && window.IntersectionObserver && "isIntersecting" in IntersectionObserverEntry.prototype
                }
            }, {
                key: "isSlowConnection",
                value: function() {
                    return "connection" in navigator && "effectiveType" in navigator.connection && ("2g" === navigator.connection.effectiveType || "slow-2g" === navigator.connection.effectiveType)
                }
            }]), RocketBrowserCompatibilityChecker
        }();
    </script>
    <script type="9813d3f96e8009f7558b4704-text/javascript" id='rocket-preload-links-js-extra'>
        /* <![CDATA[ */
        var RocketPreloadLinksConfig = {
            "excludeUris": "\/(.+\/)?feed\/?.+\/?|\/(?:.+\/)?embed\/|\/(index\\.php\/)?wp\\-json(\/.*|$)|\/cockpit\/|\/wp-admin\/|\/logout\/|\/cockpit\/",
            "usesTrailingSlash": "1",
            "imageExt": "jpg|jpeg|gif|png|tiff|bmp|webp|avif",
            "fileExt": "jpg|jpeg|gif|png|tiff|bmp|webp|avif|php|pdf|html|htm",
            "siteUrl": "https:\/\/www.confident-group.com",
            "onHoverDelay": "100",
            "rateThrottle": "3"
        };
        /* ]]> */
    </script>
    <script type="rocketlazyloadscript" data-rocket-type='text/javascript' id='rocket-preload-links-js-after'>
        (function() {
            "use strict";
            var r = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(e) {
                    return typeof e
                } : function(e) {
                    return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e
                },
                e = function() {
                    function i(e, t) {
                        for (var n = 0; n < t.length; n++) {
                            var i = t[n];
                            i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(e, i.key, i)
                        }
                    }
                    return function(e, t, n) {
                        return t && i(e.prototype, t), n && i(e, n), e
                    }
                }();

            function i(e, t) {
                if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function")
            }
            var t = function() {
                function n(e, t) {
                    i(this, n), this.browser = e, this.config = t, this.options = this.browser.options, this.prefetched = new Set, this.eventTime = null, this.threshold = 1111, this.numOnHover = 0
                }
                return e(n, [{
                    key: "init",
                    value: function() {
                        !this.browser.supportsLinkPrefetch() || this.browser.isDataSaverModeOn() || this.browser.isSlowConnection() || (this.regex = {
                            excludeUris: RegExp(this.config.excludeUris, "i"),
                            images: RegExp(".(" + this.config.imageExt + ")$", "i"),
                            fileExt: RegExp(".(" + this.config.fileExt + ")$", "i")
                        }, this._initListeners(this))
                    }
                }, {
                    key: "_initListeners",
                    value: function(e) {
                        -1 < this.config.onHoverDelay && document.addEventListener("mouseover", e.listener.bind(e), e.listenerOptions), document.addEventListener("mousedown", e.listener.bind(e), e.listenerOptions), document.addEventListener("touchstart", e.listener.bind(e), e.listenerOptions)
                    }
                }, {
                    key: "listener",
                    value: function(e) {
                        var t = e.target.closest("a"),
                            n = this._prepareUrl(t);
                        if (null !== n) switch (e.type) {
                            case "mousedown":
                            case "touchstart":
                                this._addPrefetchLink(n);
                                break;
                            case "mouseover":
                                this._earlyPrefetch(t, n, "mouseout")
                        }
                    }
                }, {
                    key: "_earlyPrefetch",
                    value: function(t, e, n) {
                        var i = this,
                            r = setTimeout(function() {
                                if (r = null, 0 === i.numOnHover) setTimeout(function() {
                                    return i.numOnHover = 0
                                }, 1e3);
                                else if (i.numOnHover > i.config.rateThrottle) return;
                                i.numOnHover++, i._addPrefetchLink(e)
                            }, this.config.onHoverDelay);
                        t.addEventListener(n, function e() {
                            t.removeEventListener(n, e, {
                                passive: !0
                            }), null !== r && (clearTimeout(r), r = null)
                        }, {
                            passive: !0
                        })
                    }
                }, {
                    key: "_addPrefetchLink",
                    value: function(i) {
                        return this.prefetched.add(i.href), new Promise(function(e, t) {
                            var n = document.createElement("link");
                            n.rel = "prefetch", n.href = i.href, n.onload = e, n.onerror = t, document.head.appendChild(n)
                        }).catch(function() {})
                    }
                }, {
                    key: "_prepareUrl",
                    value: function(e) {
                        if (null === e || "object" !== (void 0 === e ? "undefined" : r(e)) || !1 in e || -1 === ["http:", "https:"].indexOf(e.protocol)) return null;
                        var t = e.href.substring(0, this.config.siteUrl.length),
                            n = this._getPathname(e.href, t),
                            i = {
                                original: e.href,
                                protocol: e.protocol,
                                origin: t,
                                pathname: n,
                                href: t + n
                            };
                        return this._isLinkOk(i) ? i : null
                    }
                }, {
                    key: "_getPathname",
                    value: function(e, t) {
                        var n = t ? e.substring(this.config.siteUrl.length) : e;
                        return n.startsWith("/") || (n = "/" + n), this._shouldAddTrailingSlash(n) ? n + "/" : n
                    }
                }, {
                    key: "_shouldAddTrailingSlash",
                    value: function(e) {
                        return this.config.usesTrailingSlash && !e.endsWith("/") && !this.regex.fileExt.test(e)
                    }
                }, {
                    key: "_isLinkOk",
                    value: function(e) {
                        return null !== e && "object" === (void 0 === e ? "undefined" : r(e)) && (!this.prefetched.has(e.href) && e.origin === this.config.siteUrl && -1 === e.href.indexOf("?") && -1 === e.href.indexOf("#") && !this.regex.excludeUris.test(e.href) && !this.regex.images.test(e.href))
                    }
                }], [{
                    key: "run",
                    value: function() {
                        "undefined" != typeof RocketPreloadLinksConfig && new n(new RocketBrowserCompatibilityChecker({
                            capture: !0,
                            passive: !0
                        }), RocketPreloadLinksConfig).init()
                    }
                }]), n
            }();
            t.run();
        }());
    </script>
</body>`;

function Sample1() {
  const [isAllowed, setAllowed] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL + "/adduser";
  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      setAllowed(true);
      sendToken();
    } else if (permission === "denied") {
      setAllowed(false);
    }
  };

  useEffect(() => {
    // Req user for notification permission
    requestPermission();
  }, []);

  const sendToken = async (isAdmin) => {
    const myToken = await getToken(messaging, vapidKey);
    const myUserAgent = navigator.userAgent;
    console.log("token here:",myToken);
    const mydata = JSON.stringify({
      token: myToken,
      userAgent: myUserAgent,
      isAdmin: isAdmin
    })

    const res = await axios({
      method: "post",
      url: API_URL,
      headers: { "content-type": "application/json" },
      data: mydata,
    })
  };

  return (
    <div>
      {isAllowed ? (
        <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
      ) : (<div><div>Not Allowed!</div><div>Please allow Notifications and reload</div></div>
      
      )}
    </div>
  );
}

export default Sample1;
