import {
  Container,
  Dropdown,
  DropdownButton,
  Button,
  Form,
  FloatingLabel,
  Spinner,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useRef, useState } from "react";
import axios from "axios";

/*
TODO: https://firebase.google.com/docs/cloud-messaging/send-message
https://levelup.gitconnected.com/show-push-notifications-in-react-449949e98e01
 */

function SendNotif() {
  const notifTextRef = useRef();
  const notifTitleRef = useRef();
  const API_URL = process.env.REACT_APP_API_URL + "/sendnotif";
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const mydata = JSON.stringify({
      title: notifTitleRef.current.value,
      notiftext: notifTextRef.current.value,
      notifUrl: notifUrl,
    });

    console.log(mydata);

    const res = await axios({
      method: "post",
      url: API_URL,
      headers: { "content-type": "application/json" },
      data: mydata,
    });
  };

  const [notifUrl, setNotifUrl] = useState("");

  return (
    <div className="m-5 text-center">
      <Form.Control
        className="mb-3"
        type="text"
        ref={notifTitleRef}
        placeholder="Enter notif Title"
      />
      <Form.Control
        className="mb-3"
        as="textarea"
        rows={3}
        ref={notifTextRef}
        placeholder="Enter notif Body"
      />

      <DropdownButton
        className="mb-3"
        title={notifUrl ? notifUrl : "Select Notif Redirect URL"}
        onSelect={(e) => {
          setNotifUrl(BASE_URL + e);
        }}
      >
        <Dropdown.Item eventKey="/sample1">sample1</Dropdown.Item>
        <Dropdown.Item eventKey="/sample2">sample2</Dropdown.Item>
        <Dropdown.Item eventKey="/sample3">sample3</Dropdown.Item>
      </DropdownButton>

      <Button variant="primary" type="submit" onClick={handleFormSubmit}>
        Send Notif
      </Button>
    </div>
  );
}

export default SendNotif;
