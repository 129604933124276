import { Routes, Route } from 'react-router-dom';
// import "./App.css";
import Home from "./Home";
import Home1 from "./Home1";
import Admin from "./Admin";
import SendNotif from "./SendNotif";
import Sample1 from './sample1';
import Sample2 from './sample2';
import Sample3 from './sample3';
import Sample4 from './sample4';
import Sample5 from './sample5';
import Sample6 from './sample6';
import Sample7 from './sample7';

function App() {
  return (
    <Routes>
      
      <Route path="/*" element={<Home />} />
      <Route path="/home1" element={<Home1 />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/sendnotif" element={<SendNotif />} />
      <Route path='/sample1' element={<Sample1/>} />
      <Route path='/sample2' element={<Sample2/>} />
      <Route path='/sample3' element={<Sample3/>} />
      <Route path='/sample4' element={<Sample4/>} />
      <Route path='/sample5' element={<Sample5/>} />
      <Route path='/sample6' element={<Sample6/>} />
      <Route path='/sample7' element={<Sample7/>} />

    </Routes>
  );
};

export default App;