import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Form,
  // Alert,
} from "react-bootstrap";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "/login";

function AdminUser() {
  const [isAllowed, setAllowed] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();

  const navigate = useNavigate();

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      setAllowed(true);
    } else if (permission === "denied") {
      setAllowed(false);
    }
  };

  useEffect(() => {
    // Req user for notification permission
    requestPermission();
  }, []);

  // const goToSendNotif = async (isAdmin) => {
  //   const myToken = await getToken(messaging, vapidKey);
  //   const myUserAgent = navigator.userAgent;

  //   const mydata = JSON.stringify({
  //     token: myToken,
  //     userAgent: myUserAgent,
  //     isAdmin: isAdmin,
  //   });

  //   const res = await axios({
  //     method: "post",
  //     url: API_URL,
  //     headers: { "content-type": "application/json" },
  //     data: mydata,
  //   });

  //   navigate("/sendnotif", {
  //     state: { token: myToken, isAdmin: isAdmin },
  //   });
  // };

  const handleLogin = async (e) => {
    e.preventDefault();

    let mydata = JSON.stringify({
      email: emailRef.current.value,
      password: passwordRef.current.value,
    });

    try {
      const res = await axios({
        method: "post",
        url: API_URL,
        headers: { "content-type": "application/json" },
        data: mydata,
      });

      setLoginFailed(false);
      navigate("/sendnotif");
    } catch (e) {
      setLoginFailed(true);
    }
    // console.log('res',res.status);

    // if (res.status !== 200) {
    //   console.log("res", res);
    // } else {
    // }
  };

  return (
    <div className="row d-flex justify-content-center">
      <div className="m-5 col-md-3 text-center">
        {isAllowed ? (
          <>
            <h1> Welcome </h1>
            <Form.Control
              className="m-3"
              type="text"
              ref={emailRef}
              placeholder="Email..."
            />
            <Form.Control
              className="m-3"
              type="password"
              ref={passwordRef}
              placeholder="Password..."
            />

            <Button
              className="m-3"
              variant="primary"
              type="submit"
              onClick={handleLogin}
            >
              Login
            </Button>

            {/* {loginFailed ? "failed" : "suc"} */}
            {/* <>
                <Alert key="danger" variant="danger">
                  This is a alert—check it out!
                </Alert>
              </> */}
          </>
        ) : (
          "Not allowed!"
        )}
      </div>
    </div>
  );
}

export default AdminUser;
