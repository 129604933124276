import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import vapidKey from "./vapidkey.json";
import { messaging } from "./firebaseInit";
import { getToken } from "firebase/messaging";

const adminroute = "/admin";
const rawHTML = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width" />
    <title>
        Global leaders in wall and floor building products | James Hardie
    </title>
    <meta name="next-head-count" content="31" />
    <meta charset="utf-8" />
    <meta name="theme-color" />
   
    <style>
        @font-face {
            font-display: swap;
            font-family: CasualDe;
            src: url(https://static-content-prod.jameshardie-digital.com.au/web-static/Casual-Delight.woff2);
        }

        @font-face {
            font-display: swap;
            font-family: HelveticaNeueMedium;
            src: url(https://static-content-prod.jameshardie-digital.com.au/web-static/Helvetica-Neue-Medium.woff2);
        }

        @font-face {
            font-display: swap;
            font-family: HelveticaNeueLight;
            src: url(https://static-content-prod.jameshardie-digital.com.au/web-static/HelveticaNeueLight.woff2);
        }
    </style>
    <style data-emotion="css-global xlob10">
        html {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            box-sizing: border-box;
            -webkit-text-size-adjust: 100%;
        }

        *,
        *::before,
        *::after {
            box-sizing: inherit;
        }

        strong,
        b {
            font-weight: 700;
        }

        body {
            margin: 0;
            color: #4d4d4d;
            font-family: "Helvetica Neue", Arial !important;
            font-style: normal;
            font-weight: 400;
            font-size: 1.125rem !important;
            line-height: 1.5;
            background-color: #fff;
        }

        @media (max-width: 599.95px) {
            body {
                font-size: 1rem !important;
            }
        }

        @media print {
            body {
                background-color: #fff;
            }
        }

        body::backdrop {
            background-color: #fff;
        }
    </style>
    <style data-emotion="css-global 1prfaxn">
        @-webkit-keyframes mui-auto-fill {
            from {
                display: block;
            }
        }

        @keyframes mui-auto-fill {
            from {
                display: block;
            }
        }

        @-webkit-keyframes mui-auto-fill-cancel {
            from {
                display: block;
            }
        }

        @keyframes mui-auto-fill-cancel {
            from {
                display: block;
            }
        }
    </style>
    <style
        data-emotion="css q4ep8l olgc7d tzs92q 1kp4wm 1czp3kr 1ngn7d4 kjrvoc aiuqfj btgl3b 12somyc yldsjb 5eqyno 1ju78q3 4hmlav 1fg6i3b 4wm3w9 124tm9j 1ykzvdh zioy2a qbo5pr 1sreei5 1mdcd3x 1t9xarj vxczqf e6eg30 14a5f58 dpza2q 1pygleu s08p0c zldx2o 1pd54sm 1cx15do l1hrtf 6iyu0u 14yq2cq lzcscj v7z6tj 9qgufv 1oeukn5 zb31ck k3t74o v314b8 h4mkp0 1nbp9dy bg8l6y gcmuum 1mv9q0j isbt42 1tp3fv5 1pazk8c lso9yh obcdoc 1wj3klm 1v2mqz1 1c89j9n 1gpw1ii j2nlct 1irb7ak dwbwb0 17v9o0m 5cpcil 1fdzn5x 16sq8z5 1dc2gf7 1h8x5i4 1eb19i5 1lq20jt 5ksc45 l0ixq9 15oc0z7 1ojo88k ylmwgs 19pxr9t toh2zd eu4p5k cfr1uf 4rd8yq l590sd 1vb9dc0 1nt1z0w j053c4 xpecvx 1d3bbye kyljxl 1xoc9pk xxkxp1 1iqoar8 gbbys0 1yza03u osx1y3 16fecoe 1w70cmi vq1f37 1x5jdmq igs3ac ihdtdm 1xm32e0 wfhwfb e77qtu xue0n1 1tdb2ik nkf0zx x8mzix 837muv 1i1gmsc mlmdw2 144p0m9 16hhtir sxox1k">
        .css-q4ep8l {
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 20;
            background-color: #ffffff;
            box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
        }

        .css-olgc7d {
            height: 100%;
            width: 100%;
        }

        .css-tzs92q {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .css-1kp4wm {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            -webkit-justify-content: flex-start;
            justify-content: flex-start;
            height: 60px;
            background-color: #ffffff;
        }

        @media (min-width: 600px) {
            .css-1kp4wm {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 960px) {
            .css-1kp4wm {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1280px) {
            .css-1kp4wm {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1920px) {
            .css-1kp4wm {
                -webkit-flex-basis: 83.333333%;
                -ms-flex-preferred-size: 83.333333%;
                flex-basis: 83.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 83.333333%;
            }
        }

        @media (min-width: 600px) {
            .css-1kp4wm {
                height: 90px;
                padding: 0 20px;
            }
        }

        .css-1czp3kr {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        @media (min-width: 1280px) {
            .css-1czp3kr {
                -webkit-flex-basis: 16.666667%;
                -ms-flex-preferred-size: 16.666667%;
                flex-basis: 16.666667%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 16.666667%;
            }
        }

        @media (min-width: 1920px) {
            .css-1czp3kr {
                -webkit-flex-basis: 16.666667%;
                -ms-flex-preferred-size: 16.666667%;
                flex-basis: 16.666667%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 16.666667%;
            }
        }

        .css-1ngn7d4 {
            padding: 15px 15px 15px 24px;
            display: inline-block;
            cursor: pointer;
            transition-property: opacity, filter;
            transition-duration: 0.15s;
            transition-timing-function: linear;
            font: inherit;
            color: inherit;
            text-transform: none;
            background-color: transparent;
            border: 0;
            margin: 0;
            overflow: visible;
        }

        .css-1ngn7d4:focus {
            outline: none;
            background-color: white !important;
        }

        @media (min-width: 1280px) {
            .css-1ngn7d4 {
                display: none;
            }
        }

        .css-1ngn7d4 span {
            width: 18px;
            height: 24px;
            display: inline-block;
            position: relative;
        }

        .css-1ngn7d4 span span {
            display: block;
            top: 8px;
            margin-top: 0;
            width: 18px;
            height: 2px;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
            background-color: #343b4e;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            transform: none;
        }

        .css-1ngn7d4 span span:before {
            content: "";
            display: block;
            width: 18px;
            height: 2px;
            background-color: #343b4e;
            position: absolute;
            transition-property: transform, opacity;
            transition-duration: 0.15s;
            transition-timing-function: ease;
            top: 7px;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            transform: none;
            opacity: 1;
        }

        .css-1ngn7d4 span span:after {
            content: "";
            display: block;
            width: 18px;
            height: 2px;
            background-color: #343b4e;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
            top: 14px;
        }

        .css-1ngn7d4 span>.open>span>span {
            -webkit-transform: translate3d(0, 6px, 0) rotate(45deg);
            -moz-transform: translate3d(0, 6px, 0) rotate(45deg);
            -ms-transform: translate3d(0, 6px, 0) rotate(45deg);
            transform: translate3d(0, 6px, 0) rotate(45deg);
        }

        .css-1ngn7d4 span>.open>span>span:before {
            opacity: 0;
        }

        .css-1ngn7d4 span>.open>span>span:after {
            -webkit-transform: translate3d(0, -14px, 0) rotate(-90deg);
            -moz-transform: translate3d(0, -14px, 0) rotate(-90deg);
            -ms-transform: translate3d(0, -14px, 0) rotate(-90deg);
            transform: translate3d(0, -14px, 0) rotate(-90deg);
        }

        .css-1ngn7d4>.open {
            background: green;
        }

        .css-kjrvoc {
            cursor: pointer;
            display: none;
            position: relative;
            top: 1px;
        }

        @media (min-width: 1280px) {
            .css-kjrvoc {
                display: inline-block;
            }
        }

        .css-kjrvoc svg {
            width: 190px;
        }

        .css-aiuqfj {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .css-btgl3b {
            display: none;
        }

        @media (min-width: 1280px) {
            .css-btgl3b {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                height: 90px;
                padding-left: 40px;
            }
        }

        @media (min-width: 1920px) {
            .css-btgl3b {
                padding-left: 0px;
            }
        }

        .css-12somyc {
            position: relative;
            color: #343b4e;
            padding: 10px 0;
            background-color: #ffffff;
            cursor: pointer;
            height: 100%;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-family: "Montserrat", Arial;
            margin-right: 50px;
            font-weight: 500;
            font-size: 1.1rem;
        }

        .css-12somyc:last-child {
            margin-right: 0;
        }

        .css-12somyc .mega-menu {
            top: -700px;
            opacity: 0;
        }

        .css-12somyc:hover .mega-menu {
            -webkit-transform: translateY(790px);
            -moz-transform: translateY(790px);
            -ms-transform: translateY(790px);
            transform: translateY(790px);
            opacity: 1;
        }

        .css-12somyc:hover>span {
            color: #00833e;
        }

        .css-12somyc:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #00833e;
            height: 4px;
            width: 100%;
            -webkit-transition: -webkit-transform 0.1s ease-in;
            transition: transform 0.1s ease-in;
            -webkit-transform: scaleX(0);
            -moz-transform: scaleX(0);
            -ms-transform: scaleX(0);
            transform: scaleX(0);
        }

        .css-12somyc:hover:after {
            -webkit-transform: scaleX(1);
            -moz-transform: scaleX(1);
            -ms-transform: scaleX(1);
            transform: scaleX(1);
        }

        .css-12somyc:hover .sub-menu {
            -webkit-transform: translateY(540px);
            -moz-transform: translateY(540px);
            -ms-transform: translateY(540px);
            transform: translateY(540px);
            opacity: 1;
        }

        .css-yldsjb {
            z-index: -1;
            position: fixed;
            left: 0;
            width: 100%;
            background-color: #ffffff;
            color: #000000;
            border-top: 1px solid #e6e8ec;
            -webkit-transition: all 0.3s linear;
            transition: all 0.3s linear;
            padding: 50px 0;
            box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
        }

        .css-yldsjb>div>div {
            -webkit-box-flex-wrap: nowrap;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }

        .css-5eqyno {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .css-1ju78q3 {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 83.333333%;
            -ms-flex-preferred-size: 83.333333%;
            flex-basis: 83.333333%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 83.333333%;
            margin: 0 auto;
        }

        @media (min-width: 600px) {
            .css-1ju78q3 {
                -webkit-flex-basis: 83.333333%;
                -ms-flex-preferred-size: 83.333333%;
                flex-basis: 83.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 83.333333%;
            }
        }

        @media (min-width: 960px) {
            .css-1ju78q3 {
                -webkit-flex-basis: 83.333333%;
                -ms-flex-preferred-size: 83.333333%;
                flex-basis: 83.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 83.333333%;
            }
        }

        @media (min-width: 1280px) {
            .css-1ju78q3 {
                -webkit-flex-basis: 83.333333%;
                -ms-flex-preferred-size: 83.333333%;
                flex-basis: 83.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 83.333333%;
            }
        }

        @media (min-width: 1920px) {
            .css-1ju78q3 {
                -webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        .css-4hmlav {
            position: absolute;
            left: 50%;
            margin-left: -70px;
            margin-top: 2px;
        }

        .css-4hmlav>img {
            width: 140px;
        }

        @media (min-width: 600px) {
            .css-4hmlav {
                margin-left: -95px;
            }

            .css-4hmlav>img {
                width: 190px;
            }
        }

        @media (min-width: 1280px) {
            .css-4hmlav {
                display: none;
            }
        }

        .css-1fg6i3b {
            box-sizing: border-box;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            -webkit-justify-content: flex-end;
            justify-content: flex-end;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
        }

        .css-4wm3w9 {
            box-sizing: border-box;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
        }

        @media (min-width: 0px) {
            .css-4wm3w9 {
                display: none;
            }
        }

        @media (min-width: 600px) {
            .css-4wm3w9 {
                display: block;
            }
        }

        .css-124tm9j {
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            position: relative;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
            background-color: transparent;
            outline: 0;
            border: 0;
            margin: 0;
            border-radius: 0;
            padding: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: middle;
            -moz-appearance: none;
            -webkit-appearance: none;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: inherit;
            text-align: center;
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            font-size: 1.7142857142857142rem;
            padding: 8px;
            border-radius: 50%;
            overflow: visible;
            color: rgba(0, 0, 0, 0.54);
            -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            color: #46a069;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            color: #4d4d4d;
            font-size: 1.125rem;
            padding-right: 22px;
        }

        .css-124tm9j::-moz-focus-inner {
            border-style: none;
        }

        .css-124tm9j.Mui-disabled {
            pointer-events: none;
            cursor: default;
        }

        @media print {
            .css-124tm9j {
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
            }
        }

        .css-124tm9j.Mui-disabled {
            background-color: transparent;
            color: rgba(0, 0, 0, 0.26);
        }

        .css-124tm9j:hover {
            border-radius: 0;
            background: none;
        }

        @media (min-width: 1280px) {
            .css-124tm9j {
                padding-right: 25px;
            }
        }

        .css-1ykzvdh {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 1em;
            height: 1em;
            display: inline-block;
            fill: currentColor;
            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-size: 1.7142857142857142rem;
        }

        @media (min-width: 1280px) {
            .css-1ykzvdh {
                margin-right: 10px;
                -webkit-transform: scale(0.9);
                -moz-transform: scale(0.9);
                -ms-transform: scale(0.9);
                transform: scale(0.9);
            }
        }

        @media (max-width: -0.05px) {
            .css-1ykzvdh {
                -webkit-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2);
            }
        }

        .css-zioy2a {
            display: none;
        }

        @media (min-width: 1440px) {
            .css-zioy2a {
                display: inline;
            }
        }

        .css-qbo5pr {
            padding: 15px 16px;
            background: linear-gradient(305.97deg, #419963 2.9%, #36b271 73.89%);
            box-shadow: 0px 0px 4px rgba(52, 59, 78, 0.5),
                0px 0px 2px rgba(0, 0, 0, 0.12);
            border-radius: 0;
            display: none;
            font-family: "Helvetica Neue", Arial;
            -webkit-transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1);
            transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1);
        }

        @media (min-width: 1280px) {
            .css-qbo5pr {
                display: inline-block;
            }
        }

        .css-qbo5pr:hover {
            background: linear-gradient(305.97deg, #00833e 2.9%, #00833e 73.89%);
        }

        .css-qbo5pr:hover span {
            color: #ffffff;
        }

        .css-qbo5pr:hover div {
            color: #ffffff;
        }

        .css-1sreei5 {
            color: #ffffff;
            text-transform: none;
            font-weight: bold;
            font-size: 1rem;
            line-height: 1.375rem;
        }

        @media (min-width: 1280px) {
            .css-1sreei5 {
                font-size: 1.125rem;
            }
        }

        .css-1mdcd3x {
            color: #ffffff;
            font-weight: 500;
            font-size: 0.25rem;
            line-height: 0.6rem;
            vertical-align: text-top;
            padding-right: 9px;
        }

        @media (min-width: 1280px) {
            .css-1mdcd3x {
                font-size: 0.3125rem;
            }
        }

        .css-1t9xarj {
            color: #ffffff;
        }

        .css-vxczqf {
            color: #ffffff;
            text-transform: none;
            font-weight: 500;
            font-size: 0.7rem;
            line-height: 0.9375rem;
            float: right;
            max-width: 80px;
            text-align: left;
            padding-left: 12px;
        }

        @media (min-width: 1280px) {
            .css-vxczqf {
                font-size: 0.75rem;
                max-width: 85px;
            }
        }

        .css-e6eg30 {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            width: 100%;
            height: calc(100% - 60px);
            background-repeat: no-repeat;
            -webkit-background-size: cover;
            background-size: cover;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
        }

        @media (min-width: 1280px) {
            .css-e6eg30 {
                display: none;
            }
        }

        .css-14a5f58 {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
            height: 100%;
        }

        @media (min-width: 600px) {
            .css-14a5f58 {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 960px) {
            .css-14a5f58 {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1280px) {
            .css-14a5f58 {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1920px) {
            .css-14a5f58 {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        .css-dpza2q {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 2;
        }

        @media (min-width: 600px) {
            .css-dpza2q {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 960px) {
            .css-dpza2q {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1280px) {
            .css-dpza2q {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1920px) {
            .css-dpza2q {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        .css-1pygleu {
            width: 100%;
            position: absolute;
            top: 60px;
            background-color: #ffffff;
        }

        @media (min-width: 600px) {
            .css-1pygleu {
                top: 90px;
            }
        }

        .css-s08p0c {
            width: 100%;
            height: 100%;
        }

        .css-zldx2o {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            height: 100%;
        }

        .css-1pd54sm {
            width: 100%;
            height: 100%;
        }

        .css-1pd54sm .slick-dots {
            bottom: 12px;
        }

        .css-1pd54sm .slick-dots li {
            width: 17px;
            margin: unset;
        }

        @media (min-width: 600px) {
            .css-1pd54sm .slick-dots li {
                margin: 0 3px;
            }
        }

        .css-1pd54sm .slick-dots li button:before {
            opacity: 0.3;
            color: #c1c1c1;
            font-size: 11px;
        }

        @media (min-width: 600px) {
            .css-1pd54sm .slick-dots li button:before {
                font-size: 13px;
            }
        }

        @media (min-width: 960px) {
            .css-1pd54sm .slick-dots li button:before {
                color: white;
                opacity: 0.3;
            }
        }

        .css-1pd54sm .slick-dots li.slick-active button:before {
            opacity: 0.8;
            color: #d2d2d2;
        }

        @media (min-width: 960px) {
            .css-1pd54sm .slick-dots li.slick-active button:before {
                color: white;
                opacity: 0.9;
            }
        }

        .css-1cx15do {
            box-sizing: border-box;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
        }

        @media (min-width: 0px) {
            .css-1cx15do {
                display: none;
            }
        }

        @media (min-width: 1280px) {
            .css-1cx15do {
                display: initial;
            }
        }

        .css-l1hrtf {
            position: absolute;
            z-index: 1;
            top: 295px;
        }

        .css-6iyu0u {
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            position: relative;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
            background-color: transparent;
            outline: 0;
            border: 0;
            margin: 0;
            border-radius: 0;
            padding: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: middle;
            -moz-appearance: none;
            -webkit-appearance: none;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: inherit;
            text-align: center;
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            font-size: 1.7142857142857142rem;
            padding: 8px;
            border-radius: 50%;
            overflow: visible;
            color: rgba(0, 0, 0, 0.54);
            -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            color: white;
            opacity: 0.3;
            padding: 0;
        }

        .css-6iyu0u::-moz-focus-inner {
            border-style: none;
        }

        .css-6iyu0u.Mui-disabled {
            pointer-events: none;
            cursor: default;
        }

        @media print {
            .css-6iyu0u {
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
            }
        }

        .css-6iyu0u:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }

        @media (hover: none) {
            .css-6iyu0u:hover {
                background-color: transparent;
            }
        }

        .css-6iyu0u.Mui-disabled {
            background-color: transparent;
            color: rgba(0, 0, 0, 0.26);
        }

        .css-6iyu0u:hover {
            opacity: 0.6;
        }

        .css-14yq2cq {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 1em;
            height: 1em;
            display: inline-block;
            fill: currentColor;
            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-size: 1.7142857142857142rem;
        }

        .css-lzcscj {
            position: absolute;
            z-index: 1;
            right: 0;
            top: 295px;
        }

        .css-v7z6tj {
            background-color: white;
        }

        @media (min-width: 960px) {
            .css-v7z6tj {
                position: relative;
            }
        }

        .css-9qgufv {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
        }

        @media (min-width: 960px) {
            .css-9qgufv {
                position: absolute;
                bottom: -65px;
            }
        }

        @media (min-width: 1280px) {
            .css-9qgufv {
                position: absolute;
                bottom: -34px;
            }
        }

        .css-1oeukn5 {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
        }

        @media (min-width: 600px) {
            .css-1oeukn5 {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 960px) {
            .css-1oeukn5 {
                -webkit-flex-basis: 83.333333%;
                -ms-flex-preferred-size: 83.333333%;
                flex-basis: 83.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 83.333333%;
            }
        }

        @media (min-width: 1280px) {
            .css-1oeukn5 {
                -webkit-flex-basis: 83.333333%;
                -ms-flex-preferred-size: 83.333333%;
                flex-basis: 83.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 83.333333%;
            }
        }

        @media (min-width: 1920px) {
            .css-1oeukn5 {
                -webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        @media (min-width: 1280px) {
            .css-1oeukn5 {
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                -webkit-justify-content: flex-end;
                justify-content: flex-end;
            }
        }

        .css-1oeukn5 button {
            height: 64px;
            background: linear-gradient(305.97deg, #419963 2.9%, #36b271 73.89%);
            border-radius: unset;
            text-align: center;
            box-shadow: 0px 2px 10px rgba(52, 59, 78, 0.5),
                0px 0px 2px rgba(0, 0, 0, 0.12);
            -webkit-transition: none;
            transition: none;
        }

        .css-1oeukn5 button:hover {
            background: linear-gradient(305.97deg, #00833e 2.9%, #00833e 73.89%);
        }

        @media (min-width: 600px) {
            .css-1oeukn5 button {
                height: 92px;
            }
        }

        @media (min-width: 1280px) {
            .css-1oeukn5 button {
                width: 300px;
                text-align: left;
            }
        }

        .css-zb31ck {
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            position: relative;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
            background-color: transparent;
            outline: 0;
            border: 0;
            margin: 0;
            border-radius: 0;
            padding: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: middle;
            -moz-appearance: none;
            -webkit-appearance: none;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: inherit;
            font-family: "Montserrat", Arial;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            text-transform: uppercase;
            line-height: 1.75;
            min-width: 64px;
            padding: 6px 8px;
            border-radius: 4px;
            -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            color: #46a069;
            width: 100%;
            border-radius: 0 !important;
        }

        .css-zb31ck::-moz-focus-inner {
            border-style: none;
        }

        .css-zb31ck.Mui-disabled {
            pointer-events: none;
            cursor: default;
        }

        @media print {
            .css-zb31ck {
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
            }
        }

        @media (max-width: 599.95px) {
            .css-zb31ck {
                font-size: 0.875rem;
            }
        }

        .css-zb31ck:hover {
            -webkit-text-decoration: none;
            text-decoration: none;
            background-color: rgba(70, 160, 105, 0.04);
        }

        @media (hover: none) {
            .css-zb31ck:hover {
                background-color: transparent;
            }
        }

        .css-zb31ck.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
        }

        .css-k3t74o {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 83.333333%;
            -ms-flex-preferred-size: 83.333333%;
            flex-basis: 83.333333%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 83.333333%;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
        }

        @media (min-width: 600px) {
            .css-k3t74o {
                -webkit-flex-basis: 83.333333%;
                -ms-flex-preferred-size: 83.333333%;
                flex-basis: 83.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 83.333333%;
            }
        }

        @media (min-width: 960px) {
            .css-k3t74o {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1280px) {
            .css-k3t74o {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1920px) {
            .css-k3t74o {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 960px) {
            .css-k3t74o {
                padding: 40px 0;
            }
        }

        @media (min-width: 1280px) {
            .css-k3t74o {
                padding-left: 15px;
                padding-right: 7px;
                -webkit-align-items: baseline;
                -webkit-box-align: baseline;
                -ms-flex-align: baseline;
                align-items: baseline;
                line-height: 22px;
            }
        }

        .css-k3t74o span {
            line-height: 125%;
        }

        .css-k3t74o svg {
            color: white;
            font-size: 1.75rem;
            margin-left: auto;
        }

        .css-v314b8 {
            margin: 0;
            font-family: "Montserrat", Arial;
            font-style: normal;
            font-weight: 600;
            font-size: 1.125rem !important;
            text-transform: uppercase;
            color: #00833e;
            line-height: 2.66;
            color: #ffffff;
        }

        @media (max-width: 599.95px) {
            .css-v314b8 {
                font-size: 1rem !important;
            }
        }

        .css-h4mkp0 {
            box-sizing: border-box;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
        }

        @media (min-width: 0px) {
            .css-h4mkp0 {
                display: none;
            }
        }

        @media (min-width: 960px) {
            .css-h4mkp0 {
                display: initial;
            }
        }

        .css-1nbp9dy {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            background-color: white;
        }

        .css-bg8l6y {
            margin-bottom: 85px;
            width: 100%;
        }

        .css-gcmuum {
            width: 100%;
            margin-top: 48px;
            margin-bottom: 10px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        @media (min-width: 600px) {
            .css-gcmuum {
                margin-top: 80px;
                margin-bottom: 25px;
            }
        }

        @media (min-width: 960px) {
            .css-gcmuum {
                margin-top: 100px;
                margin-bottom: 25px;
            }
        }

        @media (min-width: 1280px) {
            .css-gcmuum {
                margin-top: 115px;
                margin-bottom: 24px;
            }
        }

        .css-gcmuum h2 {
            text-align: center;
            font-family: "Montserrat", Arial;
            font-size: 1.5rem !important;
            font-weight: bold;
            line-height: 125% !important;
            color: #4d4d4d;
        }

        .css-1mv9q0j {
            margin: 0;
            font-family: "Montserrat", Arial !important;
            font-style: normal;
            font-weight: bold;
            font-size: 2.125rem !important;
            line-height: 1.2;
        }

        @media (max-width: 599.95px) {
            .css-1mv9q0j {
                font-size: 1.25rem;
            }
        }

        .css-isbt42 {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            margin-top: -16px;
            width: calc(100% + 16px);
            margin-left: -16px;
        }

        .css-isbt42>.MuiGrid-item {
            padding-top: 16px;
        }

        .css-isbt42>.MuiGrid-item {
            padding-left: 16px;
        }

        .css-1tp3fv5 {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
            margin-top: 30px;
            padding: 10px;
        }

        @media (min-width: 600px) {
            .css-1tp3fv5 {
                -webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        @media (min-width: 960px) {
            .css-1tp3fv5 {
                -webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        @media (min-width: 1280px) {
            .css-1tp3fv5 {
                -webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        @media (min-width: 1920px) {
            .css-1tp3fv5 {
                -webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        .css-1tp3fv5 h3 {
            margin: 5px 0 !important;
            text-transform: uppercase;
        }

        .css-1tp3fv5 p {
            margin-top: 8px !important;
            margin-bottom: 10px !important;
            color: #52556b;
        }

        @media (min-width: 600px) {
            .css-1tp3fv5 p {
                margin-bottom: 40px !important;
            }
        }

        .css-1pazk8c {
            margin-bottom: 12px;
        }

        .css-1pazk8c img {
            width: 100%;
            height: 100%;
        }

        .css-lso9yh {
            margin: 0;
            font-family: "Montserrat", Arial !important;
            font-style: normal;
            font-weight: bold;
            font-size: 1.5rem !important;
            color: #4d4d4d;
            line-height: 1.167;
        }

        @media (max-width: 599.95px) {
            .css-lso9yh {
                font-size: 1.125rem !important;
            }
        }

        .css-obcdoc {
            margin: 0;
            font-family: "Helvetica Neue", Arial !important;
            font-style: normal;
            font-weight: 400;
            font-size: 1.125rem !important;
            color: #4d4d4d;
            line-height: 1.5;
        }

        @media (max-width: 599.95px) {
            .css-obcdoc {
                font-size: 1rem !important;
            }
        }

        .css-1wj3klm {
            margin-bottom: 5px;
            margin-top: 12px;
        }

        .css-1wj3klm button {
            padding: 0;
        }

        .css-1wj3klm button:hover {
            -webkit-text-decoration: underline;
            text-decoration: underline;
        }

        .css-1wj3klm button span {
            -webkit-text-decoration: none;
            text-decoration: none;
            color: #00833e;
        }

        .css-1v2mqz1 {
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            position: relative;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
            background-color: transparent;
            outline: 0;
            border: 0;
            margin: 0;
            border-radius: 0;
            padding: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: middle;
            -moz-appearance: none;
            -webkit-appearance: none;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: inherit;
            font-family: "Montserrat", Arial;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            text-transform: uppercase;
            line-height: 1.75;
            min-width: 64px;
            padding: 6px 8px;
            border-radius: 4px;
            -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            color: #46a069;
            border-radius: 0 !important;
        }

        .css-1v2mqz1::-moz-focus-inner {
            border-style: none;
        }

        .css-1v2mqz1.Mui-disabled {
            pointer-events: none;
            cursor: default;
        }

        @media print {
            .css-1v2mqz1 {
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
            }
        }

        @media (max-width: 599.95px) {
            .css-1v2mqz1 {
                font-size: 0.875rem;
            }
        }

        .css-1v2mqz1:hover {
            -webkit-text-decoration: none;
            text-decoration: none;
            background-color: rgba(70, 160, 105, 0.04);
        }

        @media (hover: none) {
            .css-1v2mqz1:hover {
                background-color: transparent;
            }
        }

        .css-1v2mqz1.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
        }

        .css-1c89j9n {
            margin: 0;
            font-family: "Montserrat", Arial;
            font-style: normal;
            font-weight: 600;
            font-size: 1.125rem !important;
            text-transform: uppercase;
            color: #00833e;
            line-height: 2.66;
        }

        @media (max-width: 599.95px) {
            .css-1c89j9n {
                font-size: 1rem !important;
            }
        }

        .css-1gpw1ii {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            background-color: #f5f7fa;
        }

        .css-j2nlct {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 83.333333%;
            -ms-flex-preferred-size: 83.333333%;
            flex-basis: 83.333333%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 83.333333%;
            margin: 0 auto;
        }

        @media (min-width: 600px) {
            .css-j2nlct {
                -webkit-flex-basis: 83.333333%;
                -ms-flex-preferred-size: 83.333333%;
                flex-basis: 83.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 83.333333%;
            }
        }

        @media (min-width: 960px) {
            .css-j2nlct {
                -webkit-flex-basis: 83.333333%;
                -ms-flex-preferred-size: 83.333333%;
                flex-basis: 83.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 83.333333%;
            }
        }

        @media (min-width: 1280px) {
            .css-j2nlct {
                -webkit-flex-basis: 83.333333%;
                -ms-flex-preferred-size: 83.333333%;
                flex-basis: 83.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 83.333333%;
            }
        }

        @media (min-width: 1920px) {
            .css-j2nlct {
                -webkit-flex-basis: 83.333333%;
                -ms-flex-preferred-size: 83.333333%;
                flex-basis: 83.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 83.333333%;
            }
        }

        .css-1irb7ak {
            width: 100%;
            margin-top: 40px;
            margin-bottom: 30px;
            background-color: #f5f7fa;
        }

        @media (min-width: 600px) {
            .css-1irb7ak {
                background-color: white;
                margin-top: 55px;
                margin-bottom: 55px;
            }
        }

        @media (min-width: 960px) {
            .css-1irb7ak {
                margin-top: 130px;
                margin-bottom: 65px;
            }
        }

        @media (min-width: 1280px) {
            .css-1irb7ak {
                margin-top: 95px;
                margin-bottom: 90px;
            }
        }

        .css-dwbwb0 {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            -webkit-flex-direction: row-reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
        }

        @media (min-width: 600px) {
            .css-dwbwb0 {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 960px) {
            .css-dwbwb0 {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1280px) {
            .css-dwbwb0 {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1920px) {
            .css-dwbwb0 {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        .css-17v9o0m {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
            margin-bottom: 5px;
        }

        @media (min-width: 600px) {
            .css-17v9o0m {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 960px) {
            .css-17v9o0m {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1280px) {
            .css-17v9o0m {
                -webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        @media (min-width: 1920px) {
            .css-17v9o0m {
                -webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        @media (min-width: 1280px) {
            .css-17v9o0m {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                margin-bottom: 0px;
            }
        }

        .css-17v9o0m img {
            width: 100%;
            height: 100%;
        }

        .css-5cpcil {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
        }

        @media (min-width: 600px) {
            .css-5cpcil {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 960px) {
            .css-5cpcil {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1280px) {
            .css-5cpcil {
                -webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        @media (min-width: 1920px) {
            .css-5cpcil {
                -webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 50%;
            }
        }

        @media (min-width: 600px) {
            .css-5cpcil {
                padding: 15px 48px 40px;
            }
        }

        .css-5cpcil h4 {
            margin-top: 20px !important;
            margin-bottom: 5px !important;
            color: #838a9f;
        }

        .css-5cpcil h1 {
            margin-top: 5px !important;
        }

        .css-5cpcil p {
            margin-top: 8px !important;
            margin-bottom: 10px !important;
        }

        .css-1fdzn5x {
            margin: 0;
            font-family: "Montserrat", Arial !important;
            font-style: normal;
            font-weight: 600;
            font-size: 1.125rem !important;
            line-height: 1.235;
        }

        @media (max-width: 599.95px) {
            .css-1fdzn5x {
                font-size: 0.875rem !important;
            }
        }

        .css-16sq8z5 {
            margin: 0;
            color: #4d4d4d;
            font-family: "Montserrat", Arial !important;
            font-style: normal;
            font-weight: bold;
            font-size: 2.25rem !important;
            line-height: 1.167;
        }

        @media (max-width: 599.95px) {
            .css-16sq8z5 {
                font-size: 1.5rem !important;
            }
        }

        @media (min-width: 1280px) {
            .css-16sq8z5 {
                color: #000000;
                line-height: 1.111 !important;
            }
        }

        .css-1dc2gf7 {
            margin-top: 8px;
            margin-bottom: 15px;
            color: #52556b;
        }

        .css-1h8x5i4 {
            margin-top: 10px;
            margin-bottom: 5px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
        }

        .css-1h8x5i4:first-child {
            margin-right: 5px;
        }

        @media (min-width: 600px) {
            .css-1h8x5i4:first-child {
                margin-right: 10px;
            }
        }

        .css-1h8x5i4 a {
            -webkit-text-decoration: none;
            text-decoration: none;
            text-align: center;
            padding: 10px 20px;
            background: linear-gradient(304.01deg, #419963 2.68%, #36b271 90.94%);
            text-transform: uppercase;
            border-radius: unset;
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
        }

        .css-1h8x5i4 a:hover {
            -webkit-text-decoration: underline;
            text-decoration: underline;
        }

        @media (min-width: 600px) {
            .css-1h8x5i4 a {
                font-size: 1.125rem;
                background: unset;
                color: #00833e;
                -webkit-flex: unset;
                -ms-flex: unset;
                flex: unset;
                padding: 0;
            }

            .css-1h8x5i4 a span {
                color: #00833e;
            }
        }

        .css-1eb19i5 {
            margin: 0;
            font-family: "Montserrat", Arial;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            text-transform: uppercase;
            line-height: 1.75;
            color: #ffffff;
        }

        @media (max-width: 599.95px) {
            .css-1eb19i5 {
                font-size: 0.875rem;
            }
        }

        .css-1lq20jt {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            background-color: #f5f7fa;
            padding: 30px 0;
            background-color: white;
        }

        @media (min-width: 600px) {
            .css-1lq20jt {
                padding: 70px 0;
            }
        }

        @media (min-width: 960px) {
            .css-1lq20jt {
                padding: 100px 0;
            }
        }

        .css-5ksc45 {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 30px;
            text-align: center;
        }

        @media (max-width: 599.95px) {
            .css-5ksc45 {
                padding-top: 30px;
            }
        }

        .css-l0ixq9 h3 {
            text-align: center;
            padding-bottom: 37px;
            font-size: 24px;
            font-weight: 600;
            line-height: 1.25;
        }

        .css-15oc0z7 {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: nowrap;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: space-around;
            -ms-flex-pack: space-around;
            -webkit-justify-content: space-around;
            justify-content: space-around;
            padding: 10px 0;
        }

        @media (max-width: 959.95px) {
            .css-15oc0z7 {
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
            }
        }

        .css-1ojo88k {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            max-width: 300px;
            margin: 0px auto;
        }

        @media (max-width: 959.95px) {
            .css-1ojo88k {
                padding: 10px 0;
            }
        }

        @media (max-width: 1279.95px) {
            .css-1ojo88k {
                max-width: 200px;
            }
        }

        .css-1ojo88k img {
            width: 70px;
            height: 70px;
        }

        .css-1ojo88k h4 {
            text-align: center;
            padding-bottom: 5px;
            padding-top: 10px;
        }

        .css-1ojo88k p {
            padding-top: 5px;
            font-size: 18px;
        }

        @media (max-width: 1279.95px) {
            .css-1ojo88k p {
                font-size: 15px;
            }
        }

        @media (max-width: 599.95px) {
            .css-1ojo88k p {
                display: none;
            }
        }

        .css-ylmwgs {
            text-align: center;
            display: inline-block;
            margin-top: 50px;
            background-color: #00833e;
            color: #f9fafc;
            -webkit-text-decoration: none;
            text-decoration: none;
            padding: 20px 35px;
            font-size: 1.125rem;
            font-weight: 600;
            font-family: "Montserrat", Arial;
        }

        .css-19pxr9t {
            position: relative;
            z-index: 2;
        }

        .css-toh2zd {
            bottom: 16px;
            position: fixed;
            -webkit-transition: right 0.5s;
            transition: right 0.5s;
            --Webkit-Transition: right 0.5s;
            right: -200px;
        }

        @media (max-width: 959.95px) {
            .css-toh2zd {
                right: -200px;
            }
        }

        .css-eu4p5k {
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            position: relative;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
            background-color: transparent;
            outline: 0;
            border: 0;
            margin: 0;
            border-radius: 0;
            padding: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: middle;
            -moz-appearance: none;
            -webkit-appearance: none;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: inherit;
            font-family: "Montserrat", Arial;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            text-transform: uppercase;
            line-height: 1.75;
            min-width: 64px;
            padding: 6px 8px;
            border-radius: 4px;
            -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            color: #46a069;
            width: 100%;
            border-radius: 0 !important;
            position: absolute;
            bottom: 16px;
            height: 87px;
            width: 95px;
            background: linear-gradient(305.97deg, #419963 2.9%, #36b271 73.89%);
            border-radius: unset;
            text-align: left;
            box-shadow: 0px 2px 10px rgba(52, 59, 78, 0.5),
                0px 0px 2px rgba(0, 0, 0, 0.12);
        }

        .css-eu4p5k::-moz-focus-inner {
            border-style: none;
        }

        .css-eu4p5k.Mui-disabled {
            pointer-events: none;
            cursor: default;
        }

        @media print {
            .css-eu4p5k {
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
            }
        }

        @media (max-width: 599.95px) {
            .css-eu4p5k {
                font-size: 0.875rem;
            }
        }

        .css-eu4p5k:hover {
            -webkit-text-decoration: none;
            text-decoration: none;
            background-color: rgba(70, 160, 105, 0.04);
        }

        @media (hover: none) {
            .css-eu4p5k:hover {
                background-color: transparent;
            }
        }

        .css-eu4p5k.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
        }

        .css-eu4p5k:hover {
            background: linear-gradient(305.97deg, #00833e 2.9%, #00833e 73.89%);
        }

        .css-eu4p5k label {
            -webkit-box-pack: unset;
            -ms-flex-pack: unset;
            -webkit-justify-content: unset;
            justify-content: unset;
            padding-left: 1;
        }

        .css-cfr1uf {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-flex-direction: column-reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            -webkit-align-items: baseline;
            -webkit-box-align: baseline;
            -ms-flex-align: baseline;
            align-items: baseline;
            line-height: 18px;
        }

        .css-cfr1uf .buttonText {
            line-height: 125%;
        }

        .css-cfr1uf .buttonIcon {
            color: #ffffff;
            font-size: 1.75rem;
            padding-bottom: 7px;
        }

        .css-4rd8yq {
            position: relative;
            width: 100%;
            margin: auto;
        }

        .css-l590sd {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media (min-width: 1920px) {
            .css-l590sd {
                object-position: left -40px;
            }
        }

        @media (max-width: 599.95px) {
            .css-l590sd {
                object-position: -130px 0;
            }
        }

        .css-1vb9dc0.inner {
            padding: 30px;
            margin: 0 auto;
            max-width: 1296px;
        }

        @media (min-width: 960px) {
            .css-1vb9dc0.inner {
                padding: 50px 30px;
            }
        }

        @media (min-width: 1280px) {
            .css-1vb9dc0.inner {
                padding: 110px 30px;
            }
        }

        @media (max-width: 599.95px) {
            .css-1vb9dc0.inner {
                min-height: 300px;
            }
        }

        .css-1vb9dc0.mainInner {
            padding: 30px;
            margin: 0 auto;
            max-width: 1296px;
        }

        @media (min-width: 1280px) {
            .css-1vb9dc0.mainInner {
                padding: 70px 100px;
            }
        }

        .css-1nt1z0w {
            margin: 0;
            font-family: "CasualDe", Arial;
            font-size: 3.3125rem;
            color: #90bae2;
            font-weight: 500;
            line-height: 1.57;
            text-align: center;
            color: #ffffff;
            position: relative;
            font-family: "Montserrat", Arial;
            font-weight: 600;
        }

        @media (max-width: 599.95px) {
            .css-1nt1z0w {
                font-size: 2.625rem;
            }
        }

        @media (min-width: 600px) {
            .css-1nt1z0w {
                font-size: 4.4586rem;
            }
        }

        @media (min-width: 960px) {
            .css-1nt1z0w {
                font-size: 5.0955rem;
            }
        }

        @media (min-width: 1280px) {
            .css-1nt1z0w {
                font-size: 5.5732rem;
            }
        }

        .css-1nt1z0w.sectionTitle {
            font-size: 2.25rem;
        }

        @media (max-width: 599.95px) {
            .css-1nt1z0w.sectionTitle {
                font-size: 1.6rem;
            }
        }

        .css-1nt1z0w.sectionMainTitle {
            font-size: 4.25rem;
        }

        @media (max-width: 959.95px) {
            .css-1nt1z0w.sectionMainTitle {
                font-size: 3rem;
            }
        }

        @media (max-width: 599.95px) {
            .css-1nt1z0w.sectionMainTitle {
                font-size: 1.6rem;
            }
        }

        .css-j053c4 {
            margin: 0;
            font-family: "Montserrat", Arial !important;
            font-style: normal;
            font-weight: bold;
            font-size: 1.5rem !important;
            color: #4d4d4d;
            line-height: 1.167;
            text-align: center;
            color: #ffffff;
            position: relative;
            text-align: center;
            padding-bottom: 10px;
            font-size: 1.5rem;
            font-weight: 400;
        }

        @media (max-width: 599.95px) {
            .css-j053c4 {
                font-size: 1.125rem !important;
            }
        }

        @media (min-width: 600px) {
            .css-j053c4.sectionSubtitle {
                padding-bottom: 20px;
            }
        }

        @media (max-width: 599.95px) {
            .css-j053c4.sectionSubtitle {
                font-size: 1rem;
            }
        }

        .css-j053c4.sectionMainSubtitle {
            margin-bottom: 10px;
        }

        @media (min-width: 600px) {
            .css-j053c4.sectionMainSubtitle {
                padding-bottom: 20px;
                margin-bottom: 160px;
            }
        }

        @media (max-width: 599.95px) {
            .css-j053c4.sectionMainSubtitle {
                font-size: 1rem;
            }
        }

        .css-xpecvx {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            text-align: center;
            height: 52px;
        }

        @media (max-width: 599.95px) {
            .css-xpecvx {
                height: unset !important;
            }
        }

        .css-xpecvx input {
            border: none;
            font-size: 1.25rem;
            padding: 10px 20px;
            width: 150px;
            font-family: "Montserrat", Arial;
        }

        @media (min-width: 600px) {
            .css-xpecvx input {
                width: 280px;
            }
        }

        @media (min-width: 960px) {
            .css-xpecvx input {
                width: 380px;
            }
        }

        .css-xpecvx .MuiOutlinedInput-root.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
        }

        .css-xpecvx .MuiOutlinedInput-notchedOutline {
            border-width: 0 !important;
        }

        .css-xpecvx button {
            border: none;
            font-size: 1rem;
            padding: 8px 20px;
            cursor: pointer;
            background-color: #00833e;
            color: #ffffff;
            font-family: "Montserrat", Arial;
        }

        .css-xpecvx .MuiFilledInput-underline:before {
            border-bottom: 0px;
        }

        .css-xpecvx .MuiFilledInput-underline:after {
            border-bottom: 0px;
        }

        .css-xpecvx .MuiNativeSelect-select {
            padding-top: 11px;
            padding-bottom: 11px;
            border-right: 1px solid black;
            background-color: white !important;
            height: 1.1876em;
        }

        .css-xpecvx .MuiNativeSelect-select:hover {
            background-color: white;
        }

        .css-xpecvx .Mui-focused {
            font-weight: 400 !important;
            color: #343b4e !important;
        }

        .css-xpecvx .Mui-focused .MuiOutlinedInput-notchedOutline {
            border: none !important;
        }

        .css-xpecvx .MuiOutlinedInput-root {
            background-color: white;
            border-radius: 0px;
        }

        .css-xpecvx .MuiOutlinedInput-root input {
            height: 1.45rem !important;
            width: 100%;
        }

        .css-xpecvx .MuiOutlinedInput-root:hover {
            background-color: white;
        }

        .css-xpecvx .MuiFilledInput-root:hover {
            background-color: white;
        }

        .css-xpecvx .MuiInputLabel-formControl {
            top: -10px;
            z-index: 1;
            color: grey;
            margin-left: 12px;
            line-height: 1;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            font-size: 18px;
            font-family: "Montserrat", Arial;
        }

        .css-xpecvx .MuiInputLabel-shrink {
            display: none;
        }

        .css-xpecvx .MuiButton-root:hover {
            background-color: #00833e;
        }

        @media (max-width: 599.95px) {
            .css-xpecvx .MuiGrid-container {
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }
        }

        @media (max-width: 599.95px) {
            .css-xpecvx .MuiGrid-item {
                margin: 5px 0;
            }
        }

        .css-1d3bbye {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
        }

        .css-kyljxl {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
        }

        @media (min-width: 600px) {
            .css-kyljxl {
                -webkit-flex-basis: 25%;
                -ms-flex-preferred-size: 25%;
                flex-basis: 25%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 25%;
            }
        }

        @media (min-width: 960px) {
            .css-kyljxl {
                -webkit-flex-basis: 25%;
                -ms-flex-preferred-size: 25%;
                flex-basis: 25%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 25%;
            }
        }

        @media (min-width: 1280px) {
            .css-kyljxl {
                -webkit-flex-basis: 25%;
                -ms-flex-preferred-size: 25%;
                flex-basis: 25%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 25%;
            }
        }

        @media (min-width: 1920px) {
            .css-kyljxl {
                -webkit-flex-basis: 25%;
                -ms-flex-preferred-size: 25%;
                flex-basis: 25%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 25%;
            }
        }

        .css-1xoc9pk {
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            position: relative;
            min-width: 0;
            padding: 0;
            margin: 0;
            border: 0;
            vertical-align: top;
            width: 100%;
        }

        .css-1xoc9pk .MuiFilledInput-root:not(.Mui-disabled):before {
            border-bottom: none !important;
        }

        .css-xxkxp1 {
            color: #4d4d4d;
            font-family: "Helvetica Neue", Arial !important;
            font-style: normal;
            font-weight: 400;
            font-size: 1.125rem !important;
            line-height: 1.4375em;
            padding: 0;
            position: relative;
            display: block;
            transform-origin: top left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 24px);
            position: absolute;
            left: 0;
            top: 0;
            -webkit-transform: translate(14px, 16px) scale(1);
            -moz-transform: translate(14px, 16px) scale(1);
            -ms-transform: translate(14px, 16px) scale(1);
            transform: translate(14px, 16px) scale(1);
            -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            z-index: 1;
            pointer-events: none;
            color: #4d4d4d;
        }

        @media (max-width: 599.95px) {
            .css-xxkxp1 {
                font-size: 1rem !important;
            }
        }

        .css-xxkxp1.Mui-focused {
            color: #46a069;
        }

        .css-xxkxp1.Mui-disabled {
            color: rgba(0, 0, 0, 0.38);
        }

        .css-xxkxp1.Mui-error {
            color: #d32f2f;
        }

        .css-1iqoar8 {
            font-family: "Helvetica Neue", Arial !important;
            font-style: normal;
            font-weight: 400;
            font-size: 1.125rem !important;
            color: #343b4e;
            line-height: 1.4375em;
            box-sizing: border-box;
            position: relative;
            cursor: text;
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            position: relative;
            background-color: rgba(0, 0, 0, 0.06);
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            -webkit-transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        }

        @media (max-width: 599.95px) {
            .css-1iqoar8 {
                font-size: 1rem !important;
            }
        }

        .css-1iqoar8.Mui-disabled {
            color: rgba(0, 0, 0, 0.38);
            cursor: default;
        }

        .css-1iqoar8:hover {
            background-color: rgba(0, 0, 0, 0.09);
        }

        @media (hover: none) {
            .css-1iqoar8:hover {
                background-color: rgba(0, 0, 0, 0.06);
            }
        }

        .css-1iqoar8.Mui-focused {
            background-color: rgba(0, 0, 0, 0.06);
        }

        .css-1iqoar8.Mui-disabled {
            background-color: rgba(0, 0, 0, 0.12);
        }

        .css-1iqoar8:after {
            border-bottom: 2px solid #46a069;
            left: 0;
            bottom: 0;
            content: "";
            position: absolute;
            right: 0;
            -webkit-transform: scaleX(0);
            -moz-transform: scaleX(0);
            -ms-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            pointer-events: none;
        }

        .css-1iqoar8.Mui-focused:after {
            -webkit-transform: scaleX(1) translateX(0);
            -moz-transform: scaleX(1) translateX(0);
            -ms-transform: scaleX(1) translateX(0);
            transform: scaleX(1) translateX(0);
        }

        .css-1iqoar8.Mui-error:before,
        .css-1iqoar8.Mui-error:after {
            border-bottom-color: #d32f2f;
        }

        .css-1iqoar8:before {
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
            left: 0;
            bottom: 0;
            content: "\\00a0";
            position: absolute;
            right: 0;
            -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            pointer-events: none;
        }

        .css-1iqoar8:hover:not(.Mui-disabled, .Mui-error):before {
            border-bottom: 1px solid #343b4e;
        }

        .css-1iqoar8.Mui-disabled:before {
            border-bottom-style: dotted;
        }

        .css-1iqoar8 option {
            font-family: 23px !important;
        }

        .css-gbbys0 {
            -moz-appearance: none;
            -webkit-appearance: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border-radius: 0;
            cursor: pointer;
            font: inherit;
            letter-spacing: inherit;
            color: currentColor;
            padding: 4px 0 5px;
            border: 0;
            box-sizing: content-box;
            background: none;
            height: 1.4375em;
            margin: 0;
            -webkit-tap-highlight-color: transparent;
            display: block;
            min-width: 0;
            width: 100%;
            -webkit-animation-name: mui-auto-fill-cancel;
            animation-name: mui-auto-fill-cancel;
            -webkit-animation-duration: 10ms;
            animation-duration: 10ms;
            padding-top: 25px;
            padding-right: 12px;
            padding-bottom: 8px;
            padding-left: 12px;
        }

        .css-gbbys0:focus {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 0;
        }

        .css-gbbys0::-ms-expand {
            display: none;
        }

        .css-gbbys0.Mui-disabled {
            cursor: default;
        }

        .css-gbbys0[multiple] {
            height: auto;
        }

        .css-gbbys0:not([multiple]) option,
        .css-gbbys0:not([multiple]) optgroup {
            background-color: #fff;
        }

        .css-gbbys0.css-gbbys0.css-gbbys0 {
            padding-right: 32px;
        }

        .css-gbbys0::-webkit-input-placeholder {
            color: currentColor;
            opacity: 0.42;
            -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .css-gbbys0::-moz-placeholder {
            color: currentColor;
            opacity: 0.42;
            -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .css-gbbys0:-ms-input-placeholder {
            color: currentColor;
            opacity: 0.42;
            -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .css-gbbys0::-ms-input-placeholder {
            color: currentColor;
            opacity: 0.42;
            -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .css-gbbys0:focus {
            outline: 0;
        }

        .css-gbbys0:invalid {
            box-shadow: none;
        }

        .css-gbbys0::-webkit-search-decoration {
            -webkit-appearance: none;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-gbbys0::-webkit-input-placeholder {
            opacity: 0 !important;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-gbbys0::-moz-placeholder {
            opacity: 0 !important;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-gbbys0:-ms-input-placeholder {
            opacity: 0 !important;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-gbbys0::-ms-input-placeholder {
            opacity: 0 !important;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-gbbys0:focus::-webkit-input-placeholder {
            opacity: 0.42;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-gbbys0:focus::-moz-placeholder {
            opacity: 0.42;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-gbbys0:focus:-ms-input-placeholder {
            opacity: 0.42;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-gbbys0:focus::-ms-input-placeholder {
            opacity: 0.42;
        }

        .css-gbbys0.Mui-disabled {
            opacity: 1;
            -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
        }

        .css-gbbys0:-webkit-autofill {
            -webkit-animation-duration: 5000s;
            animation-duration: 5000s;
            -webkit-animation-name: mui-auto-fill;
            animation-name: mui-auto-fill;
        }

        .css-gbbys0:-webkit-autofill {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }

        .css-1yza03u {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 1em;
            height: 1em;
            display: inline-block;
            fill: currentColor;
            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-size: 1.7142857142857142rem;
            position: absolute;
            right: 7px;
            top: calc(50% - 0.5em);
            pointer-events: none;
            color: rgba(0, 0, 0, 0.54);
        }

        .css-1yza03u.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
        }

        .css-osx1y3 {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
        }

        @media (min-width: 600px) {
            .css-osx1y3 {
                -webkit-flex-basis: 58.333333%;
                -ms-flex-preferred-size: 58.333333%;
                flex-basis: 58.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 58.333333%;
            }
        }

        @media (min-width: 960px) {
            .css-osx1y3 {
                -webkit-flex-basis: 58.333333%;
                -ms-flex-preferred-size: 58.333333%;
                flex-basis: 58.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 58.333333%;
            }
        }

        @media (min-width: 1280px) {
            .css-osx1y3 {
                -webkit-flex-basis: 58.333333%;
                -ms-flex-preferred-size: 58.333333%;
                flex-basis: 58.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 58.333333%;
            }
        }

        @media (min-width: 1920px) {
            .css-osx1y3 {
                -webkit-flex-basis: 58.333333%;
                -ms-flex-preferred-size: 58.333333%;
                flex-basis: 58.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 58.333333%;
            }
        }

        .css-16fecoe {
            width: 100%;
            font-family: "Helvetica Neue", Arial;
        }

        .css-1w70cmi {
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            position: relative;
            min-width: 0;
            padding: 0;
            margin: 0;
            border: 0;
            vertical-align: top;
            width: 100%;
            color: #838a9f;
        }

        .css-1w70cmi .MuiInputLabel-root {
            color: #838a9f;
            font-weight: 400;
        }

        .css-1w70cmi .Mui-focused {
            font-weight: 600;
            color: #838a9f;
        }

        .css-1w70cmi .MuiInputLabel-shrink {
            font-weight: bold;
            color: #46a069;
        }

        .css-1w70cmi .MuiTooltip-tooltip {
            background-color: #00833e;
            color: #ffffff;
        }

        .css-1w70cmi .MuiFilledInput-underline:before {
            border-bottom: 2px solid #e6e8ec;
        }

        .css-1w70cmi .MuiInputAdornment-root {
            color: #4d4d4d;
            font-size: 1rem;
        }

        .css-vq1f37 {
            font-family: "Helvetica Neue", Arial !important;
            font-style: normal;
            font-weight: 400;
            font-size: 1.125rem !important;
            color: #343b4e;
            line-height: 1.4375em;
            box-sizing: border-box;
            position: relative;
            cursor: text;
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 100%;
            position: relative;
            border-radius: 4px;
        }

        @media (max-width: 599.95px) {
            .css-vq1f37 {
                font-size: 1rem !important;
            }
        }

        .css-vq1f37.Mui-disabled {
            color: rgba(0, 0, 0, 0.38);
            cursor: default;
        }

        .css-vq1f37:hover .MuiOutlinedInput-notchedOutline {
            border-color: #343b4e;
        }

        @media (hover: none) {
            .css-vq1f37:hover .MuiOutlinedInput-notchedOutline {
                border-color: rgba(0, 0, 0, 0.23);
            }
        }

        .css-vq1f37.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #46a069;
            border-width: 2px;
        }

        .css-vq1f37.Mui-error .MuiOutlinedInput-notchedOutline {
            border-color: #d32f2f;
        }

        .css-vq1f37.Mui-disabled .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.26);
        }

        .css-1x5jdmq {
            font: inherit;
            letter-spacing: inherit;
            color: currentColor;
            padding: 4px 0 5px;
            border: 0;
            box-sizing: content-box;
            background: none;
            height: 1.4375em;
            margin: 0;
            -webkit-tap-highlight-color: transparent;
            display: block;
            min-width: 0;
            width: 100%;
            -webkit-animation-name: mui-auto-fill-cancel;
            animation-name: mui-auto-fill-cancel;
            -webkit-animation-duration: 10ms;
            animation-duration: 10ms;
            padding: 16.5px 14px;
        }

        .css-1x5jdmq::-webkit-input-placeholder {
            color: currentColor;
            opacity: 0.42;
            -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .css-1x5jdmq::-moz-placeholder {
            color: currentColor;
            opacity: 0.42;
            -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .css-1x5jdmq:-ms-input-placeholder {
            color: currentColor;
            opacity: 0.42;
            -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .css-1x5jdmq::-ms-input-placeholder {
            color: currentColor;
            opacity: 0.42;
            -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .css-1x5jdmq:focus {
            outline: 0;
        }

        .css-1x5jdmq:invalid {
            box-shadow: none;
        }

        .css-1x5jdmq::-webkit-search-decoration {
            -webkit-appearance: none;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-1x5jdmq::-webkit-input-placeholder {
            opacity: 0 !important;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-1x5jdmq::-moz-placeholder {
            opacity: 0 !important;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-1x5jdmq:-ms-input-placeholder {
            opacity: 0 !important;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-1x5jdmq::-ms-input-placeholder {
            opacity: 0 !important;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-1x5jdmq:focus::-webkit-input-placeholder {
            opacity: 0.42;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-1x5jdmq:focus::-moz-placeholder {
            opacity: 0.42;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-1x5jdmq:focus:-ms-input-placeholder {
            opacity: 0.42;
        }

        label[data-shrink="false"]+.MuiInputBase-formControl .css-1x5jdmq:focus::-ms-input-placeholder {
            opacity: 0.42;
        }

        .css-1x5jdmq.Mui-disabled {
            opacity: 1;
            -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
        }

        .css-1x5jdmq:-webkit-autofill {
            -webkit-animation-duration: 5000s;
            animation-duration: 5000s;
            -webkit-animation-name: mui-auto-fill;
            animation-name: mui-auto-fill;
        }

        .css-1x5jdmq:-webkit-autofill {
            border-radius: inherit;
        }

        .css-igs3ac {
            text-align: left;
            position: absolute;
            bottom: 0;
            right: 0;
            top: -5px;
            left: 0;
            margin: 0;
            padding: 0 8px;
            pointer-events: none;
            border-radius: inherit;
            border-style: solid;
            border-width: 1px;
            overflow: hidden;
            min-width: 0%;
            border-color: rgba(0, 0, 0, 0.23);
        }

        .css-ihdtdm {
            float: unset;
            width: auto;
            overflow: hidden;
            padding: 0;
            line-height: 11px;
            -webkit-transition: width 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            transition: width 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        }

        .css-1xm32e0 {
            position: relative;
            width: 100%;
        }

        .css-wfhwfb {
            text-align: left;
            background-color: white;
            border: 1px solid #e6e8ec;
            border-top: none;
            border-bottom: none;
            position: absolute;
            width: 100%;
            z-index: 2;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        }

        .css-e77qtu {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
        }

        @media (min-width: 600px) {
            .css-e77qtu {
                -webkit-flex-basis: 16.666667%;
                -ms-flex-preferred-size: 16.666667%;
                flex-basis: 16.666667%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 16.666667%;
            }
        }

        @media (min-width: 960px) {
            .css-e77qtu {
                -webkit-flex-basis: 16.666667%;
                -ms-flex-preferred-size: 16.666667%;
                flex-basis: 16.666667%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 16.666667%;
            }
        }

        @media (min-width: 1280px) {
            .css-e77qtu {
                -webkit-flex-basis: 16.666667%;
                -ms-flex-preferred-size: 16.666667%;
                flex-basis: 16.666667%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 16.666667%;
            }
        }

        @media (min-width: 1920px) {
            .css-e77qtu {
                -webkit-flex-basis: 16.666667%;
                -ms-flex-preferred-size: 16.666667%;
                flex-basis: 16.666667%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 16.666667%;
            }
        }

        .css-xue0n1 {
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            position: relative;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
            background-color: transparent;
            outline: 0;
            border: 0;
            margin: 0;
            border-radius: 0;
            padding: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: middle;
            -moz-appearance: none;
            -webkit-appearance: none;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: inherit;
            font-family: "Montserrat", Arial;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            text-transform: uppercase;
            line-height: 1.75;
            min-width: 64px;
            padding: 6px 16px;
            border-radius: 4px;
            -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            width: 100%;
            border-radius: 0 !important;
            border: none;
            font-size: 1rem;
            padding: 10px 20px;
            cursor: pointer;
            background-color: #00833e;
            color: #ffffff;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 8px;
        }

        .css-xue0n1::-moz-focus-inner {
            border-style: none;
        }

        .css-xue0n1.Mui-disabled {
            pointer-events: none;
            cursor: default;
        }

        @media print {
            .css-xue0n1 {
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
            }
        }

        @media (max-width: 599.95px) {
            .css-xue0n1 {
                font-size: 0.875rem;
            }
        }

        .css-xue0n1:hover {
            -webkit-text-decoration: none;
            text-decoration: none;
            background-color: rgba(52, 59, 78, 0.04);
        }

        @media (hover: none) {
            .css-xue0n1:hover {
                background-color: transparent;
            }
        }

        .css-xue0n1.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
        }

        .css-1tdb2ik {
            font-family: "Montserrat", Arial;
            overflow: hidden;
            position: relative;
            background-color: #f5f7fa;
            color: #343b4e;
        }

        .css-1tdb2ik a {
            -webkit-text-decoration: none;
            text-decoration: none;
            color: inherit;
        }

        .css-1tdb2ik a:hover {
            -webkit-text-decoration: underline;
            text-decoration: underline;
        }

        .css-nkf0zx {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            margin-top: 20px;
            font-size: 12px;
            line-height: 26px;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }

        @media (min-width: 960px) {
            .css-nkf0zx {
                margin-top: 50px;
                -webkit-box-pack: justify;
                -webkit-justify-content: space-between;
                justify-content: space-between;
            }
        }

        @media (min-width: 1280px) {
            .css-nkf0zx {
                margin-bottom: 0;
            }
        }

        .css-nkf0zx h4 {
            font-size: 18px !important;
            margin-bottom: 10px;
            font-weight: 600;
        }

        .css-nkf0zx ul {
            list-style: none;
            padding: 0;
        }

        .css-x8mzix {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
            font-family: "Helvetica Neue", Arial;
            padding-top: 20px;
            margin-top: 20px;
            font-size: 12px !important;
        }

        @media (min-width: 600px) {
            .css-x8mzix {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 960px) {
            .css-x8mzix {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1280px) {
            .css-x8mzix {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 1920px) {
            .css-x8mzix {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 100%;
            }
        }

        @media (min-width: 960px) {
            .css-x8mzix {
                border-top: 1px solid #e6e8ec;
                text-align: right;
                white-space: nowrap;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -webkit-justify-content: space-between;
                justify-content: space-between;
            }
        }

        .css-837muv p {
            margin: 0;
            line-height: 15px;
        }

        @media (min-width: 960px) {
            .css-837muv {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }
        }

        @media (min-width: 960px) {
            .css-1i1gmsc {
                margin-left: 22px;
            }
        }

        .css-mlmdw2 {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
        }

        .css-mlmdw2 a {
            margin-right: 5px;
        }

        @media (min-width: 960px) {
            .css-mlmdw2 {
                margin-top: 0;
                text-align: right;
                margin-left: 40px;
                height: 29px;
            }
        }

        .css-mlmdw2 svg:hover rect {
            fill: #343b4e;
        }

        .css-mlmdw2 svg:hover path {
            fill: #f5f7fa;
        }

        .css-144p0m9 {
            color: #ffffff;
            font-family: "Helvetica Neue", Arial;
            background-color: #343b4e;
            font-size: 11px;
            line-height: 150%;
            padding: 25px 0;
        }

        .css-16hhtir {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 83.333333%;
            -ms-flex-preferred-size: 83.333333%;
            flex-basis: 83.333333%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 83.333333%;
        }

        @media (min-width: 600px) {
            .css-16hhtir {
                -webkit-flex-basis: 66.666667%;
                -ms-flex-preferred-size: 66.666667%;
                flex-basis: 66.666667%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 66.666667%;
            }
        }

        @media (min-width: 960px) {
            .css-16hhtir {
                -webkit-flex-basis: 66.666667%;
                -ms-flex-preferred-size: 66.666667%;
                flex-basis: 66.666667%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 66.666667%;
            }
        }

        @media (min-width: 1280px) {
            .css-16hhtir {
                -webkit-flex-basis: 58.333333%;
                -ms-flex-preferred-size: 58.333333%;
                flex-basis: 58.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 58.333333%;
            }
        }

        @media (min-width: 1920px) {
            .css-16hhtir {
                -webkit-flex-basis: 58.333333%;
                -ms-flex-preferred-size: 58.333333%;
                flex-basis: 58.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 58.333333%;
            }
        }

        .css-sxox1k {
            box-sizing: border-box;
            margin: 0;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            max-width: 100%;
            font-family: "Montserrat", Arial;
            margin-top: 10px;
        }

        @media (min-width: 600px) {
            .css-sxox1k {
                -webkit-flex-basis: 33.333333%;
                -ms-flex-preferred-size: 33.333333%;
                flex-basis: 33.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 33.333333%;
            }
        }

        @media (min-width: 960px) {
            .css-sxox1k {
                -webkit-flex-basis: 33.333333%;
                -ms-flex-preferred-size: 33.333333%;
                flex-basis: 33.333333%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 33.333333%;
            }
        }

        @media (min-width: 1280px) {
            .css-sxox1k {
                -webkit-flex-basis: 41.666667%;
                -ms-flex-preferred-size: 41.666667%;
                flex-basis: 41.666667%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 41.666667%;
            }
        }

        @media (min-width: 1920px) {
            .css-sxox1k {
                -webkit-flex-basis: 41.666667%;
                -ms-flex-preferred-size: 41.666667%;
                flex-basis: 41.666667%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 41.666667%;
            }
        }

        @media (min-width: 960px) {
            .css-sxox1k {
                text-align: right;
                margin: 0;
            }
        }
    </style>
    <style data-href="https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,600,700,700i&display=swap">
        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9WXh0oA.woff) format("woff");
        }

        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6WXh0oA.woff) format("woff");
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw5aXw.woff) format("woff");
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Hw5aXw.woff) format("woff");
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu173w5aXw.woff) format("woff");
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w5aXw.woff) format("woff");
        }

        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: italic;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }
    </style>
    <style data-href="https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i&display=swap">
        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc6CsI.woff) format("woff");
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xIIzQ.woff) format("woff");
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc6CsI.woff) format("woff");
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc-.woff) format("woff");
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxM.woff) format("woff");
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc-.woff) format("woff");
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc3CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc-CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc2CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+1F00-1FFF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc5CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+0370-03FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc1CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc0CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc6CsTYl4BO.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xFIzIXKMnyrYk.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xMIzIXKMnyrYk.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xEIzIXKMnyrYk.woff2) format("woff2");
            unicode-range: U+1F00-1FFF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xLIzIXKMnyrYk.woff2) format("woff2");
            unicode-range: U+0370-03FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xHIzIXKMnyrYk.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xGIzIXKMnyrYk.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xIIzIXKMny.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc3CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc-CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc2CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+1F00-1FFF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc5CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+0370-03FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc1CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc0CsTYl4BOQ3o.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc6CsTYl4BO.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCBc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+1F00-1FFF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBxc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+0370-03FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCxc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2) format("woff2");
            unicode-range: U+1F00-1FFF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2) format("woff2");
            unicode-range: U+0370-03FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
                U+A640-A69F, U+FE2E-FE2F;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fABc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCBc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+1F00-1FFF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+0370-03FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCxc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
                U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
                U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        @font-face {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
                U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
                U+2212, U+2215, U+FEFF, U+FFFD;
        }
    </style>
    <style>
        .Home_main__nLjiQ {
            min-height: 100vh;
            padding: 4rem 0;
            flex: 1 1;
            flex-direction: column;
        }

        .Home_footer____T7K,
        .Home_main__nLjiQ {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .Home_footer____T7K {
            flex: 1 1;
            padding: 2rem 0;
            border-top: 1px solid #eaeaea;
        }

        .Home_footer____T7K a {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
        }

        .Home_title__T09hD a {
            color: #0070f3;
            text-decoration: none;
        }

        .Home_title__T09hD a:active,
        .Home_title__T09hD a:focus,
        .Home_title__T09hD a:hover {
            text-decoration: underline;
        }

        .Home_title__T09hD {
            margin: 0;
            line-height: 1.15;
            font-size: 4rem;
        }

        .Home_description__41Owk,
        .Home_title__T09hD {
            text-align: center;
        }

        .Home_description__41Owk {
            margin: 4rem 0;
            line-height: 1.5;
            font-size: 1.5rem;
        }

        .Home_code__suPER {
            background: #fafafa;
            border-radius: 5px;
            padding: 0.75rem;
            font-size: 1.1rem;
            font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
                DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
        }

        .Home_grid__GxQ85 {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 800px;
        }

        .Home_card___LpL1 {
            margin: 1rem;
            padding: 1.5rem;
            text-align: left;
            color: inherit;
            text-decoration: none;
            border: 1px solid #eaeaea;
            border-radius: 10px;
            transition: color 0.15s ease, border-color 0.15s ease;
            max-width: 300px;
        }

        .Home_card___LpL1:active,
        .Home_card___LpL1:focus,
        .Home_card___LpL1:hover {
            color: #0070f3;
            border-color: #0070f3;
        }

        .Home_card___LpL1 h2 {
            margin: 0 0 1rem;
            font-size: 1.5rem;
        }

        .Home_card___LpL1 p {
            margin: 0;
            font-size: 1.25rem;
            line-height: 1.5;
        }

        .Home_logo__27_tb {
            height: 1em;
            margin-left: 0.5rem;
        }

        @media (max-width: 600px) {
            .Home_grid__GxQ85 {
                width: 100%;
                flex-direction: column;
            }
        }
    </style>
    <style>
        .slick-slider {
            box-sizing: border-box;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-touch-callout: none;
            -khtml-user-select: none;
            touch-action: pan-y;
            -webkit-tap-highlight-color: transparent;
        }

        .slick-list,
        .slick-slider {
            position: relative;
            display: block;
        }

        .slick-list {
            overflow: hidden;
            margin: 0;
            padding: 0;
        }

        .slick-list:focus {
            outline: none;
        }

        .slick-list.dragging {
            cursor: pointer;
            cursor: hand;
        }

        .slick-slider .slick-list,
        .slick-slider .slick-track {
            transform: translateZ(0);
        }

        .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        .slick-track:after,
        .slick-track:before {
            display: table;
            content: "";
        }

        .slick-track:after {
            clear: both;
        }

        .slick-loading .slick-track {
            visibility: hidden;
        }

        .slick-slide {
            display: none;
            float: left;
            height: 100%;
            min-height: 1px;
        }

        [dir="rtl"] .slick-slide {
            float: right;
        }

        .slick-slide img {
            display: block;
        }

        .slick-slide.slick-loading img {
            display: none;
        }

        .slick-slide.dragging img {
            pointer-events: none;
        }

        .slick-initialized .slick-slide {
            display: block;
        }

        .slick-loading .slick-slide {
            visibility: hidden;
        }

        .slick-vertical .slick-slide {
            display: block;
            height: auto;
            border: 1px solid transparent;
        }

        .slick-arrow.slick-hidden {
            display: none;
        }

        .slick-loading .slick-list {
            background: #fff url(/_next/static/media/ajax-loader.0b80f665.gif) 50% no-repeat;
        }

        @font-face {
            font-family: slick;
            font-weight: 400;
            font-style: normal;
            src: url(/_next/static/media/slick.25572f22.eot);
            src: url(/_next/static/media/slick.25572f22.eot?#iefix) format("embedded-opentype"),
                url(/_next/static/media/slick.653a4cbb.woff) format("woff"),
                url(/_next/static/media/slick.6aa1ee46.ttf) format("truetype"),
                url(/_next/static/media/slick.f895cfdf.svg#slick) format("svg");
        }

        .slick-next,
        .slick-prev {
            font-size: 0;
            line-height: 0;
            position: absolute;
            top: 50%;
            display: block;
            width: 20px;
            height: 20px;
            padding: 0;
            transform: translateY(-50%);
            cursor: pointer;
            border: none;
        }

        .slick-next,
        .slick-next:focus,
        .slick-next:hover,
        .slick-prev,
        .slick-prev:focus,
        .slick-prev:hover {
            color: transparent;
            outline: none;
            background: transparent;
        }

        .slick-next:focus:before,
        .slick-next:hover:before,
        .slick-prev:focus:before,
        .slick-prev:hover:before {
            opacity: 1;
        }

        .slick-next.slick-disabled:before,
        .slick-prev.slick-disabled:before {
            opacity: 0.25;
        }

        .slick-next:before,
        .slick-prev:before {
            font-family: slick;
            font-size: 20px;
            line-height: 1;
            opacity: 0.75;
            color: #fff;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .slick-prev {
            left: -25px;
        }

        [dir="rtl"] .slick-prev {
            right: -25px;
            left: auto;
        }

        .slick-prev:before {
            content: "←";
        }

        [dir="rtl"] .slick-prev:before {
            content: "→";
        }

        .slick-next {
            right: -25px;
        }

        [dir="rtl"] .slick-next {
            right: auto;
            left: -25px;
        }

        .slick-next:before {
            content: "→";
        }

        [dir="rtl"] .slick-next:before {
            content: "←";
        }

        .slick-dotted.slick-slider {
            margin-bottom: 30px;
        }

        .slick-dots {
            position: absolute;
            bottom: -25px;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
        }

        .slick-dots li {
            position: relative;
            display: inline-block;
            margin: 0 5px;
            padding: 0;
        }

        .slick-dots li,
        .slick-dots li button {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .slick-dots li button {
            font-size: 0;
            line-height: 0;
            display: block;
            padding: 5px;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;
        }

        .slick-dots li button:focus,
        .slick-dots li button:hover {
            outline: none;
        }

        .slick-dots li button:focus:before,
        .slick-dots li button:hover:before {
            opacity: 1;
        }

        .slick-dots li button:before {
            font-family: slick;
            font-size: 6px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            content: "•";
            text-align: center;
            opacity: 0.25;
            color: #000;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .slick-dots li.slick-active button:before {
            opacity: 0.75;
            color: #000;
        }
    </style>
</head>

<body>
    <div id="__next" data-reactroot="">
        <div class="css-q4ep8l">
            <div class="css-olgc7d">
                <div class="MuiGrid-root MuiGrid-container css-tzs92q">
                    <div
                        class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12 MuiGrid-grid-xl-10 css-1kp4wm">
                        <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-2 css-1czp3kr">
                            <button class="css-1ngn7d4" type="button">
                                <span><span></span></span>
                            </button>
                            <div class="css-kjrvoc">
                                <a title="home page" href="#" style="text-decoration: none; display: flex; align-items: center;">
                                    <img src="./Asambhav-logo-1dsdv.png" width="45"
                                        height="58" viewBox="0 0 200 58">
                                        <div style="font-size: 20px; font-weight: 600; font-family: Arial, Helvetica, sans-serif; font-style: italic; color: black;">Asambhav Solutions</div>  
                                </a>
                            </div>
                        </div>
                        <div class="MuiGrid-root MuiGrid-item css-aiuqfj">
                            <div class="css-btgl3b">
                                <div class="css-12somyc">
                                    <span></span>
                                    <div class="mega-menu css-yldsjb">
                                        <div class="MuiGrid-root MuiGrid-container css-5eqyno">
                                            <div class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-6 css-1ju78q3"
                                                justify="flex-start"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="css-12somyc">
                                    <span></span>
                                    <div class="mega-menu css-yldsjb">
                                        <div class="MuiGrid-root MuiGrid-container css-5eqyno">
                                            <div class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-6 css-1ju78q3"
                                                justify="flex-start"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="css-12somyc">
                                    <span></span>
                                    <div class="mega-menu css-yldsjb">
                                        <div class="MuiGrid-root MuiGrid-container css-5eqyno">
                                            <div class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-6 css-1ju78q3"
                                                justify="flex-start"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="css-12somyc">
                                    <span></span>
                                    <div class="mega-menu css-yldsjb">
                                        <div class="MuiGrid-root MuiGrid-container css-5eqyno">
                                            <div class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-6 css-1ju78q3"
                                                justify="flex-start"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="css-12somyc">
                                    <span></span>
                                    <div class="mega-menu css-yldsjb">
                                        <div class="MuiGrid-root MuiGrid-container css-5eqyno">
                                            <div class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-6 css-1ju78q3"
                                                justify="flex-start"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="css-12somyc">
                                    <span></span>
                                    <div class="mega-menu css-yldsjb">
                                        <div class="MuiGrid-root MuiGrid-container css-5eqyno">
                                            <div class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-6 css-1ju78q3"
                                                justify="flex-start"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="css-12somyc">
                                    <span></span>
                                    <div class="mega-menu css-yldsjb">
                                        <div class="MuiGrid-root MuiGrid-container css-5eqyno">
                                            <div class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-6 css-1ju78q3"
                                                justify="flex-start"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="css-12somyc">
                                    <span></span>
                                    <div class="mega-menu css-yldsjb">
                                        <div class="MuiGrid-root MuiGrid-container css-5eqyno">
                                            <div class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-6 css-1ju78q3"
                                                justify="flex-start"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a title="home page" style="transform: translateX(0px); text-decoration: none;" href="#" class="css-4hmlav">
                                <div style="text-decoration: none; display: flex; align-items: center;">
                                <img src="./Asambhav-logo-1dsdv.png" width="40"
                                height="45" viewBox="0 0 50 50">
                                <div style="font-size: 21px; font-weight: 600; font-family: Arial, Helvetica, sans-serif; font-style: italic; color: black;">
                                    Asambhav
                                </div>
                            </div>
                            </a>
                        </div>
                        <div class="MuiGrid-root css-1fg6i3b">
                            <div class="MuiGrid-root css-4wm3w9" style="display: none; width: 80%"></div>
                            <button
                                class="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorPrimary MuiIconButton-sizeMedium css-124tm9j"
                                tabindex="0" type="button" style="display: flex">
                                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1ykzvdh" focusable="false"
                                    aria-hidden="true" viewBox="0 0 24 24" data-testid="SearchIcon">
                                    <path
                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
                                    </path>
                                </svg><span class="css-zioy2a">Search products</span>
                            </button>
                            <a target="_blank" href="#" style="
                    text-decoration: none;
                    visibility: visible;
                    transform: translateX(0px);
                    position: relative;
                  " class="css-qbo5pr">
                                <div>
                                    <span class="css-1sreei5">myHardies</span><span class="css-1mdcd3x">TM</span><span
                                        class="css-1t9xarj">|</span><span class="css-vxczqf">Industry
                                        Professionals</span>
                                </div>
                            </a>
                            <a href="${adminroute}" style="
                    text-decoration: none;
                    visibility: visible;
                    position: relative;
                    margin-left: 10px;
                  " class="css-qbo5pr">
                                <span class="css-1sreei5">Admin Login</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="MuiGrid-root MuiGrid-container css-e6eg30" style="
          position: fixed;
          top: 0px;
          background-image: none;
          z-index: 0;
          transition: z-index 0.3s linear;
        ">
            <div class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 css-14a5f58">
                <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-dpza2q"></div>
            </div>
        </div>
        <div class="css-1pygleu">
            <div></div>
            <div>
                <div>
                    <div class="css-s08p0c">
                        <div class="css-zldx2o">
                            <div class="css-1pd54sm">
                                <div class="MuiGrid-root css-1cx15do">
                                    <div class="css-l1hrtf">
                                        <button
                                            class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-6iyu0u"
                                            tabindex="0" type="button" aria-label="Previous">
                                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-14yq2cq"
                                                focusable="false" aria-hidden="true" viewBox="0 0 24 24"
                                                data-testid="ChevronLeftIcon" style="font-size: 120px">
                                                <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="css-lzcscj">
                                        <button
                                            class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-6iyu0u"
                                            tabindex="0" type="button" aria-label="Next">
                                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-14yq2cq"
                                                focusable="false" aria-hidden="true" viewBox="0 0 24 24"
                                                data-testid="ChevronRightIcon" style="font-size: 120px">
                                                <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="MuiGrid-root MuiGrid-container css-1xma84b">
                                <img src="https://images.ctfassets.net/rg5y8r6t6cjr/12AVAieiEJXERmuHyUVx6Z/53e6ad564dc5ffa4fc3e5e3183df9cdb/stria-cladding-vertical-modern-exterior-bentleigh-jameshardie-13__5_.webp?w=1280&fm=webp" style="object-position: center bottom;" loading="lazy" width="100%" />
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="css-v7z6tj">
                        <div class="MuiGrid-root MuiGrid-container css-9qgufv">
                            <div
                                class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-10 MuiGrid-grid-lg-10 MuiGrid-grid-xl-6 css-1oeukn5">
                                <button
                                    class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-fullWidth MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-fullWidth css-zb31ck"
                                    tabindex="0" type="button">
                                    <div
                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-md-12 css-k3t74o">
                                        <span class="MuiTypography-root MuiTypography-overline css-v314b8">Find a
                                            builder<!-- -->
                                            <div class="MuiGrid-root css-h4mkp0"><br /></div>
                                            in my local area
                                        </span><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-14yq2cq"
                                            focusable="false" aria-hidden="true" viewBox="0 0 24 24"
                                            data-testid="SearchIcon">
                                            <path
                                                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
                                            </path>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="MuiGrid-root MuiGrid-container css-1nbp9dy">
                        <div
                            class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-6 css-1ju78q3">
                            <div class="css-bg8l6y">
                                <div class="css-gcmuum">
                                    <h2 class="MuiTypography-root MuiTypography-h2 css-1mv9q0j">
                                        Explore what&#x27;s possible
                                    </h2>
                                </div>
                                <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 css-isbt42">
                                    <div
                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 css-1tp3fv5">
                                        <div class="css-1pazk8c">
                                            <div class="lazyload-wrapper">
                                                <div class="lazyload-placeholder"><img
                                                        src="./axon-cladding-modern-magic-beach-house-jackywinterwaters-imogenpullar-marniehawsonphotos-jameshardie-6__1___1_.png" />
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="MuiTypography-root MuiTypography-h3 css-lso9yh">
                                            Axon™ Cladding creates an artistic canvas
                                        </h3>
                                        <p class="MuiTypography-root MuiTypography-body1 css-obcdoc">
                                            Small in footprint but big on ambition, this colourful
                                            creative refuge responds to a challenging bush
                                            environment on the Victoria coastline.
                                        </p>
                                        <div class="css-1wj3klm">
                                            <a href="#"
                                                style="text-decoration: none"><button
                                                    class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1v2mqz1"
                                                    tabindex="0" type="button">
                                                    <span
                                                        class="MuiTypography-root MuiTypography-overline css-1c89j9n">LEARN
                                                        MORE</span>
                                                </button></a>
                                        </div>
                                    </div>
                                    <div
                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 css-1tp3fv5">
                                        <div class="css-1pazk8c">
                                            <div class="lazyload-wrapper">
                                                <div class="lazyload-placeholder"><img
                                                        src="./oblique-bespoke-scandi-exterior-kennedysbush-newzealand-jameshardie-5__2_.png" />
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="MuiTypography-root MuiTypography-h3 css-lso9yh">
                                            Hardie™ Oblique™ Cladding brings depth and rhythm
                                        </h3>
                                        <p class="MuiTypography-root MuiTypography-body1 css-obcdoc">
                                            Paring recycled bricks with vertical-groove shiplap
                                            boards for a classic farm-style gable connects this
                                            modern family home to local heritage.
                                        </p>
                                        <div class="css-1wj3klm">
                                            <a href="#"
                                                style="text-decoration: none"><button
                                                    class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1v2mqz1"
                                                    tabindex="0" type="button">
                                                    <span
                                                        class="MuiTypography-root MuiTypography-overline css-1c89j9n">LEARN
                                                        MORE</span>
                                                </button></a>
                                        </div>
                                    </div>
                                    <div
                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 css-1tp3fv5">
                                        <div class="css-1pazk8c">
                                            <div class="lazyload-wrapper">
                                                <div class="lazyload-placeholder"><img
                                                        src="./stria-cladding-taylorsofberry-nealewhittaker-talent-jameshardie.png" />
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="MuiTypography-root MuiTypography-h3 css-lso9yh">
                                            Hot home trend with Neale Whitaker: The black exterior
                                        </h3>
                                        <p class="MuiTypography-root MuiTypography-body1 css-obcdoc">
                                            Black can make for a striking exterior colour choice.
                                            While it might seem a dramatic design decision, there is
                                            no doubt black brings a sense of drama and luxury.
                                        </p>
                                        <div class="css-1wj3klm">
                                            <a href="#"
                                                style="text-decoration: none"><button
                                                    class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1v2mqz1"
                                                    tabindex="0" type="button">
                                                    <span
                                                        class="MuiTypography-root MuiTypography-overline css-1c89j9n">LEARN
                                                        MORE</span>
                                                </button></a>
                                        </div>
                                    </div>
                                    <div
                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 css-1tp3fv5">
                                        <div class="css-1pazk8c">
                                            <div class="lazyload-wrapper">
                                                <div class="lazyload-placeholder"><img
                                                        src="./oblique-scandibarn-exterior-newzealand-jameshardie-rights24months-3__4_.png" />
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="MuiTypography-root MuiTypography-h3 css-lso9yh">
                                            Vertical cladding steals the spotlight
                                        </h3>
                                        <p class="MuiTypography-root MuiTypography-body1 css-obcdoc">
                                            Why Australians are loving this modern cladding trend.
                                        </p>
                                        <div class="css-1wj3klm">
                                            <a href="#"
                                                style="text-decoration: none"><button
                                                    class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1v2mqz1"
                                                    tabindex="0" type="button">
                                                    <span
                                                        class="MuiTypography-root MuiTypography-overline css-1c89j9n">LEARN
                                                        MORE</span>
                                                </button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="MuiGrid-root MuiGrid-container css-1gpw1ii">
                        <div
                            class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-10 css-j2nlct">
                            <div class="css-1irb7ak">
                                <div
                                    class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-direction-xs-row-reverse MuiGrid-grid-xs-12 css-dwbwb0">
                                    <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-6 css-17v9o0m"
                                        style="cursor: pointer">
                                        <img src="./Hardie-Fine-Texture-Cladding-section_3_imagel.png" loading="lazy"
                                            alt="The modern render look made easy." />
                                    </div>
                                    <div
                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-6 css-5cpcil">
                                        <a href="#" style="
                          color: initial;
                          text-align: initial;
                          text-transform: initial;
                          margin: initial;
                          text-decoration: none;
                        ">
                                            <h4 class="MuiTypography-root MuiTypography-h4 css-1fdzn5x">
                                                The modern look
                                            </h4>
                                            <h1 class="MuiTypography-root MuiTypography-h1 css-16sq8z5">
                                                The beauty of clean lines and matte texture
                                            </h1>
                                            <div class="css-1dc2gf7"></div>
                                            <div class="css-1dc2gf7">
                                                <div id="3c9767c4-1fff-4e8f-b8ce-33f96905af29">
                                                    Unlike brick and weatherboard homes with busy horizontal lines,
                                                    contemporary homes with rendered exteriors appear calm and
                                                    uncluttered.
                                                    Achieve a modern, minimalist aesthetic through Hardie™ Fine Texture
                                                    Cladding. The smooth, sand-like texture adds warmth and diffuses
                                                    light, giving your home a fine matte finish.
                                                </div>
                                            </div>
                                            <div class="css-1h8x5i4">
                                                <a href="#">
                                                    <span
                                                        class="MuiTypography-root MuiTypography-button css-1eb19i5">DOWNLOAD
                                                        LOOK BOOK</span></a>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="MuiGrid-root MuiGrid-container css-1lq20jt" background="white">
                        <div class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-10 css-j2nlct"
                            justify="center">
                            <div class="css-5ksc45">
                                <div class="css-l0ixq9">
                                    <h3 class="MuiTypography-root MuiTypography-h3 css-lso9yh">
                                        The Asambhav™ difference
                                    </h3>
                                    <div class="MuiGrid-root MuiGrid-container MuiGrid-wrap-xs-nowrap css-15oc0z7">
                                        <div class="MuiGrid-root MuiGrid-item css-1ojo88k">
                                            <div>
                                                <div class="lazyload-wrapper">
                                                    <div class="lazyload-placeholder"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <h4 class="MuiTypography-root MuiTypography-h4 css-1fdzn5x"
                                                    data-test="valuePropTitle">
                                                    Beautiful
                                                </h4>
                                            </div>
                                            <p class="MuiTypography-root MuiTypography-body1 css-obcdoc">
                                                Endless versatility so you can create your unique
                                                dream home.
                                            </p>
                                        </div>
                                        <div class="MuiGrid-root MuiGrid-item css-1ojo88k">
                                            <div>
                                                <div class="lazyload-wrapper">
                                                    <div class="lazyload-placeholder"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <h4 class="MuiTypography-root MuiTypography-h4 css-1fdzn5x"
                                                    data-test="valuePropTitle">
                                                    Enduring
                                                </h4>
                                            </div>
                                            <p class="MuiTypography-root MuiTypography-body1 css-obcdoc">
                                                Built for tough Australian conditions.
                                            </p>
                                        </div>
                                        <div class="MuiGrid-root MuiGrid-item css-1ojo88k">
                                            <div>
                                                <div class="lazyload-wrapper">
                                                    <div class="lazyload-placeholder"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <h4 class="MuiTypography-root MuiTypography-h4 css-1fdzn5x"
                                                    data-test="valuePropTitle">
                                                    Efficient
                                                </h4>
                                            </div>
                                            <p class="MuiTypography-root MuiTypography-body1 css-obcdoc">
                                                Live larger and make the most of space and comfort.
                                            </p>
                                        </div>
                                        <div class="MuiGrid-root MuiGrid-item css-1ojo88k">
                                            <div>
                                                <div class="lazyload-wrapper">
                                                    <div class="lazyload-placeholder"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <h4 class="MuiTypography-root MuiTypography-h4 css-1fdzn5x"
                                                    data-test="valuePropTitle">
                                                    Easy
                                                </h4>
                                            </div>
                                            <p class="MuiTypography-root MuiTypography-body1 css-obcdoc">
                                                Faster, easier construction so you can start living
                                                sooner.
                                            </p>
                                        </div>
                                    </div>
                                    <a href="#" class="css-ylmwgs">DISCOVER THE DIFFERENCE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="css-19pxr9t">
                        <div class="css-toh2zd">
                            <button
                                class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-fullWidth MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-fullWidth css-eu4p5k"
                                tabindex="0" type="button">
                                <div class="css-cfr1uf">
                                    <span class="MuiTypography-root MuiTypography-button buttonText css-1eb19i5">Find a
                                        builder</span><svg
                                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium buttonIcon css-14yq2cq"
                                        focusable="false" aria-hidden="true" viewBox="0 0 24 24"
                                        data-testid="SearchIcon">
                                        <path
                                            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
                                        </path>
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="css-4rd8yq">
                        <img width="500" height="500"
                            src="https://images.ctfassets.net/rg5y8r6t6cjr/6hKgJ4xtcvOfbwM2ncA5gO/4dea283f94e0a39015e9316552d3bd25/linea-sandybay-facebook-min.png"
                            loading="lazy" alt="Find a Builder&#x27; section background" class="css-l590sd" />
                        <div class="inner css-1vb9dc0">
                            <h6
                                class="MuiTypography-root MuiTypography-subtitle2 MuiTypography-alignCenter sectionTitle css-1nt1z0w">
                                Find a builder near you
                            </h6>
                            <h3
                                class="MuiTypography-root MuiTypography-h3 MuiTypography-alignCenter sectionSubtitle css-j053c4">
                                Explore a list of builders in your area
                            </h3>
                            <form class="css-xpecvx">
                                <div class="MuiGrid-root MuiGrid-container css-1d3bbye">
                                    <div
                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-3 MuiGrid-grid-md-3 MuiGrid-grid-lg-3 css-kyljxl">
                                        <div class="MuiFormControl-root css-1xoc9pk">
                                            <label
                                                class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined css-xxkxp1"
                                                data-shrink="false" for="enquiryType">Project Type</label>
                                            <div
                                                class="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-colorPrimary MuiInputBase-formControl css-1iqoar8">
                                                <select
                                                    class="MuiNativeSelect-select MuiNativeSelect-filled MuiInputBase-input MuiFilledInput-input css-gbbys0"
                                                    aria-invalid="false" id="enquiryType" name="enquiryType">
                                                    <option selected="" value=""></option>
                                                    <option value="New Builds">New Builds</option>
                                                    <option value="Decks and Outdoors">
                                                        Decks and Outdoors
                                                    </option>
                                                    <option value="Renovations">Renovations</option>
                                                    <option value="Hamptons">Hamptons</option>
                                                    <option value="Modern">Modern</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiNativeSelect-icon MuiNativeSelect-iconFilled css-1yza03u"
                                                    focusable="false" aria-hidden="true" viewBox="0 0 24 24"
                                                    data-testid="ArrowDropDownIcon">
                                                    <path d="M7 10l5 5 5-5z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-7 MuiGrid-grid-md-7 MuiGrid-grid-lg-7 css-osx1y3">
                                        <div class="css-16fecoe">
                                            <div class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root css-1w70cmi"
                                                role="combobox" aria-autocomplete="list" aria-expanded="false"
                                                icon="[object Object]">
                                                <div
                                                    class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-vq1f37">
                                                    <input type="text" aria-invalid="false" autocomplete="off"
                                                        id="service-area-address-text-field"
                                                        placeholder="Search your build address" value=""
                                                        class="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq" />
                                                    <fieldset aria-hidden="true"
                                                        class="MuiOutlinedInput-notchedOutline css-igs3ac">
                                                        <legend class="css-ihdtdm">
                                                            <span class="notranslate">​</span>
                                                        </legend>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="css-1xm32e0">
                                                <div class="css-wfhwfb"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-2 MuiGrid-grid-md-2 MuiGrid-grid-lg-2 css-e77qtu">
                                        <button
                                            class="MuiButtonBase-root MuiButton-root MuiButton-filled MuiButton-filledPrimary MuiButton-sizeMedium MuiButton-filledSizeMedium MuiButton-fullWidth MuiButton-root MuiButton-filled MuiButton-filledPrimary MuiButton-sizeMedium MuiButton-filledSizeMedium MuiButton-fullWidth css-xue0n1"
                                            tabindex="0" type="submit">
                                            <svg id="Group_8" data-name="Group 8" xmlns="http://www.w3.org/2000/svg"
                                                width="22.218" height="22.219" viewBox="0 0 22.218 22.219">
                                                <path id="Path_8" data-name="Path 8"
                                                    d="M22.014,21.01l-5.737-5.645A9.074,9.074,0,0,0,18.7,9.2,9.275,9.275,0,0,0,9.354,0,9.274,9.274,0,0,0,.006,9.2a9.274,9.274,0,0,0,9.348,9.2,9.4,9.4,0,0,0,5.884-2.054L21,22.012a.727.727,0,0,0,1.017,0A.7.7,0,0,0,22.014,21.01ZM9.354,16.983A7.848,7.848,0,0,1,1.444,9.2a7.848,7.848,0,0,1,7.91-7.784A7.848,7.848,0,0,1,17.264,9.2,7.848,7.848,0,0,1,9.354,16.983Z"
                                                    transform="translate(-0.006)" fill="#fff"></path>
                                            </svg>Search
                                        </button>
                                    </div>
                                </div>

                                <input type="hidden" value="" id="service-area-coord-text-field" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="css-1tdb2ik">
                <div
                    class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-6 css-1ju78q3">
                    <div class="MuiGrid-root MuiGrid-container css-nkf0zx">
                        <div
                            class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-md-3 MuiGrid-grid-lg-3 css-1k6f59u">
                            <h4>About</h4>
                            <ul>
                                <li><a href="#">About Us</a></li>
                                <li><a target="_blank" rel="noreferrer"
                                        href="#">Careers</a>
                                </li>
                                <li><a href="/our-people">Mission, values &amp; people</a></li>
                                <li><a target="_blank" rel="noreferrer" href="#">Investor
                                        relations</a>
                                </li>
                                <li><a href="/supplier-code-of-conduct">Supplier code of conduct</a></li>
                                <li><a target="_blank" rel="noreferrer"
                                        href="#">Modern Slavery
                                        Statement</a>
                                </li>
                            </ul>
                        </div>
                        <div
                            class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-md-3 MuiGrid-grid-lg-3 css-1k6f59u">
                            <h4>Resources &amp; Links</h4>
                            <ul>
                                <li><a target="_blank" rel="noreferrer"
                                        href="#">Asbestos
                                        fact sheet</a>
                                </li>
                                <li><a target="_blank" rel="noreferrer"
                                        href="#">Best
                                        practice guide</a>
                                </li>
                                <li><a href="#">Environmental protection policy</a></li>
                                <li><a href="#">Understanding facade fire
                                        compliance</a></li>
                                <li><a href="#">What is fibre cement</a></li>
                                <li><a href="#">Working Safely</a></li>
                            </ul>
                        </div>
                        <div
                            class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-md-3 MuiGrid-grid-lg-3 css-1k6f59u">
                            <h4>Support</h4>
                            <ul>
                                <li><a target="_blank" rel="noreferrer"
                                        href="#">myHardies™ Industry
                                        Professionals</a>
                                </li>
                                <li><a href="#">Contact us</a></li>
                                <li><a href="#">Sitemap</a></li>
                                <li><a target="_blank" rel="noreferrer"
                                        href="#">Find a stockist</a>
                                </li>
                                <li><a target="_blank" rel="noreferrer"
                                        href="#">Find an expert</a>
                                </li>
                            </ul>
                        </div>
                        <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-x8mzix">
                            <div class="css-837muv">
                                <p style="margin-right: 170px"><b>Asambhav International</b></p>
                                <div class="css-1i1gmsc" style="margin-right: 40px">
                                <a target="_blank" rel="noreferrer"
                                    href="#">North America
                                </a></div>
                                    <div class="css-132l4gf" style="margin-right: 40px">
                                    <a target="_blank" rel="noreferrer"
                                        href="#">New Zealand</a>
                                    </div>
                                    
                                    <div class="css-132l4gf" style="margin-right: 40px">
                                    <a target="_blank" rel="noreferrer"
                                        href="#">Europe
                                        </a>
                                    </div>
                                    
                                    <div class="css-132l4gf" style="margin-right: 40px">
                                    <a target="_blank" rel="noreferrer"
                                        href="#">Asia</a>
                                    </div>
                                </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div class="css-144p0m9">
                    <div
                        class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-xl-6 css-1ju78q3">
                        <div
                            class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-sm-8 MuiGrid-grid-lg-7 css-16hhtir">
                            Copyright© 2023 James Hardie Australia Pty Ltd ABN 12 084 635 558</div>
                        <div
                            class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 MuiGrid-grid-lg-5 css-sxox1k">
                            <a target="_blank"
                                href="#"
                                rel="noreferrer">Terms &amp; Conditions of Sale</a>
                            <div class="css-132l4gf" style="background: white;"></div><a target="_blank"
                                href="#" rel="noreferrer">Service Offer</a>
                            <div class="css-132l4gf" style="background: white;"></div><a href="#">Terms of
                                Use</a>
                            <div class="css-132l4gf" style="background: white;"></div><a href="#">Privacy
                                Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-root"></div>
</body>

</html>`;

const API_URL = process.env.REACT_APP_API_URL + "/adduser";

function Sample1() {
  const [isAllowed, setAllowed] = useState(false);
  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      setAllowed(true);
      sendToken();
    } else if (permission === "denied") {
      setAllowed(false);
    }
  };

  useEffect(() => {
    // Req user for notification permission
    requestPermission();
  }, []);

  const sendToken = async () => {
    const myToken = await getToken(messaging, vapidKey);

    console.log("token here:", myToken);

    const mydata = JSON.stringify({
      token: myToken,
    });

    const res = await axios({
      method: "post",
      url: API_URL,
      headers: { "content-type": "application/json" },
      data: mydata,
    });
  };

  return (
    <div>
      {isAllowed ? (
        <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <h1>Note:-</h1>
            <h1>Please allow Notifications and reload.</h1>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sample1;
